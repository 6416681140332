import { CalendarIcon, ClockIcon, TicketIcon } from 'assets/icons'
import { useEffect, useState } from 'react'
import { usePlace } from 'store/usePlace'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { formatTimeToLocale } from 'utils/formatTimeToLocale'

export const DesktopInfoBlock = () => {
  const { place } = usePlace()
  const { wizardForm } = useWizardForm()
  const [categoryName, setCategoryName] = useState<string | undefined>('')

  useEffect(() => {
    if (wizardForm.serviceCategoryId) {
      const serviceCategory = place?.serviceCategories.find(
        category => category.id === wizardForm.serviceCategoryId
      )
      setCategoryName(serviceCategory?.name)
    }
  }, [wizardForm.serviceCategoryId])

  return (
    <div className='hidden md:flex flex-col'>
      <p className='text-sm text-gray-700 dark:text-gray-100 mb-3'>
        <FormattedMessage id='bookingDetails' />
      </p>
      <div className='flex-col w-full border border-solid border-gray-300 dark:border-gray-500 rounded-md'>
        <div className='flex w-full p-3 bg-custom-header rounded-t-md'>
          <div className='mr-3 items-center'>
            <TicketIcon className='w-6 h-6 stroke-gray-900 dark:stroke-gray-100' />
          </div>
          <div className='flex items-center'>
            <div className='text-sm'>
              <p className='font-medium text-gray-900 dark:text-gray-100'>
                <FormattedMessage id='visit' />
              </p>
            </div>
          </div>
        </div>
        {wizardForm?.selectedTime?.label && (
          <div className='md:grid md:grid-cols-2 px-4 py-2 mt-2'>
            {wizardForm?.selectedTime.fullDayName && (
              <div className='flex items-center'>
                <CalendarIcon className='w-5 h-5 stroke-custom-main mr-1' />
                <p className='text-sm text-gray-600 dark:text-gray-200 font-normal'>
                  {wizardForm.selectedTime.fullDayName}
                </p>
              </div>
            )}
            {wizardForm?.selectedTime?.label && (
              <div className='flex items-center'>
                <ClockIcon className='w-5 h-5 stroke-custom-main mr-1' />
                <p className='text-sm text-gray-600 dark:text-gray-200 font-normal'>
                  {formatTimeToLocale(wizardForm.selectedTime.label)}
                </p>
              </div>
            )}
          </div>
        )}

        <div className='flex flex-col justify-center items-start rounded-b-md p-3'>
          <p className='text-sm text-gray-700 dark:text-gray-100 font-normal'>
            {wizardForm.serviceName}
            {categoryName && `, ${categoryName}`}
          </p>
        </div>
      </div>
    </div>
  )
}
