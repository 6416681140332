import { Place } from '@2meters/types'
import {
  FACEBOOK_LOGO_ICON,
  INSTAGRAM_LOGO_ICON,
  LINKED_IN_LOGO_ICON,
  TWITTER_LOGO_ICON,
} from 'assets/urls'

type Props = {
  place?: Place
}

const linkMaker = (handle: string, type: string) => {
  switch (type) {
    case 'facebook':
      return `https://facebook.com/${handle}`
    case 'linkedin':
      return `https://www.linkedin.com/${handle}`
    case 'twitter':
      return `https://twitter.com/${handle}`
    case 'instagram':
      return `https://instagram.com/${handle}`
    default:
      return `https://facebook.com/${handle}`
  }
}

export const PlaceSocials = ({ place }: Props) => {
  if (!place) return null
  return (
    <>
      {place && place.socials && (
        <div className='socials flex mb-6'>
          {place.socials.facebook && (
            <a href={linkMaker(place.socials.facebook, 'facebook')} className='mr-4'>
              <img src={FACEBOOK_LOGO_ICON} />
            </a>
          )}
          {place.socials.linkedin && (
            <a href={linkMaker(place.socials.linkedin, 'linkedin')} className='mr-4'>
              <img src={LINKED_IN_LOGO_ICON} />
            </a>
          )}
          {place.socials.twitter && (
            <a href={linkMaker(place.socials.twitter, 'twitter')} className='mr-4'>
              <img src={TWITTER_LOGO_ICON} />
            </a>
          )}
          {place.socials.instagram && (
            <a href={linkMaker(place.socials.instagram, 'instagram')}>
              <img src={INSTAGRAM_LOGO_ICON} />
            </a>
          )}
        </div>
      )}
    </>
  )
}
