import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  color?: string
  viewBox?: string
}

const ChevronUpIcon = ({
  className = 'w-6 h-6',
  fill = 'none',
  color = 'currentColor',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg fill={fill} viewBox={viewBox} strokeWidth='1.5' stroke={color} className={className}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' />
  </svg>
)
export default memo(ChevronUpIcon)
