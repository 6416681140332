import { MouseEventHandler } from 'react'

interface ButtonLoadingProps {
  children: React.ReactNode
  style?: string
  loading?: boolean
  disabled?: boolean
  type?: 'button' | 'reset' | 'submit'
  onClick?: MouseEventHandler
}

export const ButtonLoading = (props: ButtonLoadingProps) => {
  const { children, loading, disabled, style, type = 'button', onClick } = props
  return (
    <button
      className={`bg-custom-main border border-solid border-custom-main rounded-md px-6 py-2 text-gray-100
        disabled:bg-gray-400 disabled:cursor-not-allowed disabled:text-black
        disabled:border-gray-400 dark:disabled:border-gray-900 disabled:shadow-none
        disabled:opacity-50 mb-2 focus:outline-none align-middle
        transition-all duration-150 ease-in-out font-semibold text-sm px-6 py-2
        text-gray-100 
        ${style}`}
      onClick={onClick}
      type={type}
      disabled={disabled}
    >
      {loading ? (
        <div className='flex justify-center items-center'>
          <svg
            className='animate-spin h-5 w-5 text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        </div>
      ) : (
        children
      )}
    </button>
  )
}
