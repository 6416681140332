import Head from 'next/head'
import { useEffect } from 'react'
import { usePlace } from 'store/usePlace'
import { LightTheme } from '@2meters/types'

export const CssCustomisation = () => {
  // sets css vars that are then use in tailwind config
  const { place } = usePlace()

  function hexToRgba(hex: string, alpha: number = 1): string {
    // Ensure the hash is removed from the start if it's there
    hex = hex.replace('#', '')

    // Parse r, g, b values
    const bigint = parseInt(hex, 16)
    const r = (bigint >> 16) & 255
    const g = (bigint >> 8) & 255
    const b = bigint & 255

    // Return the rgba string
    return `rgba(${r},${g},${b},${alpha})`
  }

  useEffect(() => {
    const body = document.body
    if (place?.customisation.theme !== 'dark') {
      console.log("Use theme 'light'")
      body.classList.remove('dark')
      document.documentElement.classList.remove('dark')
    } else {
      console.log("Use theme 'dark'")
      body.classList.add('dark')
      document.documentElement.classList.add('dark')
    }
  }, [place])

  return (
    <>
      <Head>
        <style>
          :root{' '}
          {`{ 
            --color-bg: ${place?.customisation?.backgroundColor ?? LightTheme.backgroundColor};
            --color-header: ${place?.customisation?.headerColor ?? LightTheme.headerColor};
            --color-main: ${hexToRgba(
              place?.customisation?.buttonColor ?? LightTheme.buttonColor,
              1
            )};
            --color-element: ${hexToRgba(
              place?.customisation?.headerColor ?? LightTheme.buttonColor,
              1
            )};
            --color-element_hover: ${hexToRgba(
              place?.customisation?.headerColor ?? LightTheme.buttonColor,
              0.2
            )};
            --color-element_selected: ${hexToRgba(
              place?.customisation?.headerColor ?? LightTheme.buttonColor,
              0.1
            )};
            --color-element_active: ${hexToRgba(
              place?.customisation?.headerColor ?? LightTheme.buttonColor,
              0.3
            )};
          }`}
        </style>
      </Head>
    </>
  )
}
