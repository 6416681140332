import { AppointmentShort } from '@2meters/types'
import { VISITOR } from 'app/routes'
import { CalendarIcon, ChevronRigthIcon, ClockIcon, CompressedTicketIcon } from 'assets/icons'
import { Timestamp } from 'firebase/firestore'
import { DateTime, Settings } from 'luxon'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useVisitorLocale } from 'store/useVisitorLocale'
import FormattedMessage from 'utils/FormattedMessage'
import { ticketOptions } from 'utils/colorCodes'
import { timePeriodInText } from 'utils/timez'
import { useTranslation } from 'utils/useTranslation'
import { TicketOtionsType } from '../types'
import { ShortTicketLogo } from './ShortTicketLogo'

interface ShortTicketProps {
  ticket: AppointmentShort
}

export const ShortTicket = ({ ticket }: ShortTicketProps) => {
  const intl = useTranslation()
  const { visitorLocale } = useVisitorLocale()
  const navigate = useNavigate()
  const [ticketDate, setTicketDate] = useState('')
  const [ticketTime, setTicketTime] = useState('')
  const [ticketOption, setTicketOption] = useState<TicketOtionsType>()
  const userLocale = window.navigator.language
  Settings.defaultLocale = userLocale

  useEffect(() => {
    if (ticket) setTicketOption(ticketOptions(ticket))
  }, [ticket])

  useEffect(() => {
    const startTime = ticket?.startTime || ticket?.createdAt
    const dateObject = startTime instanceof Timestamp ? startTime.toDate() : startTime
    const luxonDateTime = DateTime.fromJSDate(dateObject as Date)
    setTicketDate(startTime ? luxonDateTime.toFormat('EEE, d LLL') : '')

    const formattedTime = luxonDateTime.toLocaleString(DateTime.TIME_SIMPLE)
    setTicketTime(startTime ? formattedTime : 'Walk-in')
  }, [ticket?.startTime])

  const waitingTime = timePeriodInText({
    durationSec: ticket.estimatedWaitingTimeSec,
    locale: visitorLocale,
  })

  return (
    <div
      className='flex items-center justify-center mb-5'
      onClick={() => {
        navigate(VISITOR.TICKET.replace(':placeId', ticket.placeId).replace(':id', ticket.id))
      }}
    >
      <div className='max-w-md w-full h-full mx-auto z-10'>
        <div className='bg-white relative rounded-[10px] mx-4 pb-2 border dark:border-none'>
          <div
            className={`absolute text-white ${
              ticketOption && ticketOption?.bgColor
            } w-full rounded-t-lg top-0 left-0 h-[40px] flex items-center justify-between px-5`}
          >
            <div className='flex mr-4'>
              <CalendarIcon className='w-5 h-5 stroke-white mr-1' />
              <p className='text-sm truncate max-w-[170px] md:max-w-[260px]'>
                {ticketDate ||
                  intl({
                    id: 'liveQueue',
                  })}
              </p>
            </div>
            <div className='flex'>
              <ClockIcon className='w-5 h-5 stroke-white mr-1' />
              <p className='text-sm'>{ticketTime}</p>
            </div>
          </div>
          <div>
            <div className='flex px-4 py-6 pt-16'>
              <ShortTicketLogo placeId={ticket.placeId} />
              <div className='mr-4 mt-1 flex-shrink-0'></div>
              <div className='flex flex-col w-full'>
                <div className='flex items-start justify-between'>
                  <h4 className='text-sm text-gray-700 font-semibold'>{ticket.placeName}</h4>
                  <ChevronRigthIcon className='w-4 h-4 stroke-gray-900' />
                </div>

                <p className='text-sm text-gray-500'> {ticket.serviceName}</p>
              </div>
            </div>

            <div className='grid grid-cols-2 gap-2 mb-2 px-4'>
              <div>
                <span className='text-xs text-gray-700'>
                  <FormattedMessage id='visit' />
                </span>
                <div className='flex items-center'>
                  <CompressedTicketIcon className='w-5 h-5 stroke-gray-600 mr-1' />
                  <p className='text-lg font-bold text-gray-600'>{ticket.tag || 'No ticket'}</p>
                </div>
              </div>
              {/* {currentQueue?.estimatedWaitingTimeMode === 'constant' &&
                currentQueue?.estimatedWaitingTimeConstantMinutes &&
                currentQueue?.estimatedWaitingTimeConstantMinutes !== 0 && (
                  <div>
                    <span className='text-xs text-gray-700'>
                      <FormattedMessage id='waitingTime' />
                    </span>
                    <div className='flex items-center'>
                      <p className='text-sm font-bold text-gray-700 mt-1'>
                        {peopleBefore > 0
                          ? (peopleBefore - 1) * currentQueue?.estimatedWaitingTimeConstantMinutes
                          : currentQueue?.estimatedWaitingTimeConstantMinutes}{' '}
                        <FormattedMessage id='min' />
                      </p>
                    </div>
                  </div>
                )} */}
              <div>
                <span className='text-xs text-gray-700'>
                  <FormattedMessage id='waitingTime' />
                </span>
                <div className='flex items-center'>
                  <p className='text-sm font-bold text-gray-700 mt-1'>{waitingTime}</p>
                </div>
              </div>
            </div>

            <div className='border-t border-gray-200'>
              <div className='flex justify-center items-center py-2 mb-[-8px]'>
                <p className='text-sm font-bold mr-1'>
                  <FormattedMessage id='status' />:{' '}
                </p>
                {ticketOption && (
                  <p className={`text-sm font-semibold capitalize ${ticketOption?.textColor}`}>
                    <FormattedMessage id={`status.${ticketOption?.label}`} />
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
