import { Transition } from '@headlessui/react'
import { isMessaggingSupported } from 'api/Firebase/firebase-init'
import { XMarkIcon } from 'assets/icons'
import WarningTriangle from 'assets/warning-triangle.svg'
import { Fragment, useEffect, useState } from 'react'

export const NotificationInfo = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    if (isMessaggingSupported) setShow(Notification.permission === 'denied')
  }, [])

  return (
    <>
      {show && (
        <div className='flex w-full flex-col items-center space-y-4 px-4 pt-6 pb-2'>
          <Transition
            show={show}
            as={Fragment}
            enter='transform ease-out duration-300 transition'
            enterFrom='translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2'
            enterTo='translate-y-0 opacity-100 sm:translate-x-0'
            leave='transition ease-in duration-100'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='pointer-events-auto max-w-md w-full'>
              <div className='p-4 mx-4 ring-1 ring-gray-300 rounded-lg bg-white'>
                <div className='flex items-start'>
                  <div className='flex-shrink-0'>
                    <WarningTriangle />
                  </div>
                  <div className='ml-3 w-0 flex-1 pt-0.5'>
                    <p className='mt-1 text-sm text-gray-500 mb-1'>
                      Permissions to send notifications was not enabled. You will not get push
                      notifications.
                    </p>
                    {/* <div className='text-sm font-medium leading-6 text-custom-main hover:text-indigo-500'>
                      View instructions to enable
                    </div> */}
                  </div>
                  <div className='ml-4 flex flex-shrink-0'>
                    <button
                      type='button'
                      className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      onClick={() => {
                        setShow(false)
                      }}
                    >
                      <span className='sr-only'>Close</span>
                      <XMarkIcon className='h-5 w-5 stroke-gray-600' aria-hidden='true' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      )}
    </>
  )
}
