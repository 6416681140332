import { Appointment } from '@2meters/types'
import { useEffect, useState } from 'react'
import FormattedMessage from 'utils/FormattedMessage'
import { ProductItem } from './ProductItem'

interface TabProductsProps {
  app: Appointment | undefined
}

export const TabProducts = ({ app }: TabProductsProps) => {
  const [total, setTotal] = useState(0)

  useEffect(() => {
    const totalAmount = () => {
      const productItems = app?.order?.products.map(product => {
        const { quantity, price } = product
        if (quantity) {
          const priceNumber = Number(price)
          return priceNumber * quantity
        }
        return 0
      })

      return productItems?.reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    }
    setTotal(totalAmount() || 0)
  }, [app?.order?.products])

  return (
    <>
      <div className='p-4'>
        <div className='mt-3 mb-4'>
          <p className='text-sm font-bold text-gray-800'>
            <FormattedMessage id='ticket.productsReserved' />
          </p>
        </div>

        {app?.order?.products.map(product => (
          <ProductItem key={product.productId} product={product} />
        ))}

        <div className='flex items-center justify-between mt-10'>
          <a
            href='#'
            onClick={e => {
              e.preventDefault()
              // router.push('/shop');
            }}
            className='text-sm text-swatch-100 font-semibold underline underline-offset-4'
          >
            <FormattedMessage id='ticket.viewProductCatalog' />
          </a>
          <p className='text-sm text-gray-800 font-semibold'>
            <FormattedMessage id='subtotal' />: {app?.order?.products[0].currency || '€'}
            {total}
          </p>
        </div>
      </div>
    </>
  )
}
