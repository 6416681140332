import { Schedule, ScheduleStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

interface ScheduleStore {
  placeId: string | undefined
  isLoadingSchedules: boolean
  schedules: Schedule[]
  subscribeToSchedules: (placeId: string) => void
  unsubscribeFromSchedules: () => void
}

export const useSchedules = create<ScheduleStore>(set => ({
  placeId: undefined,
  isLoadingSchedules: false,
  schedules: [],

  subscribeToSchedules: placeId => {
    set({ isLoadingSchedules: true, placeId })
    if (!placeId) {
      set({ schedules: [], isLoadingSchedules: false })
      return
    }

    const ref = collection(db, `places/${placeId}/schedules/`)
    console.log('Subscribing to schedules', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const schedules = querySnapshot.docs.map(doc => ScheduleStruct.create(doc.data()))
        console.log('Schedules updated', schedules)
        set({ schedules, isLoadingSchedules: false })
      },
      (error: any) => {
        console.error('Subscribing to schedules failed', error)
        set({ schedules: [], isLoadingSchedules: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from schedules')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromSchedules: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromSchedules: () => {
    console.log('Unsubscribing from schedules')
  },
}))
