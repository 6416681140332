import FormattedMessage from 'utils/FormattedMessage'
import { useWizardSteps } from '../useWizardSteps'
import { useQueueTickets } from 'store/useQueueTickets'
import { Modal } from 'components/Modal'
import { useWizardForm } from 'store/useWizardForm'

export const TooManyPeopleWarning = () => {
  const { isLastStep, queue } = useWizardSteps()
  const { wizardForm } = useWizardForm()
  const { queueTickets } = useQueueTickets()

  const open = Boolean(
    isLastStep &&
      wizardForm.queueId === queue?.id &&
      queue?.isMaxLengthEnabled &&
      queue?.maxLength &&
      queueTickets?.length >= queue?.maxLength
  )

  return (
    <Modal open={open} closeIcon={false}>
      <div className='flex flex-col justify-between items-center bg-white p-5'>
        <div className='flex flex-col items-center'>
          <img
            className='inline-block w-[150px] rounded-md mt-10 mb-7'
            src='/snail.svg'
            alt='Paused queue'
          />
          <h1 className='text-gray-700 text-2xl font-bold'>
            <FormattedMessage id='tooManyPeople' /> {':('}
          </h1>
          <p className='text-sm text-gray-800 text-center my-4 max-w-[80%]'>
            <FormattedMessage id='tooManyPeople.description' />
          </p>
        </div>
      </div>
    </Modal>
  )
}
