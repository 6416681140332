import { functions } from 'api/Firebase/firebase-init'
import { httpsCallable } from 'firebase/functions'

export const setFCMTokenInAppointments = (token: string) => {
  const updateWebPushTokenCloudFunc = httpsCallable(functions, 'messenger-updateWebPushToken')

  return updateWebPushTokenCloudFunc({ token })
    .then(result => result.data)
    .catch(e => {
      console.error(e.message)
      return null
    })
}
