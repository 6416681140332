import { PropsWithChildren, memo } from 'react'

type IconProps = {
  className?: string
  color?: string
}

const IconWithBg = ({
  className = 'w-12 h-12',
  color = 'custom-main',
  children,
}: PropsWithChildren<IconProps>) => (
  <>
    <svg
      viewBox='0 0 46 46'
      className={className}
      stroke='none'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='1' y='1' width='44' height='44' rx='22' className={`fill-${color}`} opacity='0.15' />
      {children}
      <rect
        className={`fill-${color}`}
        opacity='0.2'
        x='5'
        y='5'
        width='36'
        height='36'
        rx='18'
        strokeWidth='6'
      />
    </svg>
  </>
)
export default memo(IconWithBg)
