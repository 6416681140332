import { _2METERS_LOGO_FULL_WHITE_BG_URL } from 'assets/urls'
import FormattedMessage from 'utils/FormattedMessage'

export const MainPage = () => {
  return (
    <div className='mx-auto p-3 sm:px-6 lg:px-8 h-[calc(100vh-58px)]'>
      <div className='flex flex-col justify-center items-center h-full'>
        <img className='h-8 w-8 w-auto mb-3' src={_2METERS_LOGO_FULL_WHITE_BG_URL} alt='2meters' />
        <p className='text-center'>
          2meters - <FormattedMessage id='slogan' />
        </p>
      </div>
    </div>
  )
}
