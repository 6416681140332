import { VISITOR } from 'app/routes'
import { ButtonLoading } from 'components/Buttons'
import { Container } from 'components/Container'
import { useNavigate } from 'react-router-dom'

const PAGE_NOT_FOUND_IMG = '/pageNotFound.png'

export const NoFoundPage = () => {
  const navigate = useNavigate()
  return (
    <Container className='h-full'>
      <div className='flex item-center justify-center h-full'>
        <div className='flex flex-col items-center justify-center'>
          <img
            className='h-auto max-h-[201px] w-auto mb-3'
            src={PAGE_NOT_FOUND_IMG}
            alt='2meters'
          />
          <div className='text-2xl mb-5 font-bold'>{'404 :('}</div>
          <p className='mb-8 text-center'>
            Oops! It looks like the page you were looking for isn't here.
          </p>

          <div className='fixed bottom-3 w-[calc(100%-32px)] md:relative flex justify-center'>
            <ButtonLoading
              onClick={() => {
                navigate(VISITOR.TICKETS)
              }}
              style='btn-secondary w-full md:w-auto'
            >
              Back
            </ButtonLoading>
          </div>
        </div>
      </div>
    </Container>
  )
}
