import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { LOGO } from 'app/routes'
import { ChevronLeftIcon } from 'assets/icons'
import { Container } from 'components/Container'
import { LanguageSwitcher } from 'components/LanguageSwitcher'
import { ProgressBar } from 'components/ProgressBar'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { usePlace } from 'store/usePlace'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { useWizardSteps } from '../useWizardSteps'

interface FormHeaderProps {
  goToPreviousStep: () => void
}

export const FormHeader = ({ goToPreviousStep }: FormHeaderProps) => {
  const { place } = usePlace()
  const { wizardForm } = useWizardForm()
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [loadingLogo, setLoadingLogo] = useState<boolean>(false)

  const { isFirstStep } = useWizardSteps()

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(place?.logo)
      .then(link => {
        setLogoUrl(link)
      })
      .catch(() => {
        setLogoUrl(LOGO)
      })
      .finally(() => {
        setLoadingLogo(false)
      })
  }, [place])

  return (
    <>
      <div className={`flex bg-custom-header h-[68px] md:min-h-[173px]`}>
        <Container>
          <div className='flex items-center h-full relative justify-between '>
            {!isFirstStep && (
              <div className='absolute left-0 md:hidden'>
                <button onClick={goToPreviousStep}>
                  <ChevronLeftIcon />
                </button>
              </div>
            )}

            <div className={`flex items-center mr-4 ${!isFirstStep && 'ml-7'}  md:ml-0`}>
              {(loadingLogo && (
                <div className='flex items-center justify-center min-w-[40px] h-10 mr-4'>
                  <RoundSpinner width='w-7' height='h-7' />
                </div>
              )) || (
                <div className='mr-4 flex-shrink-0 min-w-10 border-solid border-4 border-white rounded-full'>
                  <img
                    className={`inline-block h-12 w-12 md:h-[134px] md:w-[134px]  rounded-full`}
                    src={logoUrl}
                    alt=''
                  />
                </div>
              )}
              <div>
                <h1 className='text-gray-900 dark:text-gray-100 text-sm md:text-3xl md:font-bold text-600 md:text-900'>
                  <FormattedMessage id='wizardForm.bookHeader' />
                </h1>
                <p className='text-sm text-gray-900 dark:text-gray-100'>
                  {wizardForm?.serviceName}
                </p>
              </div>
            </div>
            <LanguageSwitcher />
          </div>
        </Container>
      </div>
      <ProgressBar />
    </>
  )
}
