import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import {
  Firestore,
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { getMessaging, isSupported, Messaging, onMessage } from 'firebase/messaging'
import { connectStorageEmulator, getStorage } from 'firebase/storage'

export const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DB_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  region: process.env.NEXT_PUBLIC_FIREBASE_APP_REGION,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_ANALYTICS_MEASUREMENT_ID,
}

export const publicVapidKey = process.env.NEXT_PUBLIC_PUBLIC_VAPID_KEY || ''

export const app = initializeApp(config)

// export const analytics = getAnalytics(app)

/* Firebase APIs */
initializeFirestore(app, {
  ignoreUndefinedProperties: true,
})
export const db: Firestore = getFirestore(app)

export const auth = getAuth(app)
export const functions = getFunctions(app, config.region)
export const storage = getStorage()

export let isMessaggingSupported = false
export let messaging: Messaging | undefined = undefined

if (typeof navigator !== 'undefined') {
  /* Setting up firebase messaging */
  isSupported()
    .then(supported => {
      isMessaggingSupported = supported
      messaging = getMessaging(app)
      onMessage(messaging, async payload => {
        console.log('Message received. ', payload)

        await navigator.serviceWorker
          .getRegistration('/firebase-cloud-messaging-push-scope')
          .then(registration => {
            const title = payload?.data?.title || '2Meters'
            const body = payload?.data?.body || 'You have a new message'

            const options = {
              title: title,
              body: body,
              icon: '/default-notification-logo.png',
            }

            return registration?.showNotification(title, options)
          })
      })
    })
    .catch(e => console.log(`Init Messaging Error`, e))
}

/**
 * To use this you have to set NEXT_PUBLIC_FIREBASE_IS_EMULATOR=true in .env
 */
export const isEmulator = process.env.NEXT_PUBLIC_FIREBASE_IS_EMULATOR === 'true'
if (isEmulator) {
  console.log('Connecting to local emulator')
  const host = 'localhost'

  connectFunctionsEmulator(functions, host, 5001)
  connectFirestoreEmulator(db, host, 8080)
  connectAuthEmulator(auth, `http://${host}:9099`, { disableWarnings: true })
  connectStorageEmulator(storage, host, 9199)
}
