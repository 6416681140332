import { ReactElement } from 'react'

export type ModalContentProps = {
  title?: string
  description?: string
}

export const ModalContent = ({ title, description }: ModalContentProps) => {
  return (
    <div className='mt-3 text-center sm:mt-5'>
      {title && (
        <h3 className='text-base font-semibold leading-6 text-gray-900' id='modal-title'>
          {title}
        </h3>
      )}
      {description && (
        <div className='mt-2'>
          <p className='text-sm text-gray-500'>{description}</p>
        </div>
      )}
    </div>
  )
}
