import { BASE_URL } from 'app/routes'
import QRCode from 'react-qr-code'

type QrCodeProps = {
  placeId: string
  appId: string
}

export const QrCode = ({ placeId, appId }: QrCodeProps) => {
  return (
    <div>
      <QRCode
        id='qr-code'
        size={512}
        style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
        value={`${BASE_URL}/places/${placeId}/ticket/${appId}`}
        viewBox={`0 0 512 512`}
      />
    </div>
  )
}
