import { create } from 'zustand'
import uuid from 'short-uuid'

type AlertType = 'success' | 'info' | 'warning' | 'error'

interface Alert {
  id?: string
  message: string
  type: AlertType
}

interface AlertStoreState {
  alerts: Alert[]
  addAlert: (message: string, type?: AlertType) => void
}

const useAlert = create<AlertStoreState>(set => ({
  alerts: [],
  addAlert: (message: string, type: AlertType = 'info') => {
    const id = uuid.generate()
    set(state => ({
      alerts: [...state.alerts, { id, message, type }],
    }))
    setTimeout(() => {
      set(state => ({
        alerts: state.alerts.filter(alert => alert.id !== id),
      }))
    }, 5000)
  },
}))

export { useAlert }
