import { Inventory, InventoryStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

interface InventoryStore {
  placeId: string | undefined
  isLoadingInventories: boolean
  inventories: Inventory[]

  subscribeToInventory: (placeId: string) => void
  unsubscribeFromInventory: () => void
}

export const useInventories = create<InventoryStore>(set => ({
  placeId: undefined,
  isLoadingInventories: false,
  inventories: [],

  subscribeToInventory: placeId => {
    set({ isLoadingInventories: true, placeId })
    if (!placeId) {
      set({ inventories: [], isLoadingInventories: false })
      return
    }

    const ref = collection(db, `places/${placeId}/inventories/`)
    console.log('Subscribing to inventorys', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const inventories = querySnapshot.docs.map(doc => InventoryStruct.create(doc.data()))
        console.log('Inventorys updated', inventories)
        set({ inventories, isLoadingInventories: false })
      },
      (error: any) => {
        console.error('Subscribing to inventorys failed', error)
        set({ inventories: [], isLoadingInventories: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from inventories')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromInventory: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromInventory: () => {
    console.log('Unsubscribing from inventories')
  },
}))
