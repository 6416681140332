import { create } from 'zustand'
import { AppointmentShort, AppointmentStruct } from '@2meters/types'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from 'api/Firebase/firebase-init'

interface PlaceTicketsStore {
  placeId: string | undefined
  isLoadingServices: boolean
  tickets: AppointmentShort[]
  subscribe: (placeId: string, userId: string) => void
  unsubscribe: () => void
}

export const useActiveTickets = create<PlaceTicketsStore>(set => ({
  placeId: undefined,
  isLoadingServices: true,
  tickets: [],

  subscribe: (placeId, userId) => {
    set({ isLoadingServices: true, placeId })
    if (!placeId) {
      set({ tickets: [], isLoadingServices: false })
      return
    }

    const ref = query(
      collection(db, `places/${placeId}/tickets`),
      where('userId', '==', userId),
      where('status', 'in', ['created', 'queued'])
    )

    console.log('Subscribing to active tickets', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const tickets = querySnapshot.docs.map(doc => AppointmentStruct.create(doc.data()))
        console.log('Active tickets updated', tickets)
        set({ tickets, isLoadingServices: false })
      },
      (error: any) => {
        console.error('Subscribing to active tickets failed', error)
        set({ tickets: [], isLoadingServices: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from active tickets')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribe,
    })

    return unsubscribe
  },

  unsubscribe: () => {
    console.log('Unsubscribing from active tickets')
  },
}))
