import { Appointment, TOGGLE_CHECKED } from '@2meters/types'
import { RichTextParse } from 'components/RichTextParse'

interface TabInfoProps {
  app: Appointment
}

export const TabInfo = ({ app }: TabInfoProps) => {
  console.log('app', app.formData)
  return (
    <div className='p-4'>
      {app?.formData?.data.map(({ fieldId, type, label, multiValue, value, defaultValue }: any) => {
        return (
          <div key={fieldId}>
            <div className='mb-3'>
              <span className='text-xs text-gray-700'>{label}</span>
              {type === 'checkbox' && (
                <>
                  {multiValue && multiValue.length ? (
                    <ul className='list-decimal pl-4 pt-2'>
                      {multiValue?.map(value => (
                        <li className='text-sm leading-6 text-gray-600 pb-[5px]' key={value}>
                          {value}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    '-'
                  )}
                </>
              )}
              {type === 'radio' && (
                <p className='mt-1 text-sm leading-6 text-gray-600'>{value || '-'}</p>
              )}
              {type === 'text' && (
                <p className='mt-1 text-sm leading-6 text-gray-600'>{value || '-'}</p>
              )}
              {type === 'info' && <RichTextParse str={value} className='text-sm' />}
              {type === 'number' && (
                <p className='mt-1 text-sm leading-6 text-gray-600'>{typeof value ? value : '-'}</p>
              )}
              {type === 'toggle' && (
                <div className='space-y-2 mr-3 flex items-center'>
                  <input
                    id={label}
                    aria-describedby={`${label}-description`}
                    name={label}
                    type='checkbox'
                    className={`h-4 w-4 rounded  ${
                      value === TOGGLE_CHECKED ? 'border-swatch-700' : 'border-gray-300'
                    }  text-swatch-700 focus:ring-swatch-700 `}
                    value={value}
                    checked={value === TOGGLE_CHECKED}
                    disabled={true}
                  />
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  )
}
