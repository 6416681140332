import { Service, ServiceStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

interface ServiceStore {
  placeId: string | undefined
  isLoadingServices: boolean
  services: Service[]
  subscribeToServices: (placeId: string) => void
  unsubscribeFromServices: () => void
}

export const useServices = create<ServiceStore>(set => ({
  placeId: undefined,
  isLoadingServices: false,
  services: [],

  subscribeToServices: placeId => {
    set({ isLoadingServices: true, placeId })
    if (!placeId) {
      set({ services: [], isLoadingServices: false })
      return
    }

    const ref = collection(db, `places/${placeId}/services/`)
    console.log('Subscribing to services', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const services = querySnapshot.docs.map(doc => ServiceStruct.create(doc.data()))
        console.log('Services updated', services)
        set({ services, isLoadingServices: false })
      },
      (error: any) => {
        console.error('Subscribing to services failed', error)
        set({ services: [], isLoadingServices: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from services')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromServices: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromServices: () => {
    console.log('Unsubscribing from services')
  },
}))
