import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  color?: string
  viewBox?: string
}

const ChevronDownIcon = ({
  className = 'stroke-custom-headertxt',
  fill = 'none',
  viewBox = '0 0 24 24',
  color,
}: IconProps) => (
  <svg fill={fill} viewBox={viewBox} strokeWidth='1.5' className={`w-6 h-6 ${className}`}>
    <path
      stroke={color}
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M19.5 8.25l-7.5 7.5-7.5-7.5'
    />
  </svg>
)
export default memo(ChevronDownIcon)
