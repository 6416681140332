import { db } from 'api/Firebase/firebase-init'
import { updateDoc, doc } from 'firebase/firestore'

export const markNotificationAsReceived = async (messageId: string | undefined) => {
  if (messageId)
    return await updateDoc(doc(db, `uiNotifications/${messageId}`), {
      status: 'received',
    })
  else throw Error('no message id set')
}
