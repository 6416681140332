import { Appointment } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { DateTime } from 'luxon'
import { create } from 'zustand'

interface AppointmentsStore {
  placeId: string | undefined
  isLoadingAppointments: boolean
  appointments: Appointment[]
  subscribeToAppointments: (
    placeId: string,
    scheduleId: string,
    startDate: DateTime,
    endDate: DateTime
  ) => void
  unsubscribeFromAppointments: () => void
}

export const useAppointmentsShort = create<AppointmentsStore>(set => ({
  placeId: undefined,
  isLoadingAppointments: true,
  appointments: [],

  subscribeToAppointments: (placeId, scheduleId, startDate, endDate) => {
    set({ isLoadingAppointments: true, placeId })
    if (!placeId) {
      set({ appointments: [], isLoadingAppointments: false })
      return
    }

    const ref = query(
      collection(db, `places/${placeId}/tickets_short`),
      where('scheduleId', '==', scheduleId),
      where('startTime', '>=', startDate.toJSDate()),
      where('startTime', '<', endDate.toJSDate()),
      where('status', 'in', ['created', 'queued', 'invited', 'processed'])
    )
    console.log('Subscribing to tickets_short', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async snap => {
        const appointments = snap.docs.map(doc => doc.data() as Appointment)
        console.log('tickets_short updated', appointments)
        set({ appointments, isLoadingAppointments: false })
      },
      (error: any) => {
        console.error('Subscribing to tickets_short failed', error)
        set({ appointments: [], isLoadingAppointments: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from tickets_short')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromAppointments: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromAppointments: () => {
    console.log('Unsubscribing from tickets_short')
  },
}))
