import { memo } from 'react'

type IconProps = {
  className?: string
  stroke?: string
  viewBox?: string
}

const TicketIcon = ({
  className = 'w-6 h-6 stroke-gray-800',
  stroke = 'none',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg viewBox={viewBox} stroke={stroke} fill='none' className={className}>
    <path
      d='M16.5505 7.44486C16.1025 6.99683 15.9758 6.37319 16.1504 5.8144C16.2691 5.43443 16.3285 5.24439 16.335 5.1843C16.3575 4.97488 16.3517 4.95162 16.2346 4.77654C16.201 4.72631 16.1307 4.65599 15.9901 4.51534C15.3347 3.85998 15.007 3.53229 14.6619 3.34251C13.8313 2.88583 12.8248 2.88583 11.9943 3.34251C11.6491 3.53229 11.3214 3.85998 10.666 4.51534L4.51498 10.6664C3.86093 11.3205 3.53391 11.6475 3.34434 11.9917C2.88606 12.8239 2.88655 13.833 3.34564 14.6648C3.53555 15.0089 3.86289 15.3356 4.51758 15.989C4.65755 16.1287 4.72754 16.1985 4.77744 16.2319C4.95238 16.349 4.975 16.3546 5.18434 16.3325C5.24404 16.3261 5.43376 16.2675 5.81305 16.1501C6.37241 15.9771 6.99685 16.1058 7.44392 16.5536L7.47884 16.5885C7.88287 17.022 8.00371 17.6182 7.84877 18.1485C7.72844 18.5603 7.66828 18.7661 7.66281 18.825C7.6428 19.0402 7.64453 19.047 7.76548 19.2262C7.79855 19.2752 7.86951 19.3461 8.01144 19.488C8.66365 20.1402 8.98975 20.4664 9.33298 20.6557C10.1653 21.1148 11.1749 21.1148 12.0072 20.6557C12.3504 20.4664 12.6765 20.1402 13.3287 19.488L19.4837 13.3331C20.1388 12.678 20.4663 12.3504 20.6561 12.0054C21.113 11.1746 21.113 10.1676 20.6559 9.33683C20.4661 8.99188 20.1384 8.66436 19.4832 8.00937C19.3427 7.86891 19.2725 7.79868 19.2224 7.76515C19.0473 7.64795 19.025 7.64244 18.8155 7.66458C18.7556 7.67091 18.565 7.72998 18.1839 7.84811C17.6237 8.02175 16.998 7.89306 16.5505 7.44486Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.1171 7.19533L11.1108 7.18903'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.0146 9.09377L13.0083 9.08746'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.8095 12.8906L16.8032 12.8843'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(TicketIcon)
