import { User as AuthUser, getAuth } from '@firebase/auth'
import { create } from 'zustand'

export type UserClaims = {
  orgMembership: { [key: string]: 'admin' | 'counter' }
  superadmin?: boolean
}

export interface CurrentUserStore {
  authUser?: AuthUser
  claims?: UserClaims
  loaded?: boolean
  subscribeToAuthUser: (authUser?: AuthUser) => void
  unsubscribeFromAuthUser: () => void
}

export const useCurrentUser = create<CurrentUserStore>((set, get) => ({
  loaded: false,
  refreshUserClaims: async () => {
    return get()
      .authUser?.getIdTokenResult(true)
      .then(token => {
        console.log('User claims updated', token.claims)
        set({ claims: token.claims as UserClaims })
      })
  },
  subscribeToAuthUser: (authUser?: AuthUser) => {
    const userId = authUser?.uid

    if (!userId) {
      return () => undefined
    }
    set({ authUser })

    console.log('Subscribing to Auth Token', authUser)
    const unsubscribeFromAuthToken = getAuth().onIdTokenChanged(async user => {
      if (user) {
        const token = await user.getIdTokenResult()
        console.log('User token updated', token)

        set({
          authUser: user,
          claims: token.claims as UserClaims,
          loaded: Boolean(get().claims),
        })
      }
    })

    set({
      unsubscribeFromAuthUser: () => {
        console.log('Unsubscribing from Auth User')
        unsubscribeFromAuthToken()
        set({ loaded: false, claims: undefined, authUser: undefined })
      },
    })
    return get().unsubscribeFromAuthUser
  },

  unsubscribeFromAuthUser: () => {
    console.warn('this should not execute')
  },
}))
