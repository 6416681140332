import { RadioGroup } from '@headlessui/react'
import { useEffect, useState } from 'react'
import { usePlace } from 'store/usePlace'
import { useServices } from 'store/useServices'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { TitleStep } from '../ui'
import { SelectablePaper } from './SelectablePaper'
import { ServiceDisableWarning } from './ServiceDisableWarning'

export const CategoryStep = () => {
  const { wizardForm, updateWizardForm } = useWizardForm()
  const { services } = useServices()
  const { place } = usePlace()
  const [showDisabledPopup, setShowDisabledPopup] = useState<boolean>(false)

  const categoryIdList = services.map(service => service.categoryId)
  const serviceWithCategory = place?.serviceCategories?.filter(category =>
    categoryIdList.includes(category.id)
  )

  const selectCategory = (categoryId: string) => {
    updateWizardForm({
      serviceCategoryId: categoryId,
      serviceId: undefined,
      serviceName: undefined,
      queueId: undefined,
      scheduleId: undefined,
    })
  }

  const selectService = (serviceId: string, serviceName: string) => {
    updateWizardForm({
      serviceCategoryId: undefined,
      serviceId,
      serviceName: serviceName,
      queueId: undefined,
      scheduleId: undefined,
    })
  }

  // if there is only one service, select it
  useEffect(() => {
    if (services.length === 1) {
      updateWizardForm({
        serviceCategoryId: undefined,
        serviceId: services[0].id,
        serviceName: services[0].name,
      })
    }
  }, [services])

  // if there is only one service, and we know the rest of the steps, go to next step
  // useEffect(() => {
  //   if (services.length === 1 && wizardForm.serviceId && !isLoading) {
  //     goToNextStep()
  //   }
  // }, [services, wizardForm.serviceId, isLoading])

  const existingCategories = place?.serviceCategories.map(c => c.id) || []
  const serviceWithoutCategory = services.filter(
    service =>
      !service.categoryId ||
      service.categoryId === 'uncategorized' ||
      !existingCategories?.includes(service.categoryId)
  )

  return (
    <>
      <ServiceDisableWarning
        propShow={showDisabledPopup}
        onClose={() => setShowDisabledPopup(false)}
      />
      <div>
        <TitleStep
          title={<FormattedMessage id='wizardForm.service.title' />}
          description={<FormattedMessage id='wizardForm.service.description' />}
        />
        <RadioGroup
          value={wizardForm.serviceId || wizardForm.serviceCategoryId}
          style={{ marginBottom: '8px' }}
        >
          <div className='space-y-2'>
            {serviceWithCategory?.map(category => (
              <SelectablePaper
                onClick={() => selectCategory(category.id)}
                key={category.id}
                id={category.id}
                value={category.id}
                name={category.name}
              />
              // <RadioGroup.Option
              //   onClick={() => selectCategory(category.id)}
              //   key={category.name}
              //   value={category.id}
              //   className={({ active, checked }) =>
              //     `${active ? '' : ''}
              //     ${
              //       checked
              //         ? 'bg-primary-50 bg-opacity-75 text-primary-800 border-primary-300'
              //         : 'bg-white'
              //     }
              //       relative flex cursor-pointer active:bg-primary-300 rounded-lg px-5 py-4 focus:outline-none border border-solid`
              //   }
              // >
              //   {({ checked }) => (
              //     <>
              //       <div className='flex w-full items-center justify-start'>
              //         <div className='mr-2'>
              //           <RadioCheck checked={checked} />
              //         </div>
              //         <div className='flex items-center'>
              //           <div className='text-sm'>
              //             <RadioGroup.Label
              //               as='p'
              //               className={`font-medium  ${
              //                 checked ? 'text-primary-800' : 'text-gray-900'
              //               }`}
              //             >
              //               {category.name}
              //             </RadioGroup.Label>
              //             <RadioGroup.Description
              //               as='span'
              //               className={`inline ${checked ? 'text-sky-100' : 'text-gray-500'}`}
              //             ></RadioGroup.Description>
              //           </div>
              //         </div>
              //       </div>
              //     </>
              //   )}
              // </RadioGroup.Option>
            ))}
            {serviceWithoutCategory
              ?.sort((a, b) => {
                if (a.position && b.position) {
                  return a.position - b.position
                } else return 0
              })
              ?.map(service => {
                const isDisabled = !service.scheduleId && !service.queueId
                return (
                  <SelectablePaper
                    key={service.id}
                    id={service.id}
                    name={service.name}
                    value={service.id}
                    description={service.description}
                    onClick={() => {
                      if (isDisabled) setShowDisabledPopup(true)
                      else selectService(service.id, service.name)
                    }}
                  />
                )
                // if (isDisabled) {
                //   return (
                //     <div
                //       key={service.id}
                //       className={
                //         'relative flex cursor-pointer active:bg-primary-300 rounded-lg px-5 py-4 focus:outline-none border border-solid bg-slate-100 disabled:text-slate-500'
                //       }
                //       onClick={() => {
                //         if (isDisabled) setShowDisabledPopup(true)
                //       }}
                //     >
                //       <div className='flex w-full items-center justify-start'>
                //         <div className='mr-2'>
                //           <RadioCheck checked={false} />
                //         </div>
                //         <div className='flex items-center'>
                //           <div className='text-sm'>
                //             <RadioGroup.Label as='p' className={`font-medium text-gray-700 `}>
                //               {service.name}
                //             </RadioGroup.Label>
                //             <RadioGroup.Description as='span' className={`inline text-gray-500`}>
                //               {service?.description}
                //             </RadioGroup.Description>
                //           </div>
                //         </div>
                //       </div>
                //     </div>
                //   )
                // }
                // return (
                //   <RadioGroup.Option
                //     onClick={() => selectService(service.id, service.name)}
                //     key={service.id}
                //     disabled={!service.scheduleId && !service.queueId}
                //     value={service.id}
                //     className={({ active, checked, disabled }) =>
                //       `${active ? '' : ''}
                //   ${
                //     checked
                //       ? 'bg-primary-50 bg-opacity-75 text-primary-800 border-primary-300'
                //       : 'bg-white'
                //   }
                //   ${disabled ? 'bg-slate-100 disabled:text-slate-500' : ''}
                //     relative flex cursor-pointer active:bg-primary-300 rounded-lg px-5 py-4 focus:outline-none border border-solid disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none`
                //     }
                //   >
                //     {({ checked }) => (
                //       <>
                //         <div className='flex w-full items-center justify-start'>
                //           <div className='mr-2'>
                //             <RadioCheck checked={checked} />
                //           </div>
                //           <div className='flex items-center'>
                //             <div className='text-sm'>
                //               <RadioGroup.Label
                //                 as='p'
                //                 className={`font-medium  ${
                //                   checked ? 'text-swatch-800' : 'text-gray-700'
                //                 }`}
                //               >
                //                 {service.name}
                //               </RadioGroup.Label>
                //               <RadioGroup.Description
                //                 as='span'
                //                 className={`inline ${
                //                   checked ? 'text-swatch-700' : 'text-gray-500'
                //                 }`}
                //               >
                //                 {service?.description}
                //               </RadioGroup.Description>
                //             </div>
                //           </div>
                //         </div>
                //       </>
                //     )}
                //   </RadioGroup.Option>
                // )
              })}
          </div>
        </RadioGroup>
        {serviceWithoutCategory?.length === 0 && serviceWithCategory?.length === 0 && (
          <p>
            <FormattedMessage id='wizardForm.service.empty' />
          </p>
        )}
      </div>
    </>
  )
}
