import { QueueStats, QueueStatsStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

export interface QueueStatsStore {
  placeId: string
  queueId: string
  queueStats: QueueStats
  isLoading: boolean
  subscribeToQueueStats: (placeId?: string, queueId?: string) => void
  unsubscribeFromQueueStats: () => void
}

export const useQueueStats = create<QueueStatsStore>((set, get) => ({
  placeId: undefined as any,
  queueId: undefined as any,
  queueStats: undefined as any,
  isLoading: true,
  subscribeToQueueStats: (placeId, queueId) => {
    if (!placeId) {
      set({ queueStats: undefined, isLoading: false })
      return
    }

    set({ isLoading: true })
    const ref = doc(db, `places/${placeId}/queues/${queueId}/stats/default`)
    console.log('Subscribing to Queue stats', queueId)
    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async doc => {
        const queueStats = QueueStatsStruct.create(doc.data() || {})

        console.log('Queue stats updated', queueStats)
        set({ queueStats, queueId, isLoading: false })
      },
      (error: any) => {
        console.error('Subscribing to Queue stats failed', error)
        set({ queueStats: undefined, isLoading: false })
      }
    )

    set({
      unsubscribeFromQueueStats: unsubscribeFromFirestore,
    })
    return unsubscribeFromFirestore
  },
  unsubscribeFromQueueStats: () => {
    console.log('Unsubscribing from Queue stats')
  },
}))

export default useQueueStats
