import { AppointmentShort, AppointmentShortStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

interface TicketShortStore {
  placeId: string | undefined
  id: string | undefined
  ticketShort: AppointmentShort
  error: 'not_found' | 'unknown' | undefined
  subscribeToTicketShort: (placeId: string, id: string) => void
  unsubscribeFromTicketShort: () => void
}

export const useTicketShort = create<TicketShortStore>(set => ({
  placeId: undefined,
  id: undefined,
  ticketShort: undefined as any,
  error: undefined,

  subscribeToTicketShort: (placeId, id) => {
    set({ placeId, id })
    if (!placeId && !id) {
      set({ ticketShort: undefined })
      return
    }

    const ref = doc(db, `places/${placeId}/tickets_short/${id}`)
    console.log('Subscribing to ticket_short', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async doc => {
        if (!doc.exists()) {
          console.error('Ticket short not found', { placeId, id })
          set({ ticketShort: undefined, error: 'not_found' })
          return
        }
        const ticketShort = AppointmentShortStruct.create(doc.data())
        console.log('ticket short updated', ticketShort)
        set({ ticketShort, error: undefined })
      },
      (error: any) => {
        console.error('Subscribing to ticket_short failed', error)
        set({ ticketShort: undefined, error: 'unknown' })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from ticket_short')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromTicketShort: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromTicketShort: () => {
    console.log('Unsubscribing from ticket_short')
  },
}))
