import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

export interface MesagingTokenStore {
  messagingToken?: string
  setMessagingToken: (token?: string) => void
}

export const useMessagingToken = create<MesagingTokenStore>()(
  devtools(
    persist(
      (set, get) => ({
        messagingToken: undefined,
        setMessagingToken: (token?: string) => set({ messagingToken: token }),
      }),
      {
        name: '2meters-messaging-token',
      }
    )
  )
)
