import { VISITOR } from 'app/routes'
import { HomeIcon, QrIcon, TicketIcon } from 'assets/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { usePlace } from 'store/usePlace'
import FormattedMessage from 'utils/FormattedMessage'

export const MobileMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { place } = usePlace()

  return (
    <div className='flex items-center justify-center fixed bottom-0 left-0 w-full z-50 bg-gray-100 shadow-[0_0_5px_rgba(0,0,0,.5)]'>
      <div className='flex flex-col w-full relative'>
        <div className='flex flex-row justify-around max-h-[58px]'>
          <div className='flex group'>
            <div
              className={`p-3 text-gray-600 ${location.pathname === '/' && 'text-swatch-100'}`}
              onClick={() =>
                navigate(place ? VISITOR.PLACE.replace(':placeId', place.id) : VISITOR.MAIN)
              }
            >
              <span className='flex flex-col items-center'>
                <i
                  className='mdi mdi-home-outline mdi-24px mx-1 text-gray-500 group-hover:text-gray-700
									transition-color duration-200'
                ></i>
                <HomeIcon
                  className={
                    location.pathname === `/places/${place?.id}`
                      ? 'w-6 h-6 stroke-custom-main'
                      : 'w-6 h-6 stroke-gray-800'
                  }
                />
                <span className='text-xs mb-2 transition-all duration-200'>
                  <FormattedMessage id='home' />
                </span>
              </span>
            </div>
          </div>

          <div className='flex group absolute top-[-25px] drop-shadow-[0_0_5px_rgba(0,0,0,.5)]'>
            <div className='flex items-center justify-center w-[56px] h-[56px] rounded-full bg-gray-100'>
              <div className='text-gray-400' onClick={() => navigate(VISITOR.SCAN_QR)}>
                <span className='flex flex-col items-center'>
                  <i
                    className='mdi mdi-compass-outline mdi-24px mx-1 text-gray-500 group-hover:text-gray-700
									transition-color duration-200 '
                  ></i>
                  <QrIcon />
                </span>
              </div>
            </div>
          </div>

          <div className='flex group'>
            <div
              className={`p-3 text-gray-600 ${
                location.pathname === '/mytickets' && 'text-custom-main'
              }`}
              onClick={() => navigate(VISITOR.TICKETS)}
            >
              <span className='flex flex-col items-center'>
                <i
                  className='mdi mdi-compass-outline mdi-24px mx-1 text-gray-500 group-hover:text-gray-700
									transition-color duration-200'
                ></i>
                <TicketIcon
                  className={
                    location.pathname === '/mytickets'
                      ? 'w-6 h-6 stroke-custom-main'
                      : 'w-6 h-6 stroke-gray-800'
                  }
                />
                <span className='text-xs mb-2 transition-all duration-200'>
                  <FormattedMessage id='visits' />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
