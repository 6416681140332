import { DateTime } from 'luxon'

export const nextWorkingDay = (data, locale) => {
  const now = DateTime.local()
  const currentDay = now.toFormat('ccc').toUpperCase().slice(0, 2)
  const currentTime = now.toFormat('HH:mm')

  const workingTime = data[currentDay]?.workingTime
  const isOpen = data[currentDay]?.open

  if (isOpen) {
    if (!isWithinWorkingTime(workingTime, currentTime)) {
      return findNextOpenTime(data, currentDay, currentTime, locale)
    }
  } else {
    return findNextOpenTime(data, currentDay, currentTime, locale)
  }
}

function isWithinWorkingTime(workingTime, currentTime) {
  for (const interval of workingTime) {
    if (currentTime >= interval.openingTime && currentTime <= interval.closingTime) {
      return true
    }
  }
  return false
}

function findNextOpenTime(data, currentDay, currentTime, locale) {
  const days = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU']
  const dayIndex = days.indexOf(currentDay)

  for (let i = dayIndex; i < days.length + dayIndex; i++) {
    const day = days[i % days.length]
    const nextWorkingTime = data[day]?.workingTime
    const isOpen = data[day]?.open

    if (isOpen) {
      for (const interval of nextWorkingTime) {
        if (i === dayIndex && currentTime >= interval.openingTime) {
          continue
        }

        const targetDay = DateTime.local()
          .plus({ days: i - dayIndex })
          .toFormat('dd.MM.yyyy')

        const fullDayName = DateTime.local()
          .plus({ days: i - dayIndex })
          .toFormat('EEEE', {
            locale: locale,
          })

        return {
          day: fullDayName,
          date: targetDay,
          openingTime: interval.openingTime,
        }
      }
    }
  }

  return null
}
