import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { LOGO } from 'app/routes'
import { Container } from 'components/Container'
import { LanguageSwitcher } from 'components/LanguageSwitcher'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { usePlace } from 'store/usePlace'

export const Header = () => {
  const location = useLocation()
  const [logoUrl, setLogoUrl] = useState('')
  const [loadingLogo, setLoadingLogo] = useState(false)

  const { place } = usePlace()

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(place?.logo)
      .then(link => {
        setLogoUrl(link)
      })
      .catch(() => {
        setLogoUrl(LOGO)
      })
      .finally(() => {
        setLoadingLogo(false)
      })
  }, [place])
  return (
    <header className={`flex bg-custom-header min-h-[68px] md:py-4 md:min-h-[173px]`}>
      <Container className='md:max-w-screen-xl'>
        <div className='flex items-center w-full h-full relative'>
          <div className={`flex items-center w-full mr-4 md:ml-0`}>
            {(loadingLogo && (
              <div className='flex items-center min-w-[40px] w-16 h-10 mr-4'>
                <RoundSpinner width='w-7' height='h-7' />
              </div>
            )) || (
              <div className='absolute md:static top-5 ml-5 mr-8 md:mr-6 flex-shrink-0 md:h-[134px] md:w-[134px] md:w-auto md:h-auto border-solid border-4 border-white rounded-full'>
                <img
                  className={`inline-block h-16 w-16 md:h-[134px] md:w-[134px] rounded-full`}
                  src={logoUrl}
                  alt='2meters'
                />
              </div>
            )}
            <div
              className={`flex justify-end w-full ${
                location.state?.kioskMode === 'on' ? 'pt-6' : ''
              }`}
            >
              <LanguageSwitcher />
            </div>
          </div>
        </div>
      </Container>
    </header>
  )
}
