import { isLocaleLong, LocaleLong } from '@2meters/types'
import { create } from 'zustand'
import { persist, devtools } from 'zustand/middleware'

export function navigatorLanguage() {
  let language: string = 'en-GB'

  if (typeof navigator === 'undefined') return 'en-GB'
  console.log('navigator', { language: navigator.languages })

  if (Array.isArray(navigator.languages) && navigator.languages[0]) {
    // eslint-disable-next-line prefer-destructuring
    language = navigator.languages[0]
  } else {
    // @ts-ignore
    language = navigator.language || navigator.userLanguage
  }
  const longLanguage = shortToLongLocale[language.substring(0, 2)]
  return (isLocaleLong(longLanguage) ? longLanguage : 'en-GB') as LocaleLong
}

const shortToLongLocale = {
  en: 'en-GB',
  de: 'de-DE',
  lt: 'lt-LT',
  fr: 'fr-FR',
  pt: 'pt-BR',
  es: 'es-ES',
  uk: 'uk-UA',
  ru: 'ru-RU',
  tr: 'tr-TR',
}

const visitorMessages = {
  'en-GB': '/intl/en.json',
  'de-DE': '/intl/de.json',
  'lt-LT': '/intl/lt.json',
  'fr-FR': '/intl/fr.json',
  'pt-BR': '/intl/pt.json',
  'es-ES': '/intl/es.json',
  'uk-UA': '/intl/uk.json',
  'ru-RU': '/intl/ru.json',
  'tr-TR': '/intl/tr.json',
} as Record<string, any>
const visitorLocalesNames = Object.keys(visitorMessages) as LocaleLong[]

export interface VisitorLocaleStore {
  visitorLocale: LocaleLong
  visitorLocalesNames: LocaleLong[]

  currentMessages?: Record<string, string>
  setVisitorLocale: (locale: LocaleLong) => Promise<void>
}

export const useVisitorLocale = create<VisitorLocaleStore>()(
  devtools(
    persist(
      set => ({
        visitorLocale: navigatorLanguage(),
        currentMessages: undefined,
        visitorLocalesNames,
        setVisitorLocale: (locale: LocaleLong) => {
          return fetch(visitorMessages[locale])
            .then(response => response.json())
            .then(messages => set({ visitorLocale: locale, currentMessages: messages }))
        },
      }),
      {
        name: '2meters-visitor-locale',
      }
    )
  )
)
