import { memo } from 'react'
import IconWithBg from './IconWithBg'

const ClockIconWithBg = () => (
  <IconWithBg>
    <path
      className={`stroke-custom-main`}
      fillRule='evenodd'
      clipRule='evenodd'
      d='M32.25 23.0005C32.25 28.1095 28.109 32.2505 23 32.2505C17.891 32.2505 13.75 28.1095 13.75 23.0005C13.75 17.8915 17.891 13.7505 23 13.7505C28.109 13.7505 32.25 17.8915 32.25 23.0005Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      className={`stroke-custom-main`}
      d='M26.4316 25.9429L22.6616 23.6939V18.8469'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </IconWithBg>
)
export default memo(ClockIconWithBg)
