import { DefaultSlotDurationMin, Slot } from '@2meters/types'
import uuid from 'short-uuid'
import _ from 'lodash'
import { SchedulerInterval, SlotWoOccupancy } from './types'

export const calculateSlots = (intervals: SchedulerInterval[]): SlotWoOccupancy[] => {
  function* makeRangeIterator(interval: SchedulerInterval) {
    let iterationCount = 0

    let step = interval.slotDurationMin || DefaultSlotDurationMin
    for (
      let i = interval.startTime;
      i.plus({ minute: step }) <= interval.endTime;
      i = i.plus({ minute: step })
    ) {
      iterationCount += 1
      yield {
        from: i,
        to: i.plus({ minute: step }),
        interval,
        id: uuid.generate(),
      }
    }
    return iterationCount
  }

  return _(intervals)
    .flatMap(interval => Array.from(makeRangeIterator(interval)))
    .map(({ from, to, interval, id }) => {
      const label = `${from.setZone(interval.timezone).toFormat('HH:mm')}-${to
        .setZone(interval.timezone)
        .toFormat('HH:mm')}`

      return {
        id,
        startTime: from.toJSDate(),
        endTime: to.toJSDate(),
        label,
        key: label,
        disabled: false,
        serviceId: interval.serviceId,
        shopCapacity: interval.shopCapacity || 1,
      } as SlotWoOccupancy
    })
    .groupBy(slot => slot.key)
    .map((group, key) => {
      // Combining capacity of overlaping slots
      const slot: SlotWoOccupancy = _.reduce(group, (prev, cur) => ({
        ...prev,
        shopCapacity: prev.shopCapacity + cur.shopCapacity,
      }))!

      return slot
    })
    .sortBy(i => i.startTime)
    .value()
}
