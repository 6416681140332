import { DING_DONG_SOUND } from 'assets/urls'
import { create } from 'zustand'

export interface DingDongStore {
  play: () => void
  subscribe: () => void
  unsubscribe: () => void
  clickHandler: () => void
}

const eventTypes = ['click', 'touchstart', 'touchend', 'mousedown', 'keydown']

let audio: HTMLAudioElement | undefined
function dingDong() {
  if (!audio) {
    audio = new Audio()
    audio.src =
      'data:audio/mpeg;base64,SUQzBAAAAAABEVRYWFgAAAAtAAADY29tbWVudABCaWdTb3VuZEJhbmsuY29tIC8gTGFTb25vdGhlcXVlLm9yZwBURU5DAAAAHQAAA1N3aXRjaCBQbHVzIMKpIE5DSCBTb2Z0d2FyZQBUSVQyAAAABgAAAzIyMzUAVFNTRQAAAA8AAANMYXZmNTcuODMuMTAwAAAAAAAAAAAAAAD/80DEAAAAA0gAAAAATEFNRTMuMTAwVVVVVVVVVVVVVUxBTUUzLjEwMFVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQsRbAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVf/zQMSkAAADSAAAAABVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVVV'
  }
  return audio
}

export const useDingDong = create<DingDongStore>((set, get) => ({
  play: () => {
    return dingDong().play()
  },
  clickHandler: async () => {
    const audio = dingDong()
    await audio?.play()
    console.log(`Sound Unlocked`)
    audio.src = DING_DONG_SOUND
    audio.volume = 1.0
    get().unsubscribe()
  },
  subscribe: () => {
    eventTypes.forEach(eventType => {
      window?.addEventListener(eventType, get().clickHandler)
    })
    return get().unsubscribe
  },
  unsubscribe: () => {
    eventTypes.forEach(eventType => {
      window?.removeEventListener(eventType, get().clickHandler)
    })
  },
}))

export default useDingDong
