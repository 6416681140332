import { Appointment, AppointmentStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, collectionGroup, onSnapshot, query, where } from 'firebase/firestore'
import { create } from 'zustand'

interface PlaceTicketsStore {
  placeId: string | undefined
  isLoading: boolean
  queueTickets: Appointment[]
  subscribeToQueueTickets: (placeId: string, queueId: string) => void
  unsubscribeFromQueueTickets: () => void
}

export const useQueueTickets = create<PlaceTicketsStore>(set => ({
  placeId: undefined,
  isLoading: true,
  queueTickets: [],

  subscribeToQueueTickets: (placeId, queueId) => {
    set({ isLoading: true, placeId })
    if (!placeId) {
      set({ queueTickets: [], isLoading: false })
      return
    }
    const ref = query(
      collection(db, `places/${placeId}/tickets_short`),
      where('queueId', '==', queueId),
      where('status', 'in', ['queued'])
    )
    console.log('Subscribing to queueTickets', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async snap => {
        const queueTickets = snap.docs.map(doc => doc.data() as Appointment)
        // const queueTickets = querySnapshot.docs.map(doc => AppointmentStruct.create(doc.data()))
        console.log('queueTickets updated', queueTickets)
        set({ queueTickets, isLoading: false })
      },
      (error: any) => {
        console.error('Subscribing to queueTickets failed', error)
        set({ queueTickets: [], isLoading: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from queueTickets')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromQueueTickets: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromQueueTickets: () => {
    console.log('Unsubscribing from queueTickets')
  },
}))
