import { AppointmentShort, Slot } from '@2meters/types'
import { SlotWoOccupancy } from './types'

/**
 * fills in the booked appointments inside every availability slot
 */
export const fillInOccupiedSlots = (
  appointments: AppointmentShort[],
  slots: SlotWoOccupancy[],
  scheduleId: string
): Slot[] => {
  return slots.map(slot => {
    const occupancy = appointments.filter(
      app =>
        app.scheduleId &&
        app.scheduleId === scheduleId &&
        app.startTime &&
        app.startTime.toDate() >= slot.startTime &&
        app.startTime.toDate() < slot.endTime
    )
    //TODO use NumberOfVisitors
    return { ...slot, occupancy: occupancy.length, appointments: occupancy }
  })
}
