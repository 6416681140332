import { App } from '../app/App'
import { GetStaticPaths, GetStaticProps } from 'next'

import './globals.css'

export const getStaticProps: GetStaticProps = () => {
  return {
    props: {},
  }
}

export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [
      {
        params: {
          slug: [] as string[],
        },
      },
    ],
    fallback: 'blocking',
  }
}

export default App
