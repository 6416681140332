import { CancelAppointmentRequestStruct, Urls } from '@2meters/types'
import { functions } from 'api/Firebase/firebase-init'
import { httpsCallable } from 'firebase/functions'

export function cancelTicket(request: CancelAppointmentRequestStruct) {
  const bookAppointmentCloudFunc = httpsCallable(
    functions,
    `${Urls.visitor.prefix}${Urls.visitor.cancelAppointment}`
  )

  return bookAppointmentCloudFunc(request)
}
