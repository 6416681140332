export const VISITOR = {
  MAIN: '/',
  TICKET: '/places/:placeId/ticket/:id',
  TICKETS: '/mytickets',
  PLACE: '/places/:placeId',
  BOOK: '/places/:placeId/book',
  SCAN_QR: '/qr',
  KIOSK: '/places/:placeId/kiosk',
  NOT_FOUND: '/404',
}

export const SCANNER = '/scanner'
export const ADMIN_PAGE = '/admin'
export const LOGO = '/logo.svg'

export const BASE_URL =
  typeof window !== 'undefined' ? window?.location?.origin : 'https://book.2meters.app'

export const TERMS = 'https://2meters.app/en/terms-and-conditions/'
export const PRIVACY_POLICY = 'https://2meters.app/en/privacy-policy/'
