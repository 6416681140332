import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
  stroke?: string
}

const CalendarIcon = ({
  className = 'w-5 h-5 stroke-gray-700',
  fill = 'none',
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg className={className} viewBox={viewBox} fill={fill}>
    <path
      d='M3.92822 8.13293H16.7614'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M13.2515 2.80005V5.16944'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M7.43506 2.80005V5.16944'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.3921 3.9375H7.29584C5.18098 3.9375 3.86084 5.11519 3.86084 7.27987V13.7965C3.86084 15.9954 5.18098 17.2003 7.29584 17.2003H13.3859C15.5069 17.2003 16.8209 16.0164 16.8209 13.851V7.27987C16.8271 5.11519 15.5132 3.9375 13.3921 3.9375Z'
      strokeWidth='1.44'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(CalendarIcon)
