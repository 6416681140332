import { onAuthStateChanged, signInAnonymously, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useCurrentUser } from 'store/useCurrentUser'
import { PacmanSpinner } from 'components/Spinner'
import { auth } from 'api/Firebase/firebase-init'

export const WithAuthentication = () => {
  const { authUser, subscribeToAuthUser, unsubscribeFromAuthUser } = useCurrentUser()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (authUser: User | null) => {
      if (authUser) {
        if (process.env.NODE_ENV === 'development') {
          console.log(`Signed in user. userid=${authUser.uid} isAnonymous=${authUser.isAnonymous}`)
        }
        subscribeToAuthUser(authUser)
      } else {
        if (process.env.NODE_ENV === 'development') {
          console.log('No Authenticated User, authenticating anonymously')
        }
        signInAnonymously(auth).catch(error => {
          console.error(error.message)
        })
        unsubscribeFromAuthUser()
      }
      setLoading(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return !loading && authUser && authUser.uid ? (
    <Outlet />
  ) : (
    <div className='flex justify-center items-center h-screen'>
      <PacmanSpinner />
    </div>
  )
}
