import { messaging, publicVapidKey } from 'api/Firebase/firebase-init'
import { setFCMTokenInAppointments } from 'backend/setFCMTokenInAppointments'
import { getToken, isSupported } from 'firebase/messaging'

export const getFCMToken = async () => {
  console.log('Registring service worker for notifications')
  const isMessaggingSupported = await isSupported()
  console.log('isMessaggingSupported', isMessaggingSupported)
  const registration = await navigator.serviceWorker
    .register('/firebase-messaging-module-sw.js', {
      scope: '/firebase-cloud-messaging-push-scope',
      type: 'module',
      updateViaCache: 'imports',
    })
    .catch(e => {
      console.error('FAILED TO REGISTER SERVICE WORKER', e)
      throw e
    })

  if (isMessaggingSupported && messaging && registration) {
    let FCMToken: string | undefined = undefined
    try {
      FCMToken = await getToken(messaging!, {
        serviceWorkerRegistration: registration,
        vapidKey: publicVapidKey,
      })
      localStorage.setItem('userOrigin', FCMToken)
      console.log('Service worker registered', FCMToken)
    } catch (e) {
      console.log('FAILED TO getToken', e)
      throw Error('Messaging is not supported')
    }

    FCMToken && setFCMTokenInAppointments(FCMToken)

    return FCMToken
  }

  console.log('Failed to register service worker ')
  throw Error('Messaging is not supported')
}
