import { FormattedMessage as INTLFormattedMessage } from 'react-intl'
import { usePlace } from 'store/usePlace'
import { ReactElement } from 'react'

interface props {
  id: string
}

const FormattedMessage = ({ id }: props): ReactElement => {
  const { place } = usePlace()

  if (place && place.translations && place.translations[id]) {
    return <>{place.translations[id]}</>
  }

  return <INTLFormattedMessage id={id} />
}

export default FormattedMessage
