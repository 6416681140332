import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
  stroke?: string
}

const ClockIcon = ({
  className = 'w-5 h-5 stroke-gray-700',
  fill = 'none',
  viewBox = '0 0 20 20',
}: IconProps) => (
  <svg className={className} viewBox={viewBox} fill={fill}>
    {/* <rect x='3' y='3' width='40' height='40' rx='20' fill='#F4EBFF' /> */}
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.7082 10.0004C17.7082 14.2579 14.2573 17.7088 9.99984 17.7088C5.74234 17.7088 2.2915 14.2579 2.2915 10.0004C2.2915 5.74295 5.74234 2.29211 9.99984 2.29211C14.2573 2.29211 17.7082 5.74295 17.7082 10.0004Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.8594 12.4524L9.71777 10.5782V6.53906'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(ClockIcon)
