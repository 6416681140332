import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import FormattedMessage from 'utils/FormattedMessage'

interface ProductItemProps {
  product: any
}

export const ProductItem = ({ product }: ProductItemProps) => {
  const { productId, name, quantity, price, currency, imageFile } = product
  const [loadingLogo, setLoadingLogo] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(imageFile)
      .then(link => {
        setLogoUrl(link)
        setLoadingLogo(false)
      })
      .catch(() => {
        setLogoUrl('')
        setLoadingLogo(false)
      })
  }, [imageFile])

  return (
    <div className='flex mb-3' key={productId}>
      {(loadingLogo && (
        <div className='flex items-center justify-center min-w-[40px] h-10'>
          <RoundSpinner width='w-8' height='h-8' />
        </div>
      )) || (
        <div className='mr-4 mt-1 flex-shrink-0'>
          {logoUrl ? (
            <img className='inline-block h-8 w-8 rounded-[3px]' src={logoUrl} alt={name} />
          ) : (
            <svg
              className='h-9 w-9 border border-gray-300 bg-white text-gray-300'
              preserveAspectRatio='none'
              stroke='currentColor'
              fill='none'
              viewBox='0 0 200 200'
              aria-hidden='true'
            >
              <path
                vectorEffect='non-scaling-stroke'
                strokeWidth={1}
                d='M0 0l200 200M0 200L200 0'
              />
            </svg>
          )}
        </div>
      )}
      <div className='flex flex-col w-full'>
        <div className='flex items-start justify-between'>
          <h4 className='text-sm   text-gray-700 font-semibold'>{name}</h4>
          <p className='text-sm font-semibold'>
            <FormattedMessage id='quantity' />: {quantity}
          </p>
        </div>

        <p className='text-sm  text-gray-500 '>
          <FormattedMessage id='price' />: {currency}
          {price}
        </p>
      </div>
    </div>
  )
}
