import _ from 'lodash'

export default function readResponseAs<A>(data: any): A {
  function mapValuesDeep(obj: any, callback: (value: any) => any): any {
    if (_.isArray(obj)) {
      return obj.map(item => mapValuesDeep(item, callback))
    }

    if (_.isObject(obj)) {
      return _.mapValues(obj, value => mapValuesDeep(value, callback))
    }

    return callback(obj)
  }

  const withDates = mapValuesDeep(data, (v: any) => {
    if (v && v.type === 'date') return new Date(v.value)
    return v
  })

  return withDates as A
}
