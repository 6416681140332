import { OpeningHours, weekdayFromIndex } from '@2meters/types'
import { Disclosure } from '@headlessui/react'
import { calculateAvailability } from 'api/Firebase/scheduler/calculateAvailability'
import { ChevronDownIcon } from 'assets/icons'
import { ButtonLoading } from 'components/Buttons'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAppointmentsShort } from 'store/useAppointmentsShort'
import { usePlace } from 'store/usePlace'
import { useSchedules } from 'store/useSchedules'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { localTimezone } from 'utils/timez'
import { useTranslation } from 'utils/useTranslation'
import { TitleStep } from '../ui'
import { useWizardSteps } from '../useWizardSteps'
import { DesktopInfoBlock } from './DesktopInfoBlock'
import { TimeSlotOption } from './TimeSlotOption'

export const TimeSlotStep = () => {
  const nativeIntl = useIntl()
  const intl = useTranslation()
  const { place } = usePlace()
  const { subscribeToSchedules } = useSchedules()
  const { appointments, subscribeToAppointments } = useAppointmentsShort()
  const { wizardForm } = useWizardForm()
  const { schedule } = useWizardSteps()
  const timeZone = place?.timezone || localTimezone
  const today = DateTime.local().setZone(timeZone).startOf('day')
  const [openDays, setOpenDays] = useState<OpeningHours | undefined>(undefined)
  const [isPrevWeekDisabled, setIsPrevWeekDisabled] = useState(true)

  const [thisWeek, setThisWeek] = useState(
    [0, 1, 2, 3, 4, 5, 6].map((d, i) => today.setZone(timeZone).startOf('week').plus({ day: i }))
  )

  useEffect(() => {
    if (wizardForm.placeId) return subscribeToSchedules(wizardForm.placeId)
  }, [wizardForm.placeId])

  useEffect(() => {
    if (wizardForm.placeId && wizardForm.scheduleId)
      return subscribeToAppointments(
        wizardForm.placeId,
        wizardForm.scheduleId,
        thisWeek[0],
        thisWeek[6].endOf('day')
      )
  }, [wizardForm.placeId, wizardForm.scheduleId, thisWeek])

  const currentAppointments = appointments.filter(
    appointment => appointment.scheduleId === wizardForm.scheduleId
  )

  useEffect(() => {
    const workdays = {} as any
    schedule?.dateRanges.map(sch =>
      sch.timeRanges.map(range =>
        range.days.map(day =>
          !workdays[day.toUpperCase()] ? (workdays[day.toUpperCase()] = { open: true }) : null
        )
      )
    )
    setOpenDays(workdays)
  }, [schedule])

  const handleWeekMove = (i: number) => {
    const currentMonday = thisWeek[0]
    const newMonday = i > 0 ? currentMonday.plus({ weeks: 1 }) : currentMonday.minus({ weeks: 1 })
    setIsPrevWeekDisabled(newMonday.startOf('week') <= today.startOf('week'))
    setThisWeek([0, 1, 2, 3, 4, 5, 6].map(d => newMonday.plus({ days: d })))
  }

  const [activeDisclosurePanel, setActiveDisclosurePanel] = useState<any>(null)

  function togglePanels(newPanel) {
    if (activeDisclosurePanel) {
      if (activeDisclosurePanel.key !== newPanel.key && activeDisclosurePanel.open) {
        activeDisclosurePanel.close()
      }
    }

    setActiveDisclosurePanel({
      ...newPanel,
      open: !newPanel.open,
    })
  }

  return (
    <div>
      <TitleStep
        title={intl({
          id: 'wizardForm.selectSlot.title',
        })}
        description={intl({
          id: 'wizardForm.selectSlot.description',
        })}
      />
      <div className='md:grid md:grid-cols-2 md:gap-4'>
        <div className='mb-5 md:mb-[80px] md:mt-8'>
          {(openDays && Object.keys(openDays).length > 0 && (
            <>
              <ButtonLoading
                onClick={() => {
                  handleWeekMove(-1)
                  if (activeDisclosurePanel && typeof activeDisclosurePanel.close === 'function') {
                    activeDisclosurePanel.close()
                  }
                }}
                style='w-full text-gray-700 dark:text-gray-100 bg-custom-main'
                disabled={isPrevWeekDisabled}
              >
                <FormattedMessage id='wizardForm.selectSlot.prevWeek' />
              </ButtonLoading>
              {thisWeek.map((dayOfWeek, idx) => {
                const dayIndex = dayOfWeek.get('weekday')
                dayOfWeek.get('weekNumber')
                const correctDayName = weekdayFromIndex(dayIndex)

                const availabilityData =
                  schedule && wizardForm?.serviceId
                    ? calculateAvailability(
                        schedule,
                        currentAppointments,
                        timeZone,
                        wizardForm?.serviceId,
                        dayOfWeek
                      )
                    : []

                return (
                  <Disclosure
                    key={dayIndex}
                    defaultOpen={dayIndex === wizardForm.selectedTime?.disclosureId}
                  >
                    {panel => {
                      const { open, close } = panel
                      return (
                        <>
                          {openDays && (
                            <Disclosure.Button
                              disabled={
                                !openDays[correctDayName]?.open ||
                                dayOfWeek.startOf('day') < today ||
                                availabilityData?.every(
                                  item => item.occupancy === item.shopCapacity
                                )
                              }
                              onClick={() => {
                                if (!open) {
                                  close()
                                }

                                togglePanels({ ...panel, key: idx })
                              }}
                              className='flex w-full justify-between rounded-lg border border-custom-default px-4 py-2 text-left text-sm font-medium text-gray-700 dark:text-gray-100 hover:bg-white/10 focus:outline-none mb-4 focus-visible:ring focus-visible:ring-white focus-visible:ring-opacity-75 disabled:opacity-50'
                            >
                              <span className='text-gray-700 dark:text-gray-100 font-normal'>
                                {dayOfWeek.toFormat('EEEE LLLL dd', {
                                  locale: nativeIntl.locale,
                                })}
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? 'rotate-180 transform' : ''
                                } h-5 w-5 text-gray-700 dark:text-gray-100`}
                              />
                            </Disclosure.Button>
                          )}
                          <Disclosure.Panel className='p-2 text-sm ring-1 ring-custom-default shadow-lg rounded-[8px] mt-2 mb-3'>
                            <div className='flex flex-wrap'>
                              {availabilityData
                                ?.filter(slot => {
                                  return (
                                    DateTime.fromISO(slot.startTime.toISOString()) >
                                      DateTime.now() && slot.occupancy < slot.shopCapacity
                                  )
                                })
                                .map((slot, index) => {
                                  return (
                                    <TimeSlotOption
                                      slot={slot}
                                      index={index}
                                      key={index}
                                      disclosureId={dayIndex}
                                      fullDayName={dayOfWeek.toFormat('EEEE LLLL dd')}
                                    />
                                  )
                                })}
                              {wizardForm.selectedTime?.startTime &&
                                DateTime.fromISO(wizardForm.selectedTime!.startTime).get(
                                  'weekNumber'
                                ) === dayOfWeek.get('weekNumber') && (
                                  <div className='flex justify-center items-center w-full my-2'>
                                    <span className='inline-flex items-center rounded-full px-3 py-1 text-xs font-medium text-gray-700 dark:text-gray-100'>
                                      <FormattedMessage id='slotOccupancy' />:{' '}
                                      {wizardForm.selectedTime?.occupancy} /{' '}
                                      {wizardForm.selectedTime?.shopCapacity}
                                    </span>
                                  </div>
                                )}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )
                    }}
                  </Disclosure>
                )
              })}
              <ButtonLoading
                onClick={() => {
                  handleWeekMove(1)
                  if (activeDisclosurePanel && typeof activeDisclosurePanel.close === 'function') {
                    activeDisclosurePanel.close()
                  }
                }}
                style='w-full bg-custom-main'
              >
                <FormattedMessage id='wizardForm.selectSlot.nextWeek' />
              </ButtonLoading>
            </>
          )) || (
            <div className='text-center text-gray-700 dark:text-gray-100'>
              <FormattedMessage id='wizardForm.selectSlot.noOpenHours' />
            </div>
          )}
        </div>

        <DesktopInfoBlock />
      </div>
    </div>
  )
}
