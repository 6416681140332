export const formatTimeToLocale = timeRange => {
  const locale = window.navigator.language
  if (timeRange.includes('-')) {
    const [start, end] = timeRange.split('-')
    return `${localizeTime(start, locale)}-${localizeTime(end, locale)}`
  } else {
    return localizeTime(timeRange, locale)
  }
}

const localizeTime = (time, locale) => {
  const sampleDate = new Date(`1970-01-01T${time}:00Z`)

  const localeTimeString = sampleDate.toLocaleTimeString(locale, {
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC',
  })

  const [hour, minute] = time.split(':')
  return localeTimeString.replace(/\d{1,2}:\d{1,2}/, `${hour}:${minute}`)
}
