import { memo } from 'react'

type IconProps = {
  className?: string
  viewBox?: string
}

const XMarkIcon = ({ className = 'w-6 h-6', viewBox = '0 0 24 24' }: IconProps) => (
  <svg fill='none' viewBox={viewBox} strokeWidth={1.5} stroke='currentColor' className={className}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
  </svg>
)
export default memo(XMarkIcon)
