//LOGOS
export const WHATS_APP_LOGO_URL = '/icons/whatsapp-logo.svg'
export const _2METERS_LOGO_FULL_WHITE_BG_URL = '/icons/2meters-logo-full.png'

//SOUNDS
export const DING_DONG_SOUND = '/dingdong.mp3'

//SOCIALS
export const FACEBOOK_LOGO_ICON = '/icons/socials_facebook.svg'
export const INSTAGRAM_LOGO_ICON = '/icons/socials_instagram.svg'
export const LINKED_IN_LOGO_ICON = '/icons/socials_linkedIn.svg'
export const TWITTER_LOGO_ICON = '/icons/socials_twitter.svg'
