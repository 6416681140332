import { ChangeEvent } from 'react'

interface CheckboxOptionProps {
  id: string
  value: string
  disabled?: boolean
  isChecked?: boolean | undefined
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CheckboxOption = ({
  id,
  value,
  isChecked = false,
  disabled = false,
  onChange,
}: CheckboxOptionProps) => {
  return (
    <div className='relative flex items-start'>
      <label
        htmlFor={id}
        className={`relative text-gray-700 dark:text-gray-100 flex cursor-pointer rounded-lg px-5 py-4 border border-solid border-gray-300 w-full`}
      >
        <div className=''>
          <input
            id={id}
            aria-describedby={`${id}-description`}
            name={id}
            type='checkbox'
            className={`h-4 w-4 rounded mr-3 border-solid-1 border-gray-300 checked:border-gray-300 text-custom-highlight text-md ring-swatch-700 `}
            value={value}
            defaultChecked={isChecked}
            onChange={disabled ? undefined : onChange}
            disabled={disabled}
          />
        </div>
        <div className={`text-base text-gray-900 dark:text-gray-100`}>{value}</div>
      </label>
    </div>
  )
}
