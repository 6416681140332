import { Queue, QueueStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

interface QueueStore {
  placeId: string | undefined
  isLoadingQueues: boolean
  queues: Queue[]
  subscribeToQueues: (placeId: string) => void
  unsubscribeFromQueues: () => void
}

export const useQueues = create<QueueStore>(set => ({
  placeId: undefined,
  isLoadingQueues: false,
  queues: [],

  subscribeToQueues: placeId => {
    set({ isLoadingQueues: true, placeId })
    if (!placeId) {
      set({ queues: [], isLoadingQueues: false })
      return
    }

    const ref = collection(db, `places/${placeId}/queues/`)
    console.log('Subscribing to queues', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const queues = querySnapshot.docs.map(doc => QueueStruct.create(doc.data()))
        console.log('Queues updated', queues)
        set({ queues: queues, isLoadingQueues: false })
      },
      (error: any) => {
        console.error('Subscribing to queues failed', error)
        set({ queues: [], isLoadingQueues: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from queues')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromQueues: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromQueues: () => {
    console.log('Unsubscribing from queues')
  },
}))
