import { RadioGroup } from '@headlessui/react'
import { NummericStepper } from 'components/NumericStepper/NumericStepper'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { usePlace } from 'store/usePlace'
import useQueueStats from 'store/useQueueStats'
import { useQueueTickets } from 'store/useQueueTickets'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { nextWorkingDay } from 'utils/nextWorkingDay'
import { useTranslation } from 'utils/useTranslation'
import { TitleStep } from '../../ui'
import { useWizardSteps } from '../../useWizardSteps'
import { SelectablePanel } from '../SelectablePanel'

type Props = {
  skipStep: () => void
}

export const ChoiceStep = ({ skipStep }: Props) => {
  const t = useTranslation()
  const intl = useIntl()

  const { place } = usePlace()
  const { queueStats, isLoading: isLoadingQueueStats } = useQueueStats()
  const { queueTickets, isLoading: isLoadingTickets } = useQueueTickets()
  const { wizardForm, updateWizardForm } = useWizardForm()
  const { service, queue, schedule, isQueueWorkingTime, isQueueAtMaxLength, isQueueDisabled } =
    useWizardSteps()
  const [averageProcessingTime, setAverageProcessingTime] = useState(0)
  const [averageProcessingTimeType, setAverageProcessingTimeType] = useState('')
  const [numberOfVisitors, setNumberOfVisitors] = useState(
    wizardForm.contactData.numberOfVisitors || 1
  )
  const isNextWorkingDay = nextWorkingDay(place?.openingHours, intl.locale)
  const queuesLengthCount = queueTickets?.length || 0

  const queueDisabledMassage: string[] = []

  if (!isQueueWorkingTime) {
    queueDisabledMassage.push(
      t({
        id: 'wizardForm.choiceType.errorMessage.1',
      })
    )
  }
  if (isQueueAtMaxLength) {
    queueDisabledMassage.push(
      t({
        id: 'wizardForm.choiceType.errorMessage.2',
      })
    )
  }
  if (queue?.paused) {
    queueDisabledMassage.push(
      t({
        id: 'wizardForm.choiceType.errorMessage.3',
      })
    )
  }

  const realWaitingTime =
    queue?.estimatedWaitingTimeConstantMinutes &&
    queue?.estimatedWaitingTimeConstantMinutes * queuesLengthCount

  const isLoading = isLoadingQueueStats || isLoadingTickets

  const maxSlotCapacity =
    schedule?.dateRanges?.reduce((max, obj) => Math.max(max, obj.capacity), 0) || 1

  useEffect(() => {
    if (queueStats?.averageProcessingTimeSec) {
      if (queueStats.averageProcessingTimeSec >= 60) {
        setAverageProcessingTime(Math.round(queueStats.averageProcessingTimeSec / 60))
        setAverageProcessingTimeType('min')
      } else {
        setAverageProcessingTime(Math.round(queueStats.averageProcessingTimeSec))
        setAverageProcessingTimeType('sec')
      }
    }
  }, [queueStats?.averageProcessingTimeSec])

  useEffect(() => {
    if (schedule) {
      if (numberOfVisitors > maxSlotCapacity) {
        setNumberOfVisitors(maxSlotCapacity)
      }
    }
  }, [schedule?.dateRanges, maxSlotCapacity, numberOfVisitors])

  useEffect(() => {
    updateWizardForm({
      contactData: {
        ...wizardForm.contactData,
        numberOfVisitors: numberOfVisitors,
      },
    })
  }, [numberOfVisitors])

  // preselect if only one option is available
  useEffect(() => {
    // only queue
    if (!wizardForm.queueType && service?.queueId && !service?.scheduleId) {
      if (isQueueDisabled) return
      selectQueue()
    }

    console.log('Should skipp step?', wizardForm.queueType, service?.queueId, service?.scheduleId)
    // only schedule
    if (!wizardForm.queueType && !service?.queueId && service?.scheduleId) {
      selectSchedule()
      console.log('skipping step')
      skipStep()
    }
  }, [service?.scheduleId, service?.queueId, wizardForm])

  const selectQueue = () => {
    updateWizardForm({
      queueType: 'queue',
      queueId: service?.queueId,
      scheduleId: undefined,
    })
  }

  const selectSchedule = () => {
    updateWizardForm({
      queueType: 'schedule',
      queueId: undefined,
      scheduleId: service?.scheduleId,
    })
  }

  const onSelect = id => {
    if (id === service?.queueId) {
      selectQueue()
    } else if (id === service?.scheduleId) {
      selectSchedule()
    }
  }

  if (!place) return null

  return (
    <div>
      <TitleStep
        title={t({
          id: 'wizardForm.choiceType.title',
        })}
        description={t({
          id: 'wizardForm.choiceType.description',
        })}
      />

      {place.contactDataConfig?.askNumberOfVisitors && maxSlotCapacity > 1 && (
        <div className='mb-4'>
          <p className='text-sm text-gray-700 dark:text-gray-100'>
            <FormattedMessage id='numberOfVisitors' />
          </p>
          <NummericStepper
            defaultValue={wizardForm.contactData.numberOfVisitors || 1}
            onChange={setNumberOfVisitors}
          />
        </div>
      )}

      <RadioGroup
        defaultValue={wizardForm.scheduleId || wizardForm.queueId}
        value={wizardForm.queueId || wizardForm.scheduleId || ''}
        onChange={onSelect}
      >
        <div className='space-y-2 md:space-y-0 md:grid md:grid-cols-2 md:gap-4'>
          {!!service?.queueId && service.queueId !== 'none' && (
            <SelectablePanel
              disabled={isQueueDisabled}
              value={service.queueId}
              title='wizardForm.choice.queue'
            >
              {isLoading ? (
                <div className='flex items-center justify-center min-w-[40px] h-10 mb-3'>
                  <RoundSpinner width='w-7' height='h-7' />
                </div>
              ) : (
                <>
                  <div className='mb-3'>
                    <p className='text-sm text-gray-600 dark:text-gray-200 font-normal'>
                      <FormattedMessage id='wizardForm.frontYou' />
                    </p>
                  </div>
                  <div className='mb-5'>
                    <span className='bg-custom-header text-gray-900 dark:text-gray-100 text-sm font-medium mr-2 px-3.5 py-1.5 rounded-full'>
                      {queuesLengthCount} <FormattedMessage id='people' />
                    </span>
                  </div>

                  <div className='mb-4 py-2 inline-flex items-center flex-col'>
                    {queueDisabledMassage.length > 0 && (
                      <span className='inline-flex items-center text-center flex-col rounded-full px-3 py-1 text-sm font-medium text-error-500'>
                        {queueDisabledMassage.map((message, i) => (
                          <p key={i} className='mb-2'>
                            {message}
                          </p>
                        ))}
                      </span>
                    )}
                    {isNextWorkingDay && (
                      <span className='inline-flex items-center text-center rounded-full bg-custom-element_active px-3 py-1 text-sm font-medium'>
                        <p>
                          <FormattedMessage id='openedOn' /> {isNextWorkingDay?.day}{' '}
                          {isNextWorkingDay?.date} at {isNextWorkingDay?.openingTime}
                        </p>
                      </span>
                    )}
                  </div>

                  {!isQueueDisabled && (
                    <>
                      {queue?.estimatedWaitingTimeMode === 'auto' && queuesLengthCount > 0 && (
                        <div className='mb-5'>
                          <span className='text-gray-700 dark:text-gray-100 text-sm font-medium mr-2 px-3.5 py-1.5 rounded-full'>
                            <FormattedMessage id='waitingTime' />:{' '}
                            {queuesLengthCount > 0
                              ? averageProcessingTime * queuesLengthCount
                              : averageProcessingTime}
                            {averageProcessingTimeType && (
                              <FormattedMessage id={`${averageProcessingTimeType}`} />
                            )}
                          </span>
                        </div>
                      )}
                      {queue?.estimatedWaitingTimeMode === 'constant' && (
                        <div className='mb-5'>
                          <span className='text-gray-700 dark:text-gray-100 text-sm font-medium mr-2 px-3.5 py-1.5 rounded-full'>
                            <FormattedMessage id='waitingTime' />:
                            <span className='ml-1'>{realWaitingTime}</span>
                            <FormattedMessage id='min' />
                          </span>
                        </div>
                      )}
                    </>
                  )}
                </>
              )}
            </SelectablePanel>
          )}

          {!!service?.scheduleId && (
            <SelectablePanel value={service?.scheduleId} title='wizardForm.choice.schedule'>
              <div className='flex flex-col justify-center items-center rounded-b-md p-3 h-full min-h-[100px]'>
                <p className='text-sm text-gray-700 dark:text-gray-100 font-normal'>
                  <FormattedMessage id='chooseTime' />
                </p>
              </div>
            </SelectablePanel>
          )}
        </div>
      </RadioGroup>
    </div>
  )
}
