import { Appointment } from '@2meters/types'
import { TicketTabType } from '../types'
import { useTranslation } from 'utils/useTranslation'

interface TabsProps {
  app: Appointment | undefined
  currentTab: TicketTabType | undefined
  onChange: (tab: TicketTabType) => void
}

export const Tabs = ({ app, currentTab, onChange }: TabsProps) => {
  const intl = useTranslation()
  const classNames = (...classes) => classes.filter(Boolean).join(' ')

  const isEmpty = obj => {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false
      }
    }
    return true
  }

  const tabs = [
    {
      name: intl({
        id: 'details',
      }),
      current: true,
    },

    ...(!isEmpty(app?.formData?.data)
      ? [
          {
            name: intl({
              id: 'info',
            }),
            current: false,
          },
        ]
      : []),

    ...(!isEmpty(app?.contactData)
      ? [
          {
            name: intl({
              id: 'personal',
            }),
            current: false,
          },
        ]
      : []),

    ...(app?.order
      ? [
          {
            name: intl({
              id: 'products',
            }),
            current: false,
          },
        ]
      : []),
  ]

  return (
    <div>
      <div className='block'>
        <div className='border-b border-gray-200'>
          <nav className='-mb-px flex space-x-7 pl-4 overflow-x-scroll' aria-label='Tabs'>
            {tabs.map(tab => {
              return (
                <a
                  href='#'
                  key={tab.name}
                  onClick={e => {
                    e.preventDefault()
                    onChange(tab)
                  }}
                  className={classNames(
                    tab.name === currentTab?.name
                      ? 'border-indigo-500 text-gray-600'
                      : 'border-transparent text-gray-600 opacity-30 hover:border-gray-300 hover:text-gray-700',
                    'whitespace-nowrap border-b-4 pt-4 pb-2 px-1 text-sm font-medium'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </a>
              )
            })}
          </nav>
        </div>
      </div>
    </div>
  )
}
