import { VISITOR } from 'app/routes'
import { ExclamationTriangleIcon } from 'assets/icons'
import { ButtonLoading } from 'components/Buttons'
import { Modal, ModalContent, ModalIcon } from 'components/Modal'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useActiveTickets } from 'store/useActiveTickets'
import { useCurrentUser } from 'store/useCurrentUser'
import { usePlace } from 'store/usePlace'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'
import { SERVICE_STEP, useWizardSteps } from '../useWizardSteps'

export const TicketSettingModal = () => {
  const intl = useTranslation()
  const uid = useCurrentUser(s => s.authUser?.uid)
  const navigate = useNavigate()
  const { tickets, subscribe, unsubscribe } = useActiveTickets()
  const { place } = usePlace()
  const [show, setShow] = useState(false)

  const { activeStep } = useWizardSteps()

  useEffect(() => {
    if (place?.id && uid) {
      subscribe(place.id, uid)
    }
    return unsubscribe()
  }, [place?.id, uid])

  const oneTicketPerPlace =
    place?.settings?.appointmentsPerUser === 'one-per-place' &&
    tickets.length > 0 &&
    activeStep === SERVICE_STEP

  const unlimitedTickets =
    place?.settings?.appointmentsPerUser === 'unlimited' &&
    tickets.length > 0 &&
    activeStep === SERVICE_STEP

  useEffect(() => {
    setShow(oneTicketPerPlace || unlimitedTickets)
  }, [oneTicketPerPlace, unlimitedTickets])

  return (
    <Modal
      open={show}
      closeIcon={false}
      submitButton={
        <ButtonLoading
          onClick={() => {
            navigate(
              VISITOR.TICKET.replace(':placeId', place?.id || '').replace(':id', tickets[0].id)
            )
          }}
          style={'btn-primary'}
        >
          <FormattedMessage id='ticketSettingModal.submit' />
        </ButtonLoading>
      }
      cancelButton={
        unlimitedTickets && (
          <ButtonLoading
            onClick={() => {
              setShow(false)
            }}
            style='btn-secondary'
          >
            <FormattedMessage id='ticketSettingModal.unlimited.cancel' />
          </ButtonLoading>
        )
      }
    >
      <ModalIcon
        icon={<ExclamationTriangleIcon className='h-6 w-6  stroke-orange-600' aria-hidden='true' />}
      />
      {oneTicketPerPlace && (
        <ModalContent
          title={intl({
            id: 'ticketSettingModal.title',
          })}
          description={intl({
            id: 'ticketSettingModal.onePerPlace.description',
          })}
        />
      )}
      {unlimitedTickets && (
        <ModalContent
          title={intl({
            id: 'ticketSettingModal.title',
          })}
          description={intl({
            id: 'ticketSettingModal.unlimited.description',
          })}
        />
      )}
    </Modal>
  )
}
