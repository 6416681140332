import { PhoneNumberLookupRequest } from '@2meters/types'
import { httpsCallable } from 'firebase/functions'
import readResponseAs from 'backend/utils/readResponseAs'
import { functions } from '../Firebase/firebase-init'

export const validatePhoneNumber = (phoneNumber: string): Promise<PhoneNumberLookupRequest> =>
  httpsCallable(
    functions,
    'integrations-lookupPhoneNumber'
  )({ phoneNumber })
    .then(res => readResponseAs<PhoneNumberLookupRequest>(res.data))
    .catch(e => {
      console.error('Phone number lookup failed')
      return Promise.resolve({ valid: true })
    })
