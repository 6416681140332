import { LocaleLong } from '@2meters/types'

const abbreviation = {
  'en-GB': 'En',
  'de-DE': 'De',
  'lt-LT': 'Lt',
  'fr-FR': 'Fr',
  'pt-BR': 'Pt',
  'es-ES': 'Es',
  'uk-UA': 'Ua',
  'ru-RU': 'Ru',
  'tr-TR': 'Tr',
}

export const localeAbbreviation = (locale: LocaleLong) => abbreviation[locale]
