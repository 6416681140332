import { FormField, FormPage } from '@2meters/types'
import { useEffect } from 'react'
import { useWizardForm } from 'store/useWizardForm'
import { useTranslation } from 'utils/useTranslation'
import { WizardFormField, WizardFormForm, WizardFormPage } from '../types/index'
import { TitleStep } from '../ui'
import { useWizardSteps } from '../useWizardSteps'
import { DesktopInfoBlock } from './DesktopInfoBlock'
import { FormFieldInput } from './FormFieldInput'
import { keyBy } from 'lodash'

interface FormsProps {
  formPage: FormPage
}
export const FormsStep = ({ formPage }: FormsProps) => {
  const intl = useTranslation()
  const { wizardForm, updateWizardForm } = useWizardForm()
  const { form } = useWizardSteps()
  const wizardPage = wizardForm.form?.pages[formPage.id]

  useEffect(() => {
    console.log('FORM', form)

    // setting up info form values that are static
    const infoFields = keyBy(
      formPage.fields
        .filter(field => field.type == 'info')
        .map(field => {
          const wizardFormField: WizardFormField = {
            fieldId: field.id,
            type: field.type,
            label: field.label,
            value: field.defaultValue,
          }
          return wizardFormField
        }),
      'fieldId'
    )

    const updatedWizardPage = {
      pageId: formPage.id,
      fields: { ...wizardPage?.fields, ...infoFields },
      valid: !hasEmptyRequiredFields(formPage.fields, wizardPage?.fields),
    }

    onPageChange(updatedWizardPage)
  }, [])

  function hasEmptyRequiredFields(
    formFields: FormField[],
    wizardFields?: Record<string, WizardFormField>
  ) {
    const requiredFields = formFields.filter(field => field.required)

    const hasEmptyRequiredFields = requiredFields.some(field => {
      const wizardField = wizardFields?.[field.id]
      return !(
        wizardField?.value ||
        (wizardField?.multiValue && wizardField?.multiValue?.length > 0)
      )
    })

    return hasEmptyRequiredFields
  }

  function onFieldChange(updatedWizardField: WizardFormField) {
    const updatedWizardFields = {
      ...wizardPage?.fields,
      [updatedWizardField.fieldId]: updatedWizardField,
    }

    const isValid = !hasEmptyRequiredFields(formPage.fields, updatedWizardFields)

    const updatedWizardPage = {
      pageId: formPage.id,
      fields: updatedWizardFields,
      valid: isValid,
    }

    onPageChange(updatedWizardPage)
  }

  function onPageChange(updatedWizardPage: WizardFormPage) {
    const updatedPages = {
      ...wizardForm.form?.pages,
      [updatedWizardPage.pageId]: updatedWizardPage,
    }

    const newForm: WizardFormForm = {
      formId: form?.id || '',
      pages: updatedPages,
    }

    updateWizardForm({
      form: newForm,
    })
  }

  return (
    <div>
      <TitleStep
        title={intl({
          id: 'moreInfo',
        })}
      />
      <div className='md:grid md:grid-cols-2 md:gap-4'>
        <div>
          <h2 className='text-2xl font-bold mb-1 text-gray-700 dark:text-gray-100'>
            {formPage.title}
          </h2>
          {formPage.fields.map(formField => {
            return (
              <FormFieldInput
                onFieldChange={onFieldChange}
                formField={formField}
                wizardField={wizardPage?.fields[formField.id]}
                key={formField.id}
              />
            )
          })}
        </div>
        <DesktopInfoBlock />
      </div>
    </div>
  )
}
