import { Appointment, Place } from '@2meters/types'
import { WhatsappLogo } from 'assets/icons'
import { ButtonLoading } from 'components/Buttons'
import { Modal, ModalContent, ModalIcon } from 'components/Modal'
import { useCallback, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTicket } from 'store/useTicket'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'

interface WhatsappModalProps {
  place: Place
  app: Appointment
}

export const WhatsappModal = ({ app, place }: WhatsappModalProps) => {
  const intl = useTranslation()
  const [show, setShow] = useState(false)
  const { updateTicket } = useTicket()
  const { state } = useLocation()
  const isItKiosk = state && state.kioskMode

  const handleCloseWhatsappDialog = useCallback(() => {
    if (!app) {
      return
    }

    const update = {
      ...app,
      contactData: {
        whatsAppConsent: false,
      },
    }

    setShow(false)
    updateTicket(update).catch(e => {
      console.error(`failed update an appointment ${e.message}`)
    })
  }, [app])

  const handleConnectWhatsappDialog = useCallback(() => {
    if (!app) {
      return
    }

    const update = {
      ...app,
      contactData: {
        whatsAppConsent: true,
      },
    }
    setShow(false)
    updateTicket(update).catch(e => {
      console.error(`failed update an appointment ${e.message}`)
    })

    window?.open(
      `https://wa.me/12029795097?text=${intl({
        id: 'dialogs.whatsapp.urlText',
      })}`
    )
  }, [app])

  useEffect(() => {
    const showWhatsAppDialog = Boolean(
      place?.messagingConfig?.useWhatsAppMessenger &&
        app?.contactData?.whatsAppConsent === undefined
    )

    setShow(showWhatsAppDialog)
  }, [app, app?.contactData?.whatsAppConsent, place?.messagingConfig?.useWhatsAppMessenger])

  return (
    <>
      {!isItKiosk && (
        <Modal
          open={show}
          onClose={handleCloseWhatsappDialog}
          cancelButton={
            <ButtonLoading
              onClick={handleCloseWhatsappDialog}
              style='bg-gray-100 md:mr-2 border-gray-300 text-gray-900'
            >
              <FormattedMessage id='cancel' />
            </ButtonLoading>
          }
          submitButton={
            <ButtonLoading onClick={handleConnectWhatsappDialog} style='btn-primary border-none'>
              <FormattedMessage id='confirm' />
            </ButtonLoading>
          }
        >
          <ModalIcon
            icon={
              <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                <WhatsappLogo />
              </div>
            }
          />
          <ModalContent
            title={intl({
              id: 'whatsapp.dialog.title',
            })}
            description={intl({
              id: 'whatsapp.dialog.description',
            })}
          />
        </Modal>
      )}
    </>
  )
}
