import { memo } from 'react'
import IconWithBg from './IconWithBg'

const EmailIconWithBg = () => (
  <IconWithBg>
    <path
      className={`stroke-custom-main`}
      d='M31.3332 18C31.3332 17.0833 30.5832 16.3333 29.6665 16.3333H16.3332C15.4165 16.3333 14.6665 17.0833 14.6665 18M31.3332 18V28C31.3332 28.9166 30.5832 29.6666 29.6665 29.6666H16.3332C15.4165 29.6666 14.6665 28.9166 14.6665 28V18M31.3332 18L22.9998 23.8333L14.6665 18'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </IconWithBg>
)
export default memo(EmailIconWithBg)
