import { auth } from 'api/Firebase/firebase-init'
import ArrowDown from 'assets/scanner-arrow.svg'
import { ButtonLoading } from 'components/Buttons'
import { Container } from 'components/Container'
import { Input } from 'components/Form'
import { OAuthProviderButton } from 'components/OAuthProviderButton'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { useCallback, useState } from 'react'
import { useAlert } from 'store/useAlert'
import { useCurrentUser } from 'store/useCurrentUser'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'

export const SignInPage = () => {
  const intl = useTranslation()
  const { addAlert } = useAlert()
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const { claims } = useCurrentUser()

  const signOut = () => {
    getAuth().signOut()
  }

  const signIn = useCallback(
    async (event: any) => {
      event.preventDefault()

      if (!email || !password) {
        addAlert(`Please fill in all fields ${email} ${password}`, 'error')
        return
      }
      try {
        console.info('Attempt to log in')

        await signInWithEmailAndPassword(auth, email, password)

        addAlert(`You are logged in as ${email}`, 'info')
        // subscribeToAuthUser(user)
      } catch (err: any) {
        addAlert(err?.message, 'error')
      }
    },
    [email, password, addAlert]
  )

  return (
    <Container className='h-full w-full'>
      {claims?.orgMembership ? (
        <div className='flex flex-col items-center justify-center h-full w-full'>
          <h1 className='uppercase text-gray-700 text-2xl font-bold mb-2 text-center'>
            <FormattedMessage id='scannerPage.title' />
          </h1>
          <p className='uppercase text-gray-700 text-m mb-4 text-center'>
            <FormattedMessage id='scannerPage.description' />
          </p>
          <ButtonLoading onClick={signOut} style='w-full'>
            <FormattedMessage id='signOut' />
          </ButtonLoading>
          <ArrowDown
            style={{
              position: 'fixed',
              bottom: '80px',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
          />
        </div>
      ) : (
        <div className='flex item-center justify-center h-full w-full'>
          <div className='flex flex-col items-center justify-center w-full'>
            <h1 className='text-gray-700 text-2xl font-bold w-full text-left mb-4'>
              <FormattedMessage id='signIn' />
            </h1>
            <div className='w-full mb-4'>
              <Input
                defaultValue={email}
                onChange={val => setEmail(val)}
                label={intl({ id: 'email' })}
              />
              <Input
                defaultValue={password}
                type='password'
                onChange={val => setPassword(val)}
                label={intl({ id: 'password' })}
              />
            </div>
            <ButtonLoading onClick={signIn} style='w-full  md:w-32'>
              {intl({ id: 'email' })}
            </ButtonLoading>
            <p
              className={`
          before:content-[''] 
          before:absolute 
          before:top-[50%] 
          before:left-0 
          before:bg-[#E3E5EA] 
          before:w-5/12
          before:h-[1px] 
          after:content-[''] 
          after:absolute 
          after:top-[50%] 
          after:right-0 
          after:bg-[#E3E5EA] 
          after:w-5/12
          after:h-[1px] 
          relative
          w-full
          text-center
          mb-2
          `}
            >
              <FormattedMessage id='or' />
            </p>
            <OAuthProviderButton titleGoogle={'login.google'} provider='google' />
          </div>
        </div>
      )}
    </Container>
  )
}
