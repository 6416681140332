import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  color?: string
  viewBox?: string
}

const CheckIcon = ({
  className = 'w-6 h-6',
  fill = 'none',
  color = 'currentColor',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg fill={fill} viewBox={viewBox} strokeWidth={1.5} stroke={color} className={className}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M4.5 12.75l6 6 9-13.5' />
  </svg>
)
export default memo(CheckIcon)
