import { auth } from 'api/Firebase/firebase-init'
import { VISITOR } from 'app/routes'
import { ExclamationTriangleIcon, FullscreenIcon } from 'assets/icons'
import { ButtonLoading } from 'components/Buttons'
import { DesktopMenu, MobileMenu } from 'components/Menu'
import { Modal, ModalContent, ModalIcon } from 'components/Modal'
import { useCallback, useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'utils'
import FormattedMessage from 'utils/FormattedMessage'
import { CssCustomisation } from './CssCustomisation'
import { useWizardForm } from 'store/useWizardForm'
import { useWizardSteps } from 'components/WizardForm/useWizardSteps'
import { nextTick } from 'process'

const REALISE_TIME = 60000
const TIMEOUT_DURATION = REALISE_TIME / 2
const INITIAL_COUNTER = 30

export const Layout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const intl = useTranslation()
  const { placeId: urlPlaceId } = useParams()
  const [lastClick, setLastClick] = useState<number>()
  const parts = location.pathname.split('/')
  const pageName = parts[parts.length - 1]
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [secondLeft, setSecondLeft] = useState<number>(20)
  const { resetSteps } = useWizardSteps()
  const { resetWizardForm } = useWizardForm()

  const clickOnUI = useCallback(() => {
    if (timerId !== null) {
      clearTimeout(timerId)
      setTimerId(null)
    }

    if (intervalId !== null) {
      clearInterval(intervalId)
      setIntervalId(null)
    }

    setShowModal(false)
    if (pageName === 'kiosk') return
    if (location.state?.kioskMode !== 'on') return
    setLastClick(Date.now())

    if (location.state?.kioskMode === 'on' && pageName !== 'kiosk') {
      const newTimerId = setTimeout(() => {
        let counter = INITIAL_COUNTER
        const newIntervalId = setInterval(() => {
          counter--
          setShowModal(true)
          setSecondLeft(counter)
          if (counter === 0) {
            clearInterval(newIntervalId)
          }
        }, 1000)

        setIntervalId(newIntervalId)
      }, TIMEOUT_DURATION)
      setTimerId(newTimerId)
    }
  }, [pageName, location.state?.kioskMode, timerId, intervalId])

  const doSignOutAndRedirect = () => {
    if (timerId !== null) {
      clearTimeout(timerId)
      setTimerId(null)
    }
    if (intervalId !== null) {
      clearInterval(intervalId)
      setIntervalId(null)
    }

    setShowModal(false)

    auth.signOut()
    nextTick(() => {
      resetSteps()
      resetWizardForm(urlPlaceId)
      urlPlaceId && navigate(VISITOR.KIOSK.replace(':placeId', urlPlaceId))
    })
  }

  useEffect(() => {
    if (lastClick) {
      const timer = setTimeout(doSignOutAndRedirect, REALISE_TIME)

      return () => clearTimeout(timer)
    }
  }, [lastClick])

  useEffect(() => {
    clickOnUI()
  }, [location.state?.kioskMode, pageName])

  const isKioskPage = pageName === 'kiosk'
  const isKioskMode = location.state?.kioskMode === 'on' || pageName === 'kiosk'

  return (
    <>
      <CssCustomisation />
      <div className='md:flex md:flex-col h-full' onClick={clickOnUI}>
        <Outlet />
        {!isKioskMode && !isKioskPage && (
          <>
            <div className='hidden md:block'>
              <DesktopMenu />
            </div>
            <div className='block md:hidden'>
              <MobileMenu />
            </div>
          </>
        )}
        {isKioskMode && !isKioskPage && (
          <div className='fixed top-4 left-1/2 transform -translate-x-1/2 md:left-auto md:right-4 md:transform-none'>
            <ButtonLoading
              onClick={doSignOutAndRedirect}
              style='w-full !mb-0 hover:bg-transparent bg-transparent text-white !flex !items-center !text-gray-800 dark:text-gray-100 dark:border-1'
            >
              <FullscreenIcon className='w-6 h-6 stroke-gray-800 dark:stroke-gray-100 mr-2' />
              <span className='text-gray-800 dark:text-gray-100'>
                <FormattedMessage id='kiosk.backModal.submit' />
              </span>
            </ButtonLoading>
          </div>
        )}
      </div>
      {isKioskMode && (
        <Modal
          open={showModal}
          onClose={clickOnUI}
          submitButton={
            <ButtonLoading onClick={doSignOutAndRedirect}>
              <FormattedMessage id='kiosk.backModal.submit' />
            </ButtonLoading>
          }
          cancelButton={
            <ButtonLoading onClick={clickOnUI}>
              <FormattedMessage id='kiosk.backModal.cancel' />
            </ButtonLoading>
          }
        >
          <ModalIcon
            icon={
              <ExclamationTriangleIcon className='h-6 w-6  stroke-orange-600' aria-hidden='true' />
            }
          />
          <ModalContent
            title={intl({ id: 'kiosk.backModal.title' })}
            description={`${intl({ id: 'kiosk.backModal.title' })} ${secondLeft} ${intl({
              id: 'seconds',
            })}`}
          />
        </Modal>
      )}
    </>
  )
}
