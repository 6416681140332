import { BookAppointmentRequest, WalkInRequest } from '@2meters/types'
import { bookAppointment } from 'api/backend/bookAppointment'
import { walkInCallable } from 'api/backend/walkIn'
import { PRIVACY_POLICY, TERMS, VISITOR } from 'app/routes'
import { ButtonLoading } from 'components/Buttons'
import { isUndefined, omitBy } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAlert } from 'store/useAlert'
import { useVisitorLocale } from 'store/useVisitorLocale'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { formatTimeToLocale } from 'utils/formatTimeToLocale'
import { WizardFormForm, WizardOrder } from '../types'
import { useWizardSteps } from '../useWizardSteps'
import { SmallProductItem } from './SmallProductItem'
import { Alert } from 'components/Alert'

interface ConfirmAppointmentProps {
  show: boolean
  onClose: () => void
}

export const ConfirmAppointment = ({ show, onClose }: ConfirmAppointmentProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const isItKiosk = location.state && location.state.kioskMode
  const { wizardForm, updateWizardForm, resetWizardForm } = useWizardForm()
  const { resetSteps } = useWizardSteps()
  const [isLoading, setIsLoading] = useState(false)
  const { addAlert } = useAlert()
  const { visitorLocale } = useVisitorLocale()

  useEffect(() => {
    if (show)
      updateWizardForm({
        privacyPolicy: false,
        termsAndConditions: false,
      })
  }, [show])

  const createOrderObject = (wizardOrder: WizardOrder) => ({
    inventoryId: wizardOrder.inventoryId,
    products: Object.values(wizardOrder.products).map(product => ({
      productId: product.productId,
      quantity: product.quantity,
      name: product.name,
      price: product.price,
      currency: wizardOrder.currency,
    })),
  })

  const createFormDataObject = (form: WizardFormForm): BookAppointmentRequest['formData'] => ({
    formId: form.formId,
    data: Object.values(form.pages)
      .flatMap(page => Object.values(page.fields))
      .map(field => ({
        type: field.type,
        fieldId: field.fieldId,
        ...(field?.value ? { value: field.value.toString() } : {}),
        // Array.isArray(field?.value) && field?.type === 'checkbox'
        //   ? undefined
        //   : field?.value?.toString(),
        multiValue: field?.multiValue || [],
        label: field?.label,
      })),
  })

  const onSubmit = () => {
    if (!wizardForm.placeId || !wizardForm.serviceId) {
      console.error('Place id or service id is not set')
      return
    }

    const commonRequestData = {
      placeId: wizardForm.placeId!,
      serviceId: wizardForm.serviceId!,
      contactData: omitBy(wizardForm.contactData, isUndefined),
      createdFrom: isItKiosk ? ('kiosk' as const) : ('visitor' as const),
      ...(wizardForm.order && {
        order: createOrderObject(wizardForm.order),
      }),
      ...(wizardForm.form && {
        formData: createFormDataObject(wizardForm.form),
      }),
      preferredLanguage: visitorLocale,
    }

    let requestPromise: Promise<any>
    if (
      wizardForm.queueType === 'schedule' &&
      wizardForm.scheduleId &&
      commonRequestData.placeId &&
      wizardForm.selectedTime?.startTime &&
      wizardForm.selectedTime?.endTime
    ) {
      const bookRequest: BookAppointmentRequest = omitBy(
        {
          ...commonRequestData,
          scheduleId: wizardForm.scheduleId,
          startTime: wizardForm.selectedTime.startTime,
          endTime: wizardForm.selectedTime.endTime,
        },
        isUndefined
      ) as unknown as BookAppointmentRequest

      requestPromise = bookAppointment(bookRequest)
    } else {
      const walkInRequest: WalkInRequest = omitBy(
        {
          ...commonRequestData,
          queueId: wizardForm.queueId,
        },
        isUndefined
      ) as unknown as WalkInRequest

      requestPromise = walkInCallable(walkInRequest)
    }

    setIsLoading(true)
    return requestPromise
      .then(({ data }: any) => {
        setIsLoading(false)
        navigate(
          VISITOR.TICKET.replace(':placeId', data.placeId).replace(':id', data.appointmentId),
          location.state ? { state: { kioskMode: 'on' } } : {}
        )
        resetWizardForm(wizardForm.placeId)
        resetSteps()
      })
      .catch(e => {
        setIsLoading(false)
        if (e.message) {
          addAlert(e.message, 'error')
        } else {
          addAlert('An unknown error has occurred. Try again', 'error')
        }
        console.error('Error', e)
      })
  }

  if (!show) {
    return null
  }

  return (
    <div className='relative' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-10'></div>
      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center sm:p-0'>
          <div className='max-h-[calc(100vh-144px)] md:max-h-full relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
            <div>
              <div className='mt-3 text-center sm:mt-5'>
                <h3 className='text-base font-semibold leading-6 text-gray-900' id='modal-title'>
                  <FormattedMessage id='wizardForm.confirm.confirmVisit' />
                </h3>
              </div>
              <div>
                <div className='mt-3 text-left sm:mt-5'>
                  {wizardForm.selectedTime?.label && (
                    <div className='mt-2'>
                      <p className='text-sm  text-gray-500'>
                        <FormattedMessage id='wizardForm.confirm.yourAppointment' />,{' '}
                        {wizardForm.selectedTime.fullDayName},{' '}
                        {formatTimeToLocale(wizardForm.selectedTime.label)} for{' '}
                        {wizardForm.serviceName}
                      </p>
                    </div>
                  )}

                  {Object.values(wizardForm.contactData)
                    .filter(data => typeof data === 'string')
                    .some(Boolean) && (
                    <div className='mt-5 mb-2'>
                      <p className='text-sm font-bold text-gray-800'>
                        <FormattedMessage id='wizardForm.confirm.personalInfo' />
                      </p>
                    </div>
                  )}
                  {(wizardForm.contactData.firstName || wizardForm.contactData.lastName) && (
                    <div className='my-1'>
                      <p className='text-sm font-bold text-gray-900'>
                        {wizardForm.contactData.firstName} {wizardForm.contactData.lastName}
                      </p>
                    </div>
                  )}

                  {wizardForm.contactData.email && (
                    <p className='text-sm text-gray-700'>{wizardForm.contactData.email}</p>
                  )}

                  {wizardForm.contactData.phone && (
                    <p className='text-sm text-gray-700'>{wizardForm.contactData.phone}</p>
                  )}

                  {wizardForm.order && (
                    <div>
                      <div className='mt-5 mb-2'>
                        <p className='text-sm font-bold text-gray-800'>
                          <FormattedMessage id='wizardForm.confirm.yourOrder' />
                        </p>
                      </div>

                      {Object.values(wizardForm.order?.products).map(product => (
                        <SmallProductItem
                          key={product.productId}
                          product={product}
                          currency={wizardForm.order!.currency}
                        />
                      ))}
                    </div>
                  )}

                  <div className='mt-5 mb-2'>
                    <p className='text-sm  text-gray-700'>
                      <FormattedMessage id='wizardForm.confirm.fearNot' />
                    </p>
                  </div>

                  <fieldset className='mt-5'>
                    <div className='space-y-2'>
                      <div className='relative flex items-start'>
                        <div className='flex h-6 items-center'>
                          <input
                            id='termsAndConditions'
                            aria-describedby='termsAndConditions-description'
                            name='termsAndConditions'
                            onChange={e => {
                              updateWizardForm({
                                ...wizardForm,
                                termsAndConditions: e.target.checked,
                                privacyPolicy: e.target.checked,
                              })
                            }}
                            type='checkbox'
                            className='h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600'
                          />
                        </div>
                        <div className='ml-3 text-sm leading-6'>
                          <label htmlFor='termsAndConditions' className=' text-gray-500'>
                            <FormattedMessage id='wizardForm.confirm.terms.text' />
                            {` `}
                            <a href={TERMS} target='_blanc' className='text-indigo-500'>
                              <FormattedMessage id='wizardForm.confirm.terms' />
                            </a>{' '}
                            <FormattedMessage id='and' /> {` `}
                            <a href={PRIVACY_POLICY} target='_blanc' className='text-indigo-500'>
                              <FormattedMessage id='wizardForm.confirm.policy' />
                            </a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div className='mt-5 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3'>
              <ButtonLoading
                disabled={isLoading}
                onClick={onClose}
                style='w-full bg-gray-400 text-black-100 border-none'
              >
                <FormattedMessage id='cancel' />
              </ButtonLoading>
              <ButtonLoading
                loading={isLoading}
                disabled={!wizardForm.privacyPolicy || !wizardForm.termsAndConditions || isLoading}
                onClick={onSubmit}
                style='btn-primary w-full border-none'
              >
                <FormattedMessage id='confirm' />
              </ButtonLoading>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
