import { VISITOR } from 'app/routes'
import { Container } from 'components/Container'
import { LanguageSwitcher } from 'components/LanguageSwitcher'
import QRCode from 'react-qr-code'
import { Link, useParams } from 'react-router-dom'
import FormattedMessage from 'utils/FormattedMessage'

export const KioskPage = () => {
  const { placeId: urlPlaceId } = useParams()

  return (
    <div className='relative kiosk-bg-gray h-screen flex justify-center flex-col'>
      {/* <div className='absolute top-0 w-full h-[13px] bg-swatch-100'></div> */}
      {/* <div className='absolute bottom-0 w-full h-[13px] bg-swatch-100'></div> */}
      <Container>
        <div className='absolute right-7 top-7 dark'>
          <LanguageSwitcher />
        </div>
        <div className='flex flex-col justify-center h-full'>
          <div className='w-full flex justify-center'>
            <div className='flex items-center flex-col justify-center max-w-[90%] md:max-w-[70%]'>
              <h1 className='text-4xl font-semibold text-white mb-5 text-center'>
                <FormattedMessage id='kiosk.title' />
              </h1>
              <p className='text-xl font-normal text-white text-center mb-5'>
                <FormattedMessage id='kiosk.description' />
              </p>
            </div>
          </div>

          <div className='flex justify-around mt-5'>
            {/* <h1 className='text-white'>testing mode...</h1> */}
            <div className='w-[200px] md:w-[300px] lg:w-[380px] rounded-[10px] overflow-hidden'>
              <QRCode
                id='qr-code'
                size={512}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={`https://book.${process.env.NEXT_PUBLIC_DOMAIN}/places/${urlPlaceId}/book`}
                viewBox={`0 0 512 512`}
              />
              <p className='text-4xl font-semibold text-white mb-5 text-center mt-5'>
                <FormattedMessage id='kiosk.scan' />
              </p>
            </div>
            <Link
              to={{
                pathname: urlPlaceId && VISITOR.BOOK.replace(':placeId', urlPlaceId),
              }}
              state={{ kioskMode: 'on' }}
            >
              <button
                type='button'
                className='rounded-[10px] bg-swatch-100 w-[210px] h-[210px] md:w-[310px] md:h-[310px] lg:w-[380px] lg:h-[380px] px-3.5 py-2.5 text-5xl font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                <FormattedMessage id='book' />
              </button>
            </Link>
          </div>
        </div>
      </Container>
    </div>
  )
}
