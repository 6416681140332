import { SubscriptionType } from '@2meters/types'
import { UserClaims, useCurrentUser } from './useCurrentUser'
import { usePlace } from './usePlace'
import useSubscription from './useSubscription'

export interface RestrictionsStore {
  canUseScanner: boolean
  isCounter: boolean
  isAdmin: boolean
  isSuperAdmin: boolean
}

const isAdmin = (orgId?: string, claims?: UserClaims): boolean => {
  if (!orgId) return false
  const role = claims?.orgMembership?.[orgId]
  if (!role) return false
  return role === 'admin'
}

const isCounter = (orgId?: string, claims?: UserClaims): boolean => {
  if (!orgId) return false
  const role = claims?.orgMembership?.[orgId]
  if (!role) return false
  return role === 'counter' || role === 'admin'
}

const isSuperAdmin = (claims?: UserClaims) => {
  return Boolean(claims?.superadmin)
}

const canUseScanner = (
  organisationId?: string,
  subscriptionType?: SubscriptionType,
  claims?: UserClaims
) => {
  return (
    Boolean(organisationId) &&
    Boolean(subscriptionType) &&
    (isAdmin(organisationId, claims) || isCounter(organisationId, claims)) &&
    ['pro_monthly', 'pro_annual', 'enterprise_monthly', 'enterprise_annual'].includes(
      subscriptionType!
    )
  )
}

export const useRestrictions = (): RestrictionsStore => {
  const { place } = usePlace()
  const { claims } = useCurrentUser()
  const { subscription } = useSubscription()

  return {
    canUseScanner: canUseScanner(place?.organisationId, subscription?.type, claims),
    isSuperAdmin: isSuperAdmin(claims),
    isAdmin: isAdmin(place?.organisationId, claims),
    isCounter: isCounter(place?.organisationId, claims),
  }
}
