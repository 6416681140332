import { Appointment, AppointmentStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'
import { updateTicket } from './repository/ticket'

interface AppointmentStore {
  placeId: string | undefined
  id: string | undefined
  ticket: Appointment
  error: 'not_found' | 'unknown' | undefined
  updateTicket: (appointment: Appointment) => Promise<void>
  subscribeToTicket: (placeId: string, id: string) => void
  unsubscribeFromTicket: () => void
}

export const useTicket = create<AppointmentStore>((set, get) => ({
  placeId: undefined,
  id: undefined,
  ticket: undefined as any,
  error: undefined,

  subscribeToTicket: (placeId, id) => {
    set({ placeId, id })
    if (!placeId && !id) {
      set({ ticket: undefined })
      return
    }

    const ref = doc(db, `places/${placeId}/tickets/${id}`)
    console.log('Subscribing to ticket', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async doc => {
        const data = doc.data()
        if (!doc.exists() || !data) {
          console.error('Ticket not found', { placeId, id })
          set({ ticket: undefined, error: 'not_found' })
          return
        }
        const ticket = AppointmentStruct.create(doc.data())
        console.log('Ticket updated', ticket)
        set({ ticket, error: undefined })
      },
      (error: any) => {
        console.error('Subscribing to ticket failed', error)
        set({ ticket: undefined, error: 'unknown' })
      }
    )

    const unsubscribe = () => {
      console.log(`Unsubscribing from ticket ${id}`)
      set({ ticket: undefined, placeId: undefined, id: undefined, error: undefined })
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromTicket: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromTicket: () => {
    console.error('Unsubscribing from ticket before subscribing')
  },

  updateTicket: async (appointment: Appointment) => {
    const placeId = get().placeId
    if (!placeId) return Promise.reject('Not subscribed to ticket')
    const [error, validAppointment] = AppointmentStruct.validate(appointment)
    if (error) return Promise.reject(error)

    updateTicket({ placeId: placeId, ticket: validAppointment })
  },
}))
