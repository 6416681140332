import { memo } from 'react'

type IconProps = {
  className?: string
  viewBox?: string
  stroke?: string
}

const HomeIcon = ({
  className = 'w-6 h-6 stroke-gray-800',
  stroke = 'none',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg viewBox={viewBox} stroke={stroke} fill='none' className={className}>
    <path
      d='M21.5 9.95611L13.9741 3.9986C13.1101 3.31557 11.8899 3.31557 11.0259 3.9986L3.5 9.95611'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.99609 8.77539V17.4504C4.99609 19.1424 6.36799 20.5143 8.05999 20.5143H16.9393C18.6313 20.5143 20.0032 19.1424 20.0032 17.4504V8.77539'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.2313 12.5605L9.86914 15.3384M15.1289 12.5605L13.7667 15.3384'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(HomeIcon)
