import { Place, PlaceStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

export interface PlaceStore {
  placeId?: string
  place?: Place
  loaded: boolean
  error: 'not_found' | 'unknown' | undefined
  setPlace: (place: Place) => void
  subscribeToPlace: (id: string) => void
  unsubscribeFromPlace: () => void
}

export const usePlace = create<PlaceStore>((set, get) => ({
  place: undefined,
  placeId: undefined,
  loaded: false,
  error: undefined,
  setPlace: place => set({ place, placeId: place.id }),
  subscribeToPlace: placeId => {
    const ref = doc(db, `places/${placeId}`)
    console.log('Subscribing to place', placeId)
    const unsubscribeFromFirestore = onSnapshot(
      ref,
      doc => {
        if (!doc.exists()) {
          console.error('Place deost not exits')
          set({ placeId, loaded: true, error: 'not_found' })
          return
        }
        console.log('Place updated', doc.data())
        const place = PlaceStruct.create(doc.data())
        set({ place, placeId, loaded: true, error: undefined })
      },
      (error: unknown) => {
        console.error('Subscribing to place failed', error)
        set({ placeId, loaded: true, error: 'unknown' })
      }
    )

    set({
      unsubscribeFromPlace: () => {
        console.log('Unsubscribing from place')
        unsubscribeFromFirestore()
      },
    })

    return get().unsubscribeFromPlace
  },
  unsubscribeFromPlace: () => {
    console.error('Unsubscribing from place before subscribing =(')
  },
}))
