import { PlaceStruct } from '@2meters/types'
import { doc, onSnapshot } from '@firebase/firestore'
import { db } from 'api/Firebase/firebase-init'
import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { LOGO } from 'app/routes'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'

interface ShortTicketLogoProps {
  placeId: string
}

export const ShortTicketLogo = ({ placeId }: ShortTicketLogoProps) => {
  const [loadingLogo, setLoadingLogo] = useState(false)
  const [logoUrl, setLogoUrl] = useState('')
  const [placeLogo, setPlaceLogo] = useState('')

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(placeLogo)
      .then(link => {
        setLogoUrl(link)
        setLoadingLogo(false)
      })
      .catch(() => {
        setLogoUrl(LOGO)
        setLoadingLogo(false)
      })
  }, [placeLogo])

  useEffect(() => {
    const fetchCount = () => {
      const ref = doc(db, `places/${placeId}`)
      onSnapshot(ref, doc => {
        if (doc.data() !== undefined) {
          const place = PlaceStruct.create(doc.data())
          setPlaceLogo(place.logo || '')
        }
      })
    }
    fetchCount()
  }, [placeId])

  return (
    <div className='flex pr-2'>
      {(loadingLogo && (
        <div className='flex items-center justify-center min-w-[40px] h-10'>
          <RoundSpinner width='w-8' height='h-8' />
        </div>
      )) || (
        <div className='mt-1 flex-shrink-0'>
          <img className='inline-block h-[40px] w-[40px] rounded-full' src={logoUrl} alt='logo' />
        </div>
      )}
    </div>
  )
}
