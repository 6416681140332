import { memo } from 'react'
import IconWithBg from './IconWithBg'

const PhoneIconWithBg = () => (
  <IconWithBg>
    <path
      className='stroke-custom-main'
      d='M31.3332 27.1V29.6C31.3341 29.8321 31.2866 30.0618 31.1936 30.2744C31.1006 30.4871 30.9643 30.678 30.7933 30.8349C30.6222 30.9918 30.4203 31.1112 30.2005 31.1856C29.9806 31.2599 29.7477 31.2875 29.5165 31.2666C26.9522 30.988 24.489 30.1118 22.3249 28.7083C20.3114 27.4289 18.6043 25.7218 17.3249 23.7083C15.9165 21.5343 15.0401 19.0591 14.7665 16.4833C14.7457 16.2529 14.7731 16.0206 14.8469 15.8013C14.9208 15.5821 15.0395 15.3806 15.1955 15.2097C15.3515 15.0388 15.5414 14.9022 15.753 14.8087C15.9647 14.7153 16.1935 14.6669 16.4249 14.6666H18.9249C19.3293 14.6627 19.7214 14.8059 20.028 15.0696C20.3346 15.3333 20.5349 15.6995 20.5915 16.1C20.697 16.9 20.8927 17.6856 21.1749 18.4416C21.287 18.7399 21.3112 19.0641 21.2448 19.3757C21.1783 19.6874 21.0239 19.9734 20.7999 20.2L19.7415 21.2583C20.9278 23.3446 22.6552 25.072 24.7415 26.2583L25.7999 25.2C26.0264 24.9759 26.3125 24.8215 26.6241 24.7551C26.9358 24.6886 27.2599 24.7129 27.5582 24.825C28.3143 25.1071 29.0998 25.3028 29.8999 25.4083C30.3047 25.4654 30.6744 25.6693 30.9386 25.9812C31.2029 26.2931 31.3433 26.6913 31.3332 27.1Z'
      stroke='#7669FF'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </IconWithBg>
)
export default memo(PhoneIconWithBg)
