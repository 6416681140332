import { ButtonLoading } from 'components/Buttons'
import { Modal, ModalContent } from 'components/Modal'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { usePlace } from 'store/usePlace'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'

export const TimeZonePopup = () => {
  const intl = useTranslation()
  const { place } = usePlace()
  const [show, setShow] = useState(false)
  const [timeDifference, setTimeDifference] = useState<number>(0)
  const myTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  useEffect(() => {
    if (place && place.timezone && place.timezone !== myTimeZone) {
      const myTime = DateTime.now().setZone(myTimeZone)
      const placeTime = DateTime.now().setZone(place.timezone)
      setShow(myTime.hour - placeTime.hour === 0 ? false : true)
      setTimeDifference(myTime.hour - placeTime.hour)
    }
  }, [place?.timezone])

  return (
    <Modal
      open={show}
      onClose={() => setShow(false)}
      submitButton={
        <ButtonLoading onClick={() => setShow(false)} style='w-full'>
          <FormattedMessage id='confirm' />
        </ButtonLoading>
      }
    >
      <ModalContent
        title={intl({
          id: 'timezone.dialog.title',
        })}
        description={intl(
          {
            id: 'timezone.dialog.description',
          },
          {
            placeTimeZone: place?.timezone,
            myTimeZone: myTimeZone,
            diff: timeDifference < 0 ? timeDifference : `+${timeDifference}`,
          }
        )}
      />
    </Modal>
  )
}
