import { memo } from 'react'

type IconProps = {
  className?: string
  viewBox?: string
}

const EarthIcon = ({ className = 'w-5 h-5', viewBox = '0 0 20 20' }: IconProps) => (
  <svg width='20' height='20' className={className} viewBox={viewBox} fill='none'>
    <g clipPath='url(#clip0_478_20887)'>
      <path
        d='M10 19.375C15.1777 19.375 19.375 15.1777 19.375 10C19.375 4.82233 15.1777 0.625 10 0.625C4.82233 0.625 0.625 4.82233 0.625 10C0.625 15.1777 4.82233 19.375 10 19.375Z'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.23341 19.2084C6.92508 17.375 6.04175 13.9334 6.04175 10.0417C6.04175 6.15004 6.92508 2.62504 8.23341 0.833374'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M11.7666 19.2084C13.0749 17.375 13.9583 13.9334 13.9583 10.0417C13.9583 6.15004 13.0749 2.62504 11.7666 0.833374'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M0.625 10H19.375' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.70825 14.375H18.2916' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M1.70825 5.625H18.2916' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_478_20887'>
        <rect width='20' height='20' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
export default memo(EarthIcon)
