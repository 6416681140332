import { Options as IntlMessageFormatOptions, PrimitiveType } from 'intl-messageformat'
import { MessageDescriptor, useIntl } from 'react-intl'
import { usePlace } from 'store/usePlace'

export const useTranslation = () => {
  const { place } = usePlace()
  const intl = useIntl()

  return (
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType>,
    opts?: IntlMessageFormatOptions
  ) => {
    if (place && descriptor.id && place.translations && place.translations[descriptor.id]) {
      return place.translations ? place.translations[descriptor.id || ''] : ''
    }
    return intl.formatMessage(descriptor, values, opts)
  }
}
