import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, EarthIcon } from 'assets/icons'
import { Fragment } from 'react'
import { useVisitorLocale } from 'store/useVisitorLocale'
import FormattedMessage from 'utils/FormattedMessage'
import { localeAbbreviation } from 'utils/localeAbbreviation'

export const LanguageSwitcher = () => {
  const { visitorLocale, visitorLocalesNames, setVisitorLocale } = useVisitorLocale()
  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  return (
    <div className='flex items-center'>
      <Listbox value={visitorLocale} onChange={setVisitorLocale}>
        {({ open }) => (
          <>
            <div className='relative'>
              <Listbox.Button
                className={`flex text-white relative w-full cursor-default rounded-md py-1.5 pl-3 pr-10 text-left text-900 focus:outline-none focus:ring-0 sm:text-sm sm:leading-6`}
              >
                <div className='mr-2'>
                  <EarthIcon className={`stroke-gray-900 dark:stroke-gray-100`} />
                </div>
                <span className='block truncate text-gray-900 dark:text-gray-100'>
                  {localeAbbreviation(visitorLocale)}
                </span>
                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                  <ChevronDownIcon
                    className={`stroke-gray-900 dark:stroke-gray-100`}
                    aria-hidden='true'
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-[130px] left-[-26px] overflow-auto rounded-md bg-custom-bg py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {visitorLocalesNames.map(lang => (
                    <Listbox.Option
                      key={lang}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-custom-main' : '',
                          'relative cursor-default select-none py-2 pl-3 pr-9 text-gray-700 dark:text-gray-100'
                        )
                      }
                      value={lang}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            <FormattedMessage id={`lang.${lang}`} />
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-gray-700 dark:text-gray-100' : 'text-white',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon className='h-5 w-5' aria-hidden='true' />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
