interface InputProps<T> {
  defaultValue?: T
  type?: 'text' | 'password' | 'email' | 'number'
  name?: string
  label?: string
  required?: boolean
  placeholder?: string
  error?: string
  icon?: React.ReactNode
  onChange: (value: T) => void
}

export const Input = <T extends string | number>({
  defaultValue: value,
  type = 'text',
  name,
  label,
  required,
  placeholder,
  icon,
  error,
  onChange,
}: InputProps<T>) => {
  return (
    <div className='mb-4'>
      {label && (
        <label
          htmlFor={name}
          className='block text-sm font-medium leading-6 text-gray-700 dark:text-gray-100'
        >
          {label}
        </label>
      )}

      <div className='relative mt-2 rounded-md shadow-sm'>
        {icon && (
          <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
            {icon}
          </div>
        )}

        <input
          type={type}
          name={name}
          id={name}
          defaultValue={value}
          onChange={e => onChange(e.target.value as T)}
          className={`block w-full focus:outline-0 rounded-md  py-2 ${
            icon ? 'pl-10' : 'pl-4'
          } pr-7 text-gray-900 ring-1 ring-inset ${
            error && required
              ? 'border border-red-500 focus:ring-red-500'
              : 'border-0 focus:ring-indigo-600 focus:ring-2'
          } ring-gray-300 placeholder:text-gray-300  focus:ring-inset  sm:text-sm sm:leading-6`}
          placeholder={placeholder}
        />
      </div>
      {required && <p className='mt-2 text-sm text-red-600 dark:text-red-500'>{error}</p>}
    </div>
  )
}
