import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
}

const ChevronRigthIcon = ({
  className = 'w-6 h-6 stroke-current',
  fill = 'none',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg fill={fill} viewBox={viewBox} strokeWidth='1.5' className={className}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' />
  </svg>
)
export default memo(ChevronRigthIcon)
