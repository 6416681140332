import { RejectAppointmentRequest, Urls } from '@2meters/types'
import { functions } from 'api/Firebase/firebase-init'
import { httpsCallable } from 'firebase/functions'

export function rejectAppointment(request: RejectAppointmentRequest) {
  const rejectAppointmentCloudFunc = httpsCallable(
    functions,
    `${Urls.counter.prefix}${Urls.counter.rejectAppointment}`
  )

  return rejectAppointmentCloudFunc(request)
}
