import { LocaleLong } from '@2meters/types'
import { HumanizeDuration, HumanizeDurationLanguage } from 'humanize-duration-ts'

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone

type timePeriodInTextProps = {
  durationSec: number | undefined
  locale: LocaleLong
  largest?: number
  delimiter?: string
  spacer?: string
  d?: string
  h?: string
  m?: string
}
export const timePeriodInText = ({
  durationSec,
  locale,
  largest = 1,
  delimiter = '',
  spacer = ' ',
  d,
  h,
  m,
}: timePeriodInTextProps) => {
  if (!durationSec) return '-'

  const normalisedDuration = Math.max(durationSec, 60)
  const langService: HumanizeDurationLanguage = new HumanizeDurationLanguage()
  const humanizer: HumanizeDuration = new HumanizeDuration(langService)
  const language = locale.slice(0, 2)

  return humanizer.humanize(normalisedDuration * 1000, {
    language,
    round: true,
    largest: largest,
    delimiter: delimiter,
    spacer: spacer,
    languages: {
      en: {
        y: () => 'y',
        mo: () => 'mo',
        w: () => 'w',
        d: () => (d ? d : 'd'),
        h: () => (h ? h : 'h'),
        m: () => (m ? m : 'min'),
        s: () => 's',
        ms: () => 'ms',
        decimal: '.',
      },
      de: {
        y: () => 'J',
        mo: () => 'Mo',
        w: () => 'Wo',
        d: () => 'Tag',
        h: () => 'St',
        m: () => 'Min',
        s: () => 'S',
        ms: () => 'Ms',
        decimal: '.',
      },
      uk: {
        y: () => 'р',
        mo: () => 'м',
        w: () => 'т',
        d: () => 'д',
        h: () => 'г',
        m: () => 'хв',
        s: () => 'с',
        ms: () => 'мс',
        decimal: '.',
      },
    },
  })
}
