import { FormFieldOption } from '@2meters/types'
import { ChangeEvent } from 'react'
import { CheckboxOption } from './CheckboxOption'

interface CheckboxGroupProps {
  options: FormFieldOption[]
  label?: string | undefined
  required?: boolean
  values: string[] | undefined
  onCheckboxChange: (values: string[]) => void
}

export const CheckboxGroup = ({
  options,
  label,
  required,
  values: selectedOptions,
  onCheckboxChange,
}: CheckboxGroupProps) => {
  function onCheckboxToggle(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      onCheckboxChange([...(selectedOptions || []), e.target.value])
    } else {
      onCheckboxChange((selectedOptions || []).filter(v => v !== e.target.value))
    }
  }

  return (
    <fieldset>
      {label && (
        <legend className='text-base font-semibold text-gray-700 dark:text-gray-100 mb-2'>
          {label} {required && '*'}
        </legend>
      )}

      <div className='space-y-2 mb-5'>
        {options?.map(option => (
          <CheckboxOption
            key={option.id}
            id={option.id}
            value={option.value}
            isChecked={selectedOptions?.includes(option.value)}
            onChange={onCheckboxToggle}
          />
        ))}
      </div>
    </fieldset>
  )
}
