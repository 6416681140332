import { MinusIcon, PlusIcon } from 'assets/icons'
import { useState } from 'react'

type Props = {
  defaultValue: number
  className?: string
  onChange: (value: number) => void
}

export const NummericStepper = ({ defaultValue, className, onChange }: Props) => {
  const [quantity, setQuantity] = useState(defaultValue)

  const decrease = () => {
    if (quantity > 0) {
      setQuantity(quantity - 1)
      onChange(quantity - 1)
    }
  }

  const increase = () => {
    setQuantity(quantity + 1)
    onChange(quantity + 1)
  }

  return (
    <div className={`flex items-center ${className}`}>
      <div className='isolate inline-flex rounded-md shadow-sm h-[34px]'>
        <button
          type='button'
          onClick={decrease}
          className='relative w-[35px] -mr-px inline-flex items-center rounded-l-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
        >
          <MinusIcon className='stroke-slate-800 h-5 w-5' aria-hidden='true' />
        </button>
        <div className='relative w-[35px] flex items-center justify-center bg-gray-800 px-2 py-2 text-white  ring-gray-300'>
          {quantity}
        </div>
        <button
          type='button'
          onClick={increase}
          className='relative w-[35px] -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10'
        >
          <span className='sr-only'>Next</span>
          <PlusIcon aria-hidden='true' className='stroke-slate-800 h-5 w-5' />
        </button>
      </div>
    </div>
  )
}
