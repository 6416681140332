import { isMessaggingSupported } from 'api/Firebase/firebase-init'
import { useEffect, useState } from 'react'
import { getFCMToken } from 'store/fcm/getFcmToken'
import { useAlert } from 'store/useAlert'
import { useMessagingToken } from 'store/useMessagingToken'
import { useWizardForm } from 'store/useWizardForm'
import { PushNotificationModal } from './PushNotificationModal'

interface PushNotificationProps {
  show: boolean
  onClose: () => void
}

export const PushNotification = ({ show, onClose }: PushNotificationProps) => {
  const { addAlert } = useAlert()
  const { wizardForm, updateWizardForm } = useWizardForm()
  const { setMessagingToken } = useMessagingToken()
  const [status, setStatus] = useState<'pending' | 'granted' | 'denied' | 'loading'>('loading')
  const [pushToken, setPushToken] = useState<string | undefined>('')

  const getToken = async () => {
    setStatus('loading')
    let token: string | undefined = undefined
    try {
      token = await getFCMToken()
      setMessagingToken(token)
      setStatus('granted')
      setPushToken(token)
    } catch (e) {
      if (e instanceof Error) {
        addAlert(e.message, 'error')
      } else {
        addAlert('Unknown error', 'error')
      }
      setPushToken('')
      setStatus('denied')
      return null
    }

    return token
  }

  const getNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission()
      if (permission === 'granted') {
        await getToken()
      } else {
        setStatus('denied')
        setTimeout(() => {
          setPushToken('')
        }, 2000)
      }
    } catch (e) {
      if (e instanceof Error) {
        addAlert(e.message, 'error')
      } else {
        addAlert('Unknown error', 'error')
      }
      console.error(e)
    }
  }

  useEffect(() => {
    if (!isMessaggingSupported || !('Notification' in window)) {
      setPushToken('')
      setStatus('denied')
      return () => undefined
    }
    if (Notification.permission === 'granted') {
      void getToken()
      return () => undefined
    }
    if (Notification.permission === 'denied') {
      setTimeout(() => {
        setPushToken('')
      }, 1500)
      setStatus('denied')
      return () => undefined
    }

    setStatus('pending')
  }, [])

  useEffect(() => {
    if (show) {
      setTimeout(getNotificationPermission, 2000)
    }
  }, [show])

  useEffect(() => {
    updateWizardForm({
      notificationStatus: status,
    })
  }, [status])

  useEffect(() => {
    updateWizardForm({
      contactData: {
        ...wizardForm.contactData,
        pushToken,
      },
    })
  }, [pushToken])

  return <PushNotificationModal show={show} status={status} onClose={onClose} />
}
