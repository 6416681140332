import { Slot } from '@2meters/types'
import { memo } from 'react'
import { useWizardForm } from 'store/useWizardForm'
import { formatTimeToLocale } from 'utils/formatTimeToLocale'

interface TimeSlotOptionProps {
  slot: Slot
  index: number
  disabled?: boolean // TODO: If the number of visitors does not fit into the slot, disabled it
  fullDayName: string
  disclosureId: number
}

// eslint-disable-next-line react/display-name
export const TimeSlotOption = memo(
  ({ slot, index, disabled, fullDayName, disclosureId }: TimeSlotOptionProps) => {
    const { wizardForm, updateWizardForm } = useWizardForm()
    const isSelected =
      !disabled &&
      index === wizardForm.selectedTime?.index &&
      fullDayName === wizardForm.selectedTime?.fullDayName
    const startTime = slot.label.split('-')[0]

    const onSelect = () => {
      updateWizardForm({
        selectedTime: {
          ...wizardForm.selectedTime,
          label: slot.label,
          startTime: slot.startTime.toISOString(),
          endTime: slot.endTime.toISOString(),
          index,
          fullDayName,
          disclosureId,
          occupancy: slot.occupancy,
          shopCapacity: slot.shopCapacity,
        },
      })
    }

    return (
      <div className='flex justify-center w-3/12 mb-2'>
        <div
          className={`relative text-gray-700 dark:text-gray-100 flex flex-col items-center cursor-pointer mr-1 mt-2 rounded-[16px] px-3 py-2 focus:outline-none ${
            isSelected ? 'bg-custom-main' : 'bg-black/20'
          } ${disabled && 'opacity-25 outline-none'}`}
          onClick={onSelect}
        >
          <p className='text-xs'>{formatTimeToLocale(startTime)}</p>
        </div>
      </div>
    )
  }
)
