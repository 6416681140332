import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
}

const MinusIcon = ({ className = 'w-6 h-6', fill = 'none', viewBox = '0 0 24 24' }: IconProps) => (
  <svg fill={fill} viewBox={viewBox} strokeWidth={1.5} className={className}>
    <path strokeLinecap='round' strokeLinejoin='round' d='M18 12H6' />
  </svg>
)
export default memo(MinusIcon)
