import { Place } from '@2meters/types'
import MapIconWithBg from 'assets/icons/MapIconWithBg'
import FormattedMessage from 'utils/FormattedMessage'

type Props = {
  place?: Place
}

export const PlaceAddress = ({ place }: Props) => {
  if (!place) return null
  return (
    <>
      {place.address && (
        <div className='address mb-6'>
          <div className='mb-2 flex items-center'>
            <MapIconWithBg />
          </div>
          <h3 className='text-gray-900 dark:text-gray-100 mb-2 font-bold'>
            <FormattedMessage id='address' />
          </h3>
          <a
            className='text-custom-main font-semibold'
            href={`https://maps.google.com/?q=${place.address}`}
          >
            <FormattedMessage id='findOnMap' />
          </a>
          <p className='text-gray-700 dark:text-gray-100 font-regular'>{place.address}</p>
        </div>
      )}
    </>
  )
}
