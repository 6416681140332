import { memo } from 'react'

const WhatsappLogo = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M10.5992 26.7157C10.3073 26.5691 9.98688 26.5185 9.66905 26.5937C8.58843 26.848 6.84491 27.2827 5.57617 27.6017C4.85489 27.7833 4.19848 27.1412 4.36583 26.4173C4.68107 25.05 5.11824 23.1483 5.3258 22.1973C5.39067 21.9016 5.35045 21.5967 5.22203 21.3229C4.46183 19.6949 4 17.8931 4 16.0003C4 8.65128 10.6952 2.79023 18.2868 4.21463C23.0568 5.10973 26.8903 8.94445 27.7853 13.7132C29.2097 21.3048 23.3487 28 15.9997 28C14.12 28 12.2415 27.546 10.5992 26.7157Z'
      stroke='#039855'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M11.4678 11.2811C9.61922 13.1388 11.0203 16.09 13.4851 18.5548C15.9499 21.0197 18.8544 22.374 20.7122 20.5254L20.8834 20.3541C21.628 19.6108 21.5191 18.3745 20.6564 17.7726C20.3775 17.5793 20.0856 17.3744 19.7755 17.1577C19.1048 16.6868 18.1876 16.7594 17.6078 17.3366L17.2939 17.6481C15.9434 16.8437 15.2584 16.0705 14.3464 14.7006L14.6578 14.3868C15.2338 13.8056 15.3064 12.8884 14.8368 12.2177C14.6188 11.9076 14.4152 11.6158 14.2207 11.3369C13.62 10.4742 12.3837 10.3665 11.6391 11.1098L11.4678 11.2811Z'
      stroke='#039855'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(WhatsappLogo)
