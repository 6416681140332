import FacebookIcon from 'assets/socials/facebook.svg'
import GoogleIcon from 'assets/socials/google.svg'
import { ButtonLoading } from 'components/Buttons'
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth'
import { PropsWithChildren } from 'react'
import { useAlert } from 'store/useAlert'
import FormattedMessage from 'utils/FormattedMessage'

type Props = {
  provider: 'facebook' | 'google'
  titleGoogle?: string
  titleFacebook?: string
}

export const OAuthProviderButton = ({
  children,
  provider,
  titleGoogle,
  titleFacebook,
}: PropsWithChildren<Props>) => {
  const { addAlert } = useAlert()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      console.info('Attempt to log in')

      const { user } = await {
        google: () => signInWithPopup(getAuth(), new GoogleAuthProvider()),
        facebook: () => signInWithPopup(getAuth(), new FacebookAuthProvider()),
      }[provider]()

      addAlert(`You are logged in as ${user?.email}`, 'info')
      // subscribeToAuthUser(user)
    } catch (err: any) {
      addAlert(String(err.message), 'error')
    }
  }

  return (
    <form className='w-full' onSubmit={handleSubmit}>
      {children || (
        <ButtonLoading
          style='w-full flex bg-white text-gray-400 items-center justify-center'
          type='submit'
        >
          {
            {
              google: (
                <GoogleIcon style={{ marginRight: 6, minWidth: 20, width: 20, height: 25 }} />
              ),
              facebook: (
                <FacebookIcon
                  style={{ marginRight: 6, minWidth: 20, width: 20, height: 25, zIndex: 10000 }}
                />
              ),
            }[provider]
          }

          {
            {
              google: <FormattedMessage id={titleGoogle || ''} />,
              facebook: <FormattedMessage id={titleFacebook || ''} />,
            }[provider]
          }
        </ButtonLoading>
      )}
    </form>
  )
}
