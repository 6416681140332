import { VISITOR } from 'app/routes'
import { BurgerIcon, HomeIcon, TicketIcon } from 'assets/icons'
import CloseIcon from 'assets/icons/CloseIcon'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlace } from 'store/usePlace'
import FormattedMessage from 'utils/FormattedMessage'

export const DesktopMenu = () => {
  const navigate = useNavigate()
  const { place } = usePlace()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <div className='fixed top-[10px] left-[10px]'>
        <button
          type='button'
          onClick={() => setIsOpen(!isOpen)}
          className='bg-custom-main hover:bg-custom-main focus:ring-4 focus:outline-none focus:ring-swatch-10 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center mr-2 w-[40px] h-[40px] border-radius-full border border-white'
        >
          <BurgerIcon className='stroke-white' />
        </button>
      </div>

      <div
        id='drawer'
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } bg-custom-bg w-80 border-solid border-r border-text-main`}
        tabIndex={-1}
        role='dialog'
        aria-modal='true'
        aria-labelledby='drawer-label'
      >
        <button
          type='button'
          data-drawer-hide='drawer-example'
          aria-controls='drawer-example'
          onClick={() => setIsOpen(!isOpen)}
          className='text-cutext-gray-700 dark:text-gray-100 bg-custom-bg hover:brightness-75 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white'
        >
          <CloseIcon className='stroke-custom-default' />
        </button>
        <div className='py-4 overflow-y-auto mt-5'>
          <ul className='space-y-2 font-medium'>
            <li>
              <div
                className='flex items-center p-2 text-cutext-gray-700 dark:text-gray-100 rounded-lg bg-custom-bg hover:brightness-75 hover:cursor-pointer'
                onClick={() => {
                  navigate(place ? VISITOR.PLACE.replace(':placeId', place.id) : VISITOR.MAIN)
                  setIsOpen(false)
                }}
              >
                <HomeIcon className={'w-6 h-6 stroke-gray-700 dark:stroke-gray-100'} />
                <span className='ml-3'>Home</span>
              </div>
            </li>
            <li>
              <div
                className='flex items-center p-2 text-cutext-gray-700 dark:text-gray-100 rounded-lg bg-custom-bg hover:brightness-75 hover:cursor-pointer'
                onClick={() => {
                  navigate(VISITOR.TICKETS)
                  setIsOpen(false)
                }}
              >
                <TicketIcon className={'w-6 h-6 stroke-gray-700 dark:stroke-gray-100'} />
                <span className='ml-3'>
                  <FormattedMessage id='visits' />
                </span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
