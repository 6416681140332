import CheckCircle from 'assets/check-circle.svg'
import NotifyPending from 'assets/notify-pending.svg'
import WarningTriangle from 'assets/warning-triangle.svg'
import { ButtonLoading } from 'components/Buttons'
import { Modal } from 'components/Modal'
import { RoundSpinner } from 'components/Spinner'
import FormattedMessage from 'utils/FormattedMessage'

interface PushNotificationModalProps {
  show: boolean
  status: string
  onClose: () => void
}

export const PushNotificationModal = ({ show, status, onClose }: PushNotificationModalProps) => {
  return (
    <Modal open={show} onClose={onClose} closeIcon={status !== 'loading'}>
      {status === 'loading' && (
        <div className='flex justify-center items-center'>
          <RoundSpinner />
        </div>
      )}
      {status === 'pending' && (
        <div className='flex flex-col justify-center items-center w-full'>
          <NotifyPending className='inline-block w-[150px] rounded-md' alt='pending' />
          <p className='text-sm text-gray-500 text-center my-4'>
            <FormattedMessage id='wizardForm.uiNotification.pending' />
          </p>
        </div>
      )}
      {status === 'granted' && (
        <div className='flex flex-col justify-center items-center w-full'>
          <div className='mx-auto flex h-12 items-center justify-center rounded-full bg-green-100'>
            <CheckCircle className='inline-block rounded-md' alt='granted' />
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <h3 className='text-base font-semibold leading-6 text-gray-900' id='modal-title'>
              <FormattedMessage id='wizardForm.uiNotification.granted.title' />
            </h3>
            <div className='mt-2'>
              <p className='text-sm text-gray-500'>
                <FormattedMessage id='wizardForm.uiNotification.granted.description' />
              </p>
            </div>
          </div>
        </div>
      )}
      {status === 'denied' && (
        <div className='flex flex-col justify-center items-center w-full'>
          <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
            <WarningTriangle
              className='inline-block w-12 rounded-md'
              src={WarningTriangle}
              alt='denied'
            />
          </div>
          <div className='mt-3 text-center sm:mt-5'>
            <h3 className='text-base font-semibold leading-6 text-gray-900' id='modal-title'>
              <FormattedMessage id='wizardForm.uiNotification.denied.title' />
            </h3>
            <div className='mt-2'>
              <p className='text-sm text-gray-500'>
                <FormattedMessage id='wizardForm.uiNotification.denied.description' />
              </p>
            </div>
          </div>
        </div>
      )}
      <ButtonLoading onClick={onClose}>
        <FormattedMessage id='close' />
      </ButtonLoading>
    </Modal>
  )
}
