import { LocaleLong, PhoneNumberLookupRequest } from '@2meters/types'
import { validatePhoneNumber } from 'api/backend/validatePhoneNumber'
import { RoundSpinner } from 'components/Spinner'
import { verify } from 'crypto'
import { isValidPhoneNumber } from 'libphonenumber-js'
import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { useVisitorLocale } from 'store/useVisitorLocale'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'

type PhoneInputProps = {
  // showValidationErrors: boolean;
  initialValue?: string
  validate?: boolean
  verify?: boolean
  onChange?: (phone: string, valid: boolean | undefined) => void
}

type IpApiLookup = {
  ip: string
  // eslint-disable-next-line camelcase
  country_name: string
  // eslint-disable-next-line camelcase
  country_code: string
  region: string
  // eslint-disable-next-line camelcase
  region_code: string
  city: string
  postal: string
  latitude: string
  longitude: string
  timezone: string
  // eslint-disable-next-line camelcase
  country_calling_code: string
  currency: string
}

const autoCorrectPhoneNumber = (phoneNumberVal: string, dialCode: string): string => {
  let phoneNumber = phoneNumberVal
  // the first char should always be +
  if (phoneNumber.substr(0, 1) === '0') {
    phoneNumber = '+'
  }

  if (phoneNumber.length >= 1 && phoneNumber.charAt(0) !== '+') {
    phoneNumber = `+${phoneNumber}`
  }

  // remove the 0 after the dial code
  const re = new RegExp(`\\+${dialCode}[0]+(?<rest>[0-9]*)`, 'gm')
  const res = re.exec(phoneNumber)

  if (res) {
    phoneNumber = res?.groups?.rest ? `+${dialCode}${res.groups.rest}` : `+${dialCode}`
  }

  return phoneNumber
}

const validate = (phoneNumber?: string): Promise<PhoneNumberLookupRequest> => {
  if (
    phoneNumber === undefined ||
    phoneNumber.trim().length < 8 ||
    !isValidPhoneNumber(phoneNumber)
  ) {
    return Promise.resolve({ valid: false })
  }

  return validatePhoneNumber(phoneNumber)
}

const loadLocale = async (params: LocaleLong): Promise<any> => {
  const localeShort = params.substr(0, 2)
  try {
    const localizationDataModule = await import(`react-phone-input-2/lang/${localeShort}.json`)
    return await localizationDataModule?.default
  } catch (err: any) {
    console.error('PhoneInput: ', err)
    return {}
  }
}

const processPhoneNumber = async (phoneVal: string): Promise<{ phone: string; valid: boolean }> => {
  const phone = phoneVal
  if (!phone) {
    return Promise.resolve({ phone, valid: false })
  }

  const validationResult = await validate(phone)

  if (!validationResult.valid) {
    return Promise.resolve({ phone, valid: false })
  }

  return Promise.resolve({ phone, valid: true })
}

export const PhoneField: React.FC<PhoneInputProps> = ({
  // showValidationErrors,
  initialValue,
  validate = true,
  onChange: onChangeProp,
}) => {
  const intl = useTranslation()
  const { visitorLocale } = useVisitorLocale()
  const [localizationData, setLocalizationData] = useState<any>(null)
  const [visitorCountryCode, setVisitorCountryCode] = useState<any>('us')
  const [phone, setPhone] = useState(initialValue ?? '')
  const [validationDelay, setValidationDelay] = useState<number>(2000)
  const [dialCode, setDialCode] = useState<string>('')
  const [valid, setValid] = useState<boolean | undefined>()
  const [phoneError, setPhoneError] = useState('')
  const [verificationInProgress, setVerificationInProgress] = useState(false)
  const ref = useRef<HTMLInputElement | null>(null)

  const handleChange = useCallback((input: string, data: any): void => {
    setPhone(input)
    setDialCode(data.dialCode)
    setValidationDelay(data.delay !== undefined ? data.delay : 2000)
  }, [])

  useEffect(() => {
    const correctedPhone = autoCorrectPhoneNumber(phone, dialCode)
    if (phone !== correctedPhone) {
      setPhone(correctedPhone)
    }
  }, [phone, dialCode])

  const onBlur = useCallback(
    (input: FocusEvent<HTMLInputElement, Element>, data: any): void => {
      handleChange(input.currentTarget.value.replace(/\D/g, ''), {
        ...data,
        delay: 0,
      })
    },
    [handleChange]
  )

  useEffect(() => {
    if (!phone) {
      onChangeProp && onChangeProp(phone, !validate)
      return undefined
    }
    if (!validate) {
      onChangeProp && onChangeProp(phone, true)
      return
    }

    const phoneTimeout = setTimeout(() => {
      setVerificationInProgress(true)
      processPhoneNumber(phone)
        .catch((e: Error) => {
          setPhoneError(e.message)
        })
        .then(data => {
          if (!data) {
            return
          }
          const { phone, valid } = data
          setPhoneError('')
          if (!valid && document.activeElement !== ref.current) {
            setPhoneError(
              intl({
                id: 'wizardForm.personalInfo.input.phone.error',
              })
            )
          }
          onChangeProp && onChangeProp(phone, valid)

          setValid(valid)
        })
        .finally(() => setVerificationInProgress(false))
    }, validationDelay)

    return () => clearTimeout(phoneTimeout)
  }, [phone, validate, validationDelay, ref, onChangeProp])

  useEffect(() => {
    if (!phone) {
      return
    }
    const phoneNumber = autoCorrectPhoneNumber(phone, dialCode)
    if (phone !== phoneNumber) {
      setPhone(phoneNumber)
    }
  }, [phone, dialCode])

  // if no phone is set (first time user), then fetch the country code via geoip lookup
  useEffect(() => {
    if (phone) {
      return undefined
    }

    setVerificationInProgress(true)
    const controller = new AbortController()
    const timeoutId = setTimeout(() => controller.abort(), 2000)
    fetch('https://ipapi.co/json/', { signal: controller.signal })
      .then((res: Response) => res.json())
      .then((data: IpApiLookup) => {
        if (!data.country_code) {
          throw new Error('geolocation ip lookup failed')
        }
        setVisitorCountryCode(data.country_code.toLowerCase())
      })
      .catch(e => console.error('Geolocation error: ', e))
      .finally(() => setVerificationInProgress(false))
    return () => clearTimeout(timeoutId)
  }, [phone])

  useEffect(() => {
    loadLocale(visitorLocale as LocaleLong).then(localizationData => {
      // Force unmount of PhoneInput component to destroy cache
      setLocalizationData(null)
      // Set localization data for new render
      setLocalizationData(localizationData)
    })
  }, [visitorLocale])

  if (!localizationData) {
    return null
  }

  return (
    <div className='flex flex-col relative w-full'>
      <label className='block text-sm font-medium leading-6 text-gray-700 dark:text-gray-100 mb-2'>
        <FormattedMessage id='wizardForm.personalInfo.input.phone.label' />
      </label>
      <PhoneInput
        value={phone}
        preferredCountries={['de', 'lt', 'ch', 'gb', 'es', 'us']}
        country={visitorCountryCode}
        enableSearch
        buttonClass='phoneField-flagDropdown'
        inputClass={`phone-field-input ${
          // showValidationErrors && valid === false && 'phone-field-input-error'
          valid === false && 'phone-field-input-error'
        }`}
        searchPlaceholder={intl({
          id: 'wizardForm.personalInfo.input.phone.searchLabel',
        })}
        searchNotFound={intl({
          id: 'wizardForm.personalInfo.input.phone.searchNotFound',
        })}
        localization={localizationData ?? 'en'}
        placeholder={intl({
          id: 'wizardForm.personalInfo.input.phone.placeholder',
        })}
        onChange={handleChange}
        onBlur={onBlur}
      />
      {phoneError && <p className='mt-2 text-sm text-red-600 dark:text-red-500'>{phoneError}</p>}
      {verificationInProgress && (
        <div className='absolute right-0 top-[36px]'>
          <RoundSpinner />
        </div>
      )}
    </div>
  )
}
