import { Container } from 'components/Container'
import FormattedMessage from 'utils/FormattedMessage'

export const Header = () => {
  return (
    <div className={`flex bg-custom-header h-[68px] md:min-h-[68px]`}>
      <Container>
        <div className='flex items-center h-full pl-10'>
          <h1 className='text-xl md:text-2xl font-bold text-gray-900 dark:text-gray-100'>
            <FormattedMessage id='myVisits' />
          </h1>
        </div>
      </Container>
    </div>
  )
}
