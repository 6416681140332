import { useRouter } from 'next/router'
import { FC, PropsWithChildren } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { StaticRouter } from 'react-router-dom/server'

export const Router: FC<PropsWithChildren<any>> = props => {
  const router = useRouter()
  const location = Array.isArray(router.query.slug) ? '/' + router.query.slug.join('/') : '/'

  if (typeof window !== 'undefined') {
    return <BrowserRouter {...props} />
  }
  return <StaticRouter location={location} {...props} />
}
