import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
  stroke?: string
}

const BurgerIcon = ({ fill = 'none', viewBox = '0 0 20 14' }: IconProps) => (
  <svg
    width='20'
    height='14'
    viewBox={viewBox}
    fill={fill}
    className='stroke-gray-100 dark:stroke-gray-100'
  >
    <path d='M1 7H19M1 1H19M1 13H19' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
  </svg>
)
export default memo(BurgerIcon)
