import { EnvelopeIcon } from 'assets/icons'
import { Input } from 'components/Form'
import { PhoneField } from 'components/PhoneField'
import { useCallback, useEffect, useState } from 'react'
import { usePlace } from 'store/usePlace'
import { useWizardForm } from 'store/useWizardForm'
import { useTranslation } from 'utils/useTranslation'
import { WizardContactData } from '../types'
import { TitleStep } from '../ui'
import { DesktopInfoBlock } from './DesktopInfoBlock'

interface PersonalInfoProps {
  onValid: (valid: boolean) => void
}

export const ContactsStep = ({ onValid }: PersonalInfoProps) => {
  const intl = useTranslation()
  const { place } = usePlace()
  const { wizardForm, updateWizardForm } = useWizardForm()
  const [data, setData] = useState<WizardContactData>(wizardForm.contactData)
  const [emailError, setEmailError] = useState('')
  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [phoneError, setPhoneError] = useState<boolean | undefined>(false)

  useEffect(() => {
    updateWizardForm({
      contactData: {
        ...wizardForm.contactData,
        ...data,
      },
    })
  }, [data])

  useEffect(() => {
    const validateForm = () => {
      if (place?.contactDataConfig?.askName && (firstNameError || !data.firstName)) {
        return false
      }

      if (place?.contactDataConfig?.askName && (lastNameError || !data.lastName)) {
        return false
      }

      if (place?.contactDataConfig?.askEmail && (emailError || !data.email)) {
        return false
      }

      if (place?.contactDataConfig?.askPhone && (phoneError || !data.phone)) {
        return false
      }

      return true
    }

    if (onValid) onValid(validateForm())
  }, [data, firstNameError, emailError, onValid, place]) //phoneError

  // Validate the email format
  const validateEmail = email => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
  }

  // Handle changes in the email input field
  const handleChangeEmail = email => {
    setData(prevState => ({
      ...prevState,
      email,
    }))
    setEmailError(validateEmail(email) ? '' : 'Invalid email format')
  }

  // Handle changes in the first name input field
  const handleChangeFirstName = firstName => {
    setData(prevState => ({
      ...prevState,
      firstName,
    }))
    setFirstNameError(firstName ? '' : 'Cannot be empty')
  }
  // Handle changes in the last name input field
  const handleChangeLastName = lastName => {
    setData(prevState => ({
      ...prevState,
      lastName,
    }))
    setLastNameError(lastName ? '' : 'Cannot be empty')
  }

  // Handle changes in the phone number input field
  const handlePhoneNumberChange = useCallback((phone: string, valid: boolean | undefined) => {
    setPhoneError(!valid)
    setData(prevState => ({
      ...prevState,
      phone,
    }))
  }, [])

  return (
    <div>
      <TitleStep
        title={intl({
          id: 'wizardForm.personalInfo.title',
        })}
      />
      <div className='md:grid md:grid-cols-2 md:gap-4'>
        <div>
          {place?.contactDataConfig?.askName && (
            <>
              <Input
                name='firstName'
                defaultValue={data.firstName}
                error={firstNameError}
                onChange={name => {
                  handleChangeFirstName(name)
                }}
                label={intl({
                  id: 'firstName',
                })}
                placeholder={intl({
                  id: 'wizardForm.personalInfo.input.firstName.placeholder',
                })}
              />
              <Input
                name='lastName'
                defaultValue={data.lastName}
                error={lastNameError}
                onChange={name => {
                  handleChangeLastName(name)
                }}
                label={intl({
                  id: 'lastName',
                })}
                placeholder={intl({
                  id: 'wizardForm.personalInfo.input.lastName.placeholder',
                })}
              />
            </>
          )}

          {place?.contactDataConfig?.askEmail && (
            <Input
              name='email'
              label={intl({
                id: 'email',
              })}
              error={emailError}
              defaultValue={data.email}
              onChange={email => {
                handleChangeEmail(email)
              }}
              placeholder='olivia@2meters.app'
              icon={<EnvelopeIcon className='h-5 w-5 stroke-gray-500' />}
            />
          )}

          {place?.contactDataConfig?.askPhone && (
            <div className='mb-4'>
              <PhoneField
                initialValue={data.phone}
                onChange={handlePhoneNumberChange}
                validate={
                  place.messagingConfig.useSMSMessenger ||
                  place.messagingConfig.useWhatsAppMessenger ||
                  place.messagingConfig.useBulkgateMessenger
                }
              />
            </div>
          )}
        </div>
        <DesktopInfoBlock />
      </div>
    </div>
  )
}
