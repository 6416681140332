import { genPrivateSubscription, Subscription, SubscriptionStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

export interface SubscriptionStore {
  subscriptionId: string
  subscription: Subscription
  loaded: boolean
  failed: boolean
  subscribeToSubscription: (id?: string) => void
  unsubscribeFromSubscription: () => void
}

export const useSubscription = create<SubscriptionStore>((set, get) => ({
  subscription: undefined as any,
  subscriptionId: undefined as any,
  loaded: false,
  failed: false,
  subscribeToSubscription: subscriptionId => {
    if (!subscriptionId) {
      set({
        subscription: SubscriptionStruct.create({ id: '', placeId: '' }),
        subscriptionId: undefined,
        loaded: true,
      })
      return
    }
    console.log('Subscribing to subscription', subscriptionId)
    const ref = doc(db, `subscriptions/${subscriptionId}`)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async doc => {
        if (!doc.exists() || !doc.data()) {
          console.log('Subscription does not exist', { subscriptionId })
          const subscription = genPrivateSubscription(subscriptionId) as any
          set({ subscription, loaded: true })
        } else {
          console.log('Subscription updated', { subscriptionId, subscription: doc.data() })
          const subscription = SubscriptionStruct.create(doc.data() || {})
          set({ subscription, subscriptionId: subscriptionId, loaded: true })
        }
      },
      (error: any) => {
        console.error('Subscribing to subscription failed', error)
        set({ subscriptionId: subscriptionId, loaded: true, failed: true })
      }
    )

    set({
      unsubscribeFromSubscription: () => {
        console.log('Unsubscribing from subscription')
        unsubscribeFromFirestore()
      },
    })
    return get().unsubscribeFromSubscription
  },
  unsubscribeFromSubscription: () => {},
}))

export default useSubscription
