import { readTime } from '@2meters/types'
import { DateTime } from 'luxon'

export function readTimeToDate(date: DateTime, timeStr: string, zone: string) {
  const [hours, minutes] = readTime(timeStr)

  return date.setZone(zone).set({
    hour: hours,
    minute: minutes,
    second: 0,
    millisecond: 0,
  })
}
