import { IntlProvider } from 'react-intl'
import { useVisitorLocale } from 'store/useVisitorLocale'
import { PropsWithChildren, useEffect } from 'react'

type Props = {}

export const LanguageProvider: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const { visitorLocale, currentMessages, setVisitorLocale } = useVisitorLocale()

  useEffect(() => {
    setVisitorLocale(visitorLocale)
  }, [visitorLocale])

  return (
    <IntlProvider locale={visitorLocale} messages={currentMessages} {...props}>
      {children}
    </IntlProvider>
  )
}
