import { InviteAppointmentRequest, Urls } from '@2meters/types'
import { functions } from 'api/Firebase/firebase-init'
import { httpsCallable } from 'firebase/functions'

export function inviteAppointment(request: InviteAppointmentRequest) {
  const inviteCloudFunc = httpsCallable(
    functions,
    `${Urls.counter.prefix}${Urls.counter.inviteAppointment}`
  )

  return inviteCloudFunc({ ...request, removeAfterMin: request.removeAfterMin || undefined })
}
