export const ticketOptions = appointment => {
  const { status, confirmation } = appointment

  const config = {
    created_confirmed: {
      label: 'confirmed',
      textColor: 'text-ticket-confirmed',
      bgColor: 'bg-ticket-confirmed',
    },
    created_pending: {
      label: 'notConfirmed',
      textColor: 'text-ticket-not_confirmed',
      bgColor: 'bg-ticket-not_confirmed',
    },
    invited_confirmed: {
      label: 'invited',
      textColor: 'text-ticket-invited',
      bgColor: 'bg-ticket-invited',
    },
    created_not_yet: {
      label: 'notConfirmed',
      textColor: 'text-ticket-not_confirmed',
      bgColor: 'bg-ticket-not_confirmed',
    },
    queued_confirmed: {
      label: 'queued',
      textColor: 'text-ticket-queued',
      bgColor: 'bg-ticket-queued',
    },
    processed_confirmed: {
      label: 'processed',
      textColor: 'text-ticket-processed',
      bgColor: 'bg-ticket-processed',
    },
    cancelled_confirmed: {
      label: 'cancelled',
      textColor: 'text-ticket-cancelled',
      bgColor: 'bg-ticket-cancelled',
    },
    cancelled_not_yet: {
      label: 'cancelled',
      textColor: 'text-ticket-cancelled',
      bgColor: 'bg-ticket-cancelled',
    },
  }

  const key = `${status}_${confirmation}`
  const defaultConfig = {
    label: 'rejected',
    textColor: 'text-ticket-rejected',
    bgColor: 'bg-ticket-rejected',
  }

  return config[key] || defaultConfig
}
