import { Schedule, weekdayFromIndex } from '@2meters/types'
import { ScheduleDateFormat } from '@2meters/types'
import { DateTime } from 'luxon'
import { readTimeToDate } from './readTimeToDate'
import { SchedulerInterval } from './types'

export const calculateIntervals = (
  schedule: Schedule,
  startOfDay: DateTime,
  endOfDay: DateTime,
  timezone: string,
  serviceId: string,
  today: DateTime
): SchedulerInterval[] => {
  const dayOfWeek = weekdayFromIndex(startOfDay.weekday)

  return schedule.dateRanges
    .filter(
      dateRange =>
        !dateRange.availabilityWindowStart ||
        today.plus({ days: dateRange.availabilityWindowStart }) < endOfDay
    )
    .filter(
      dateRange =>
        !dateRange.availabilityWindowFinish ||
        startOfDay < today.plus({ days: dateRange.availabilityWindowFinish })
    )
    .filter(dateRange => {
      const startDate = DateTime.fromFormat(dateRange.startDate, ScheduleDateFormat, {
        zone: timezone,
      })
      const endDate = dateRange.endDate
        ? DateTime.fromFormat(dateRange.endDate, ScheduleDateFormat, {
            zone: timezone,
          })
        : undefined

      return (
        dateRange.enabled !== false && startDate <= startOfDay && (!endDate || endDate >= endOfDay)
      )
    })
    .map(dateRange => {
      const interval: SchedulerInterval[] = dateRange.timeRanges
        .filter(timeRange => timeRange.days.includes(dayOfWeek))
        .map(timeRange => ({
          id: timeRange.id,
          startTime: readTimeToDate(startOfDay, timeRange.from, timezone),
          endTime: readTimeToDate(startOfDay, timeRange.until, timezone),
          slotDurationMin: dateRange.slotDurationMinutes,
          shopCapacity: dateRange.capacity,
          serviceId: serviceId,
          timezone: timezone,
        }))
        .flat()
      return interval
    })
    .flat()
}
