import { Place } from '@2meters/types'
import PhoneIconWithBg from 'assets/icons/PhoneIconWithBg'
import FormattedMessage from 'utils/FormattedMessage'

type Props = {
  place?: Place
}

export const ContactInfo = ({ place }: Props) => {
  if (!place) return null
  return (
    <>
      {place.contactData && place.contactData.phoneNumber && (
        <div className='telephone mb-6'>
          <div className='mb-2'>
            <PhoneIconWithBg />
          </div>
          <h3 className='text-gray-700 dark:text-gray-100 mb-2 font-bold'>
            <FormattedMessage id='phone' />
          </h3>
          <a
            href={`tel:${place.contactData.phoneNumber}`}
            className='text-custom-highlight font-regular'
          >
            {place.contactData.phoneNumber}
          </a>
        </div>
      )}
    </>
  )
}
