import { Place } from '@2meters/types'
import EmailIconWithBg from 'assets/icons/EmailIconWithBg'
import FormattedMessage from 'utils/FormattedMessage'

type Props = {
  place?: Place
}

export const PlaceContacts = ({ place }: Props) => {
  if (!place) return null
  return (
    <>
      {place && place.contactData && place.contactData.email && (
        <div className='email flex flex-col items-start mb-6'>
          <div className='mb-2'>
            <EmailIconWithBg />
          </div>
          <h3 className='text-gray-700 dark:text-gray-100 mb-2 font-bold'>
            <FormattedMessage id='email' />
          </h3>
          <a
            className='text-custom-highlight font-semibold'
            href={`mailto:${place.contactData.email}`}
          >
            {place.contactData.email}
          </a>
        </div>
      )}
    </>
  )
}
