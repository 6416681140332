import { Appointment } from '@2meters/types'
import { ExclamationTriangleIcon } from 'assets/icons'
import FormattedMessage from 'utils/FormattedMessage'
import { NotificationInfo } from './NotificationInfo'
import { ShortTicket } from './ShortTicket'

interface TabHistoryProps {
  tickets: Appointment[]
}

export const TabHistory = ({ tickets }: TabHistoryProps) => {
  return (
    <>
      <div className='p-4'>
        {(tickets.length === 0 && (
          <div className='flex flex-col justify-center items-center mt-5'>
            <div className='mb-3'>
              <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-orange-100'>
                <ExclamationTriangleIcon className='h-6 w-6 stroke-orange-600' />
              </div>
              <div className='mt-3 text-center sm:mt-5'>
                <h3 className='text-base font-semibold leading-6 text-gray-900 dark:text-gray-100'>
                  <FormattedMessage id='noListTickets' />
                </h3>
              </div>
            </div>
          </div>
        )) ||
          tickets
            .filter(
              ticket =>
                ticket.confirmation === 'rejected' ||
                ticket.status === 'processed' ||
                ticket.status === 'cancelled'
            )
            .map(ticket => <ShortTicket key={ticket.id} ticket={ticket} />)}
      </div>
    </>
  )
}
