import { Appointment } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { doc, setDoc } from 'firebase/firestore'

type UpdateAppointmentParams = {
  placeId: string
  ticket: Appointment
}

export const updateTicket = async (params: UpdateAppointmentParams): Promise<void> => {
  const { placeId, ticket } = params
  console.log('Update ticket', ticket)
  const ref = doc(db, `places/${placeId}/tickets/${ticket.id}`)

  return setDoc(ref, ticket, { merge: true })
}
