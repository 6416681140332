import { InventoryProduct, OrderProduct } from '@2meters/types'
import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { NummericStepper } from 'components/NumericStepper/NumericStepper'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useWizardForm } from 'store/useWizardForm'
import { WizardOrderProduct } from '../types'

interface ProductCardProps {
  inventoryId: string
  product: InventoryProduct
  quantity: number
  currency: string
}

export const ProductCard = ({
  inventoryId,
  product,
  currency,
  quantity: defaultQuantity,
}: ProductCardProps) => {
  const { wizardForm, updateWizardForm } = useWizardForm()
  const [logoUrl, setLogoUrl] = useState('')
  const [loadingLogo, setLoadingLogo] = useState(false)

  const onQuantityChanged = (quantity: number) => {
    updateProductQuantity(wizardForm.order?.products || {}, product, quantity)
  }

  function updateProductQuantity(
    products: Record<string, WizardOrderProduct>,
    product: InventoryProduct,
    quantity: number
  ) {
    const updatedProducts = {
      ...products,
      [product.id]: {
        productId: product.id,
        name: product.name,
        price: product.price || 0,
        imageFile: product.imageFile,
        description: product.description,
        quantity: quantity,
      },
    }

    const totalAmount = Object.values(updatedProducts).reduce(
      (sum: number, product: WizardOrderProduct) => {
        return sum + (product.price || 0) * (product.quantity || 0)
      },
      0
    )

    updateWizardForm({
      order: {
        inventoryId: inventoryId,
        currency: currency,
        totalAmount: totalAmount,
        products: updatedProducts,
      },
    })
  }

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(product.imageFile)
      .then(link => {
        setLogoUrl(link)
        setLoadingLogo(false)
      })
      .catch(() => {
        setLogoUrl('')
        setLoadingLogo(false)
      })
  }, [product.imageFile])

  return (
    <div className='group column border-gray-300 dark:border-gray-400 p-3 border rounded-lg mb-5'>
      <div className='flex'>
        {(loadingLogo && (
          <div className='flex items-center justify-center min-w-[40px] h-10 mr-4'>
            <RoundSpinner width='w-7' height='h-7' />
          </div>
        )) || (
          <div className='mr-4 flex-shrink-0'>
            {logoUrl ? (
              <img
                className='inline-block h-[60px] w-[60px] rounded-[3px]'
                src={logoUrl}
                alt={product.name}
              />
            ) : (
              <svg
                className='h-16 w-16 border border-gray-300 dark:border-gray-400 text-gray-700 dark:text-gray-100'
                preserveAspectRatio='none'
                stroke='currentColor'
                fill='none'
                viewBox='0 0 200 200'
                aria-hidden='true'
              >
                <path
                  vectorEffect='non-scaling-stroke'
                  strokeWidth={1}
                  d='M0 0l200 200M0 200L200 0'
                />
              </svg>
            )}
          </div>
        )}

        <div className='flex flex-col w-full'>
          <div className='flex items-center justify-between'>
            <h4 className='text-lg text-gray-700 dark:text-gray-100 font-bold'>{product.name}</h4>
          </div>

          <p className='mt-1 text-gray-700 dark:text-gray-100 '>{product.description}</p>
          <div className='flex items-center justify-between mt-3'>
            <p className='text-gray-700 dark:text-gray-100 '>
              <b>
                {product.price?.toFixed(2)} {currency}
              </b>
            </p>
          </div>
        </div>
      </div>

      <NummericStepper
        defaultValue={defaultQuantity}
        onChange={onQuantityChanged}
        className='justify-end '
      />
    </div>
  )
}
