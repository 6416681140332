import { AppointmentShort, Schedule, Slot } from '@2meters/types'
import { DateTime } from 'luxon'
import { calculateIntervals } from './calculateIntervals'
import { calculateSlots } from './calculateSlots'
import { fillInOccupiedSlots } from './fillInOccupiedSlots'

export function calculateAvailability(
  schedule: Schedule,
  appointments: AppointmentShort[],
  timezone: string,
  serviceId: string,
  date: DateTime
): Slot[] {
  const startOfDay = date.startOf('day')
  const endOfDay = date.endOf('day')
  const today = DateTime.local().setZone(timezone)

  const intervals = calculateIntervals(schedule, startOfDay, endOfDay, timezone, serviceId, today)

  const activeAppointments = appointments.filter(appointment =>
    ['confirmed', 'pending', 'created', 'invited', 'queued', 'processed'].includes(
      appointment.status
    )
  )
  const slotsWoOccupancy = calculateSlots(intervals)
  const slots = fillInOccupiedSlots(activeAppointments, slotsWoOccupancy, schedule.id)
  return slots
}
