import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  color?: string
  viewBox?: string
}

const FullscreenIcon = ({ className = 'w-6 h-6 stroke-white' }: IconProps) => (
  <svg viewBox='0 0 33 33' fill='none' className={className}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.6016 7.47389V10.1739C21.6016 11.1138 22.2672 11.7047 23.2108 11.7047H25.7538C26.6962 11.7047 27.3631 11.1176 27.3631 10.1739V7.47389C27.3631 6.53025 26.6962 5.94312 25.7538 5.94312H23.2108C22.2672 5.94312 21.6016 6.53025 21.6016 7.47389Z'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.30029 7.47389V10.1739C4.30029 11.1138 4.96595 11.7047 5.90959 11.7047H8.45258C9.39498 11.7047 10.0619 11.1176 10.0619 10.1739V7.47389C10.0619 6.53025 9.39498 5.94312 8.45258 5.94312H5.90959C4.96595 5.94312 4.30029 6.53025 4.30029 7.47389Z'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M21.6016 24.7735V27.4736C21.6016 28.4134 22.2672 29.0043 23.2108 29.0043H25.7538C26.6962 29.0043 27.3631 28.4173 27.3631 27.4736V24.7735C27.3631 23.8299 26.6962 23.2427 25.7538 23.2427H23.2108C22.2672 23.2427 21.6016 23.8299 21.6016 24.7735Z'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.30029 24.7735V27.4736C4.30029 28.4134 4.96595 29.0043 5.90959 29.0043H8.45258C9.39498 29.0043 10.0619 28.4173 10.0619 27.4736V24.7735C10.0619 23.8299 9.39498 23.2427 8.45258 23.2427H5.90959C4.96595 23.2427 4.30029 23.8299 4.30029 24.7735Z'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.2845 20.756L12.5496 20.7558L12.5483 18.0198'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M16.3794 14.1912H19.1152L19.1156 16.9261'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.5483 20.7568L19.1139 14.1912'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M10.0888 8.80078H21.6108M10.0888 26.1236H21.6108M7.22559 11.7041V23.2435M24.4802 11.7041V23.2435'
      strokeWidth='1.15312'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(FullscreenIcon)
