import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { usePlace } from 'store/usePlace'
import { useQueues } from 'store/useQueues'
import { useServices } from 'store/useServices'
import useSubscription from 'store/useSubscription'
import { useTicket } from 'store/useTicket'
import { useTicketShort } from 'store/useTicketShort'
import { TicketPageHeader, Ticket, WhatsappModal } from './components'
import TicketNotFound from './components/TicketNotFound'

export const TicketPage = () => {
  const { placeId: urlPlaceId, id: urlTicketId } = useParams()
  const { place, subscribeToPlace, unsubscribeFromPlace, error: placeError } = usePlace()
  const { subscribeToSubscription, unsubscribeFromSubscription } = useSubscription()
  const { subscribeToQueues, unsubscribeFromQueues } = useQueues()
  const {
    ticketShort,
    subscribeToTicketShort,
    unsubscribeFromTicketShort,
    error: ticketShortError,
  } = useTicketShort()
  const { ticket, subscribeToTicket, unsubscribeFromTicket, error: ticketError } = useTicket()
  const {
    // services,
    subscribeToServices,
    unsubscribeFromServices,
  } = useServices()

  useEffect(() => {
    const unsubscribe = () => {
      unsubscribeFromTicket()
      unsubscribeFromTicketShort()
      unsubscribeFromPlace()
      unsubscribeFromServices()
      unsubscribeFromQueues()
      unsubscribeFromSubscription()
    }

    if (urlTicketId && urlPlaceId) {
      subscribeToTicket(urlPlaceId, urlTicketId)
      subscribeToTicketShort(urlPlaceId, urlTicketId)
      subscribeToPlace(urlPlaceId)
      subscribeToServices(urlPlaceId)
      subscribeToQueues(urlPlaceId)
      subscribeToSubscription()
    }

    return unsubscribe
  }, [urlTicketId, urlPlaceId])

  useEffect(() => {
    if (!place) return

    return subscribeToSubscription(place.subscriptionId)
  }, [place])

  const isError = ticketShortError || placeError
  const ticketOrShort = ticket || ticketShort

  return (
    <>
      <div className='bg-custom-bg'>
        <TicketPageHeader />
        {isError && <TicketNotFound ticketId={urlTicketId} placeId={urlPlaceId} />}
        {!isError && ticketOrShort && place && (
          <>
            <div className='p-4'>
              <Ticket
                app={ticketOrShort}
                place={place}
                // services={services}
              />
            </div>
            <WhatsappModal place={place} app={ticketOrShort} />
          </>
        )}
      </div>
    </>
  )
}
