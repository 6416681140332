import { VISITOR } from 'app/routes'
import { ButtonLoading } from 'components/Buttons'
import { ContactInfo } from 'components/ContactInfo'
import { OpeningHours } from 'components/OpeningHours/OpeningHours'
import { PlaceAddress } from 'components/PlaceAddress'
import { PlaceContacts } from 'components/PlaceContacts'
import { PlaceSocials } from 'components/PlaceSocials'
import { RichTextParse } from 'components/RichTextParse'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePlace } from 'store/usePlace'
import FormattedMessage from 'utils/FormattedMessage'
import { Header } from './components'

export const PlacePage = () => {
  const navigate = useNavigate()
  const { placeId: urlPlaceId } = useParams()
  const {
    place,
    subscribeToPlace: subscribeToPlace,
    unsubscribeFromPlace: unsubscribeFromPlace,
  } = usePlace()
  useEffect(() => {
    const unsubscribe = () => {
      unsubscribeFromPlace()
    }
    if (urlPlaceId) {
      subscribeToPlace(urlPlaceId)
    }
    return unsubscribe
  }, [urlPlaceId])

  if (!place) {
    return null
  }

  return (
    <div className='overflow-x-hidden h-screen flex flex-col'>
      <Header />
      <div>
        <div className='mx-auto w-full max-w-screen-lg px-4 sm:px-6 lg:px-8 md:h-auto md:max-w-screen-xl'>
          <div className='w-full px-4 pt-10 pb-1'>
            <h3 className='text-2xl font-semibold	md:text-2xl md:font-bold text-gray-900 dark:text-gray-100'>
              {place.name}
            </h3>
          </div>
          <div className='px-4 pb-28 pt-6 md:flex md:pb-8'>
            <div id='left' className='md:mr-8 md:w-5/12'>
              <div className='mb-4'>
                <RichTextParse
                  className={'text-gray-700 dark:text-gray-100 text-base'}
                  str={place.description}
                />
              </div>
              <PlaceSocials place={place} />
              <PlaceContacts place={place} />
              <PlaceAddress place={place} />
              <ContactInfo place={place} />
            </div>
            <OpeningHours place={place} />
          </div>
        </div>
        <div className='w-full flex fixed bottom-20 md:bottom-0 md:relative px-4 py-[24px] mt-auto'>
          <div className='md:max-w-screen-xl w-full mx-auto flex justify-end '>
            <ButtonLoading
              onClick={() => navigate(VISITOR.BOOK.replace(':placeId', place?.id || ''))}
              style='w-full !mb-0 md:w-36 btn-primary hover:text-white border-none'
            >
              <FormattedMessage id='book' />
            </ButtonLoading>
          </div>
        </div>
      </div>
    </div>
  )
}
