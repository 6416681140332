import QrAim from 'assets/qr_code-aim.svg'
import React, { useEffect, useState } from 'react'
import { QrReader } from 'react-qr-reader'
import { ChevronLeftIcon } from 'assets/icons'
import { useNavigate } from 'react-router-dom'

export const ScanQrPage: React.FC = () => {
  const [data, setData] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (data) {
      window?.location?.assign(data)
    }
  }, [data])

  return (
    <div className='flex flex-col items-center w-full h-screen absolute left-0 bg-black'>
      <div
        className='absolute w-72 h-72 opacity-30 bg-cover bottom-0 top-0 m-auto z-10'
        style={{
          backgroundImage: `url('${QrAim}')`,
        }}
      />
      <QrReader
        constraints={{
          facingMode: 'environment',
        }}
        scanDelay={300}
        onResult={(result: any) => {
          if (result) {
            setData(result.getText())
          }
        }}
        containerStyle={{ width: '100%' }}
        videoStyle={{
          width: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
        }}
        videoContainerStyle={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          paddingTop: 0,
          left: 0,
          top: 0,
        }}
      />
      <button
        className='h-auto min-w-auto p-4 text-white fixed top-0 left-0 z-10'
        onClick={() => navigate(-1)}
      >
        <ChevronLeftIcon />
      </button>
    </div>
  )
}
