export { WhatsappModal } from './WhatsappModal'
export { Ticket } from './Ticket'
export { ProductItem } from './ProductItem'
export { Tabs } from './Tabs'
export { TabDetails } from './TabDetails'
export { TabInfo } from './TabInfo'
export { TabProducts } from './TabProducts'
export { TabPersonal } from './TabPersonal'
export { TicketPageHeader } from './TicketPageHeader'
export { QrCode } from './QrCode'
