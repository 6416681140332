import { Container } from 'components/Container'
import { useTranslation } from 'utils/useTranslation'
import { TicketTabType } from '../types'

interface TabsProps {
  currentTab: TicketTabType | undefined
  onChange: (tab: TicketTabType) => void
}

export const Tabs = ({ currentTab, onChange }: TabsProps) => {
  const intl = useTranslation()
  const classNames = (...classes) => classes.filter(Boolean).join(' ')

  const tabs = [
    {
      name: intl({
        id: 'ticket.upcomingVisits',
      }),
      current: true,
    },
    {
      name: intl({
        id: 'ticket.visitsHistory',
      }),
      current: false,
    },
  ]

  return (
    <div className='block'>
      <div className='border-b border-gray-200'>
        <Container>
          <nav className='-mb-px flex space-x-7' aria-label='Tabs'>
            <a
              href='#'
              onClick={e => {
                e.preventDefault()
                onChange(tabs[0])
              }}
              className={classNames(
                tabs[0].name === currentTab?.name
                  ? 'border-custom-main text-gray-700 dark:text-gray-100'
                  : 'border-transparent text-gray-900 dark:text-gray-200 opacity-30 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-4 pt-4 pb-2 px-1 text-sm font-medium w-1/2 text-center'
              )}
              aria-current={tabs[0].current ? 'page' : undefined}
            >
              {tabs[0].name}
            </a>

            <a
              href='#'
              onClick={e => {
                e.preventDefault()
                onChange(tabs[1])
              }}
              className={classNames(
                tabs[1].name === currentTab?.name
                  ? 'border-custom-main text-gray-700 dark:text-gray-100'
                  : 'border-transparent text-gray-900 dark:text-gray-200 opacity-30 hover:border-gray-300 hover:text-gray-700',
                'whitespace-nowrap border-b-4 pt-4 pb-2 px-1 text-sm font-medium w-1/2  text-center'
              )}
              aria-current={tabs[1].current ? 'page' : undefined}
            >
              {tabs[1].name}
            </a>
          </nav>
        </Container>
      </div>
    </div>
  )
}
