import { RadioGroup } from '@headlessui/react'
import { RadioCheck } from 'components/WizardForm/ui'

export type Props = {
  id: string
  name: string
  value: string
  description?: string
  onClick?: () => void
}

export const SelectablePaper = ({ onClick, name, description, id, value }: Props) => {
  return (
    <RadioGroup.Option
      key={id}
      value={value}
      onClick={onClick}
      className={({ active, checked }) =>
        `${active ? '' : ''}
                  ${
                    checked
                      ? 'bg-custom-element_selected text-gray-900 dark:text-white border-gray-300'
                      : 'bg-custom-bg'
                  }
                  relative flex cursor-pointer hover:border-gray-500 active:bg-custom-element_active hover:bg-custom-element_hover dark:hover:border-gray-300 focus:border-gray-400 rounded-lg px-5 py-4 border border-solid`
      }
    >
      {({ checked }) => (
        <div className='flex w-full'>
          <div className='mr-2 mt-[2px]'>
            <RadioCheck checked={checked} />
          </div>
          <div className='flex items-start'>
            <div className='text-sm'>
              <RadioGroup.Label
                as='p'
                className={`relative text-gray-700 dark:text-gray-100 text-base ${
                  checked ? 'text-gray-900 dark:text-gray-100' : 'text-gray-700 dark:text-gray-200'
                }`}
              >
                {name}
              </RadioGroup.Label>
              {description && (
                <RadioGroup.Description
                  as='span'
                  className={`inline 
                    ${
                      checked
                        ? 'text-gray-700 dark:text-gray-200'
                        : 'text-gray-500 dark:text-gray-300'
                    }`}
                >
                  {description}
                </RadioGroup.Description>
              )}
            </div>
          </div>
        </div>
      )}
    </RadioGroup.Option>
  )
}
