import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { useTranslation } from 'utils/useTranslation'
import { TitleStep } from '../ui'
import { useWizardSteps } from '../useWizardSteps'
import { DesktopInfoBlock } from './DesktopInfoBlock'
import { ProductCard } from './ProductCard'

export const ProductsStep = () => {
  const intl = useTranslation()
  const { wizardForm } = useWizardForm()
  const { inventory } = useWizardSteps()

  return (
    <div>
      <TitleStep
        title={intl({
          id: 'wizardForm.products.title',
        })}
        description={intl({
          id: 'wizardForm.products.description',
        })}
      />
      <div className='md:grid md:grid-cols-2 md:gap-4'>
        <div className='md:mt-8 md:pb-[80px]'>
          {inventory?.products?.map(product => (
            <ProductCard
              inventoryId={inventory.id}
              product={product}
              quantity={wizardForm.order?.products[product.id]?.quantity || 0}
              currency={inventory.currency || '€'}
              key={product.id}
            />
          ))}
          <div className='flex justify-end items-center mb-10'>
            <h2 className='text-2xl font-bold leading-7 text-gray-700 dark:text-gray-100'>
              <FormattedMessage id='subtotal' />: {wizardForm.order?.totalAmount.toFixed(2) || 0}{' '}
              {wizardForm.order?.currency}
            </h2>
          </div>
        </div>
        <DesktopInfoBlock />
      </div>
    </div>
  )
}
