import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'
import { usePlace } from 'store/usePlace'

export const WithPlace: React.FC = () => {
  const [error] = useState<string>()

  return (
    <>
      {error && <div>Error: {error}</div>}
      {(usePlace.getState()?.loaded && <Outlet />) || (!error && <Outlet />)}
    </>
  )
}
