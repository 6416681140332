import { useEffect, useState } from 'react'
import { useCurrentUser } from 'store/useCurrentUser'
import { useTickets } from 'store/useTickets'
import { useTranslation } from 'utils/useTranslation'
import { Header, TabHistory, Tabs, TabUpcoming } from './components'
import { TicketTabType } from './types'

export const TicketsPage = () => {
  const intl = useTranslation()
  const { authUser } = useCurrentUser()
  const { tickets, subscribe, unsubscribe } = useTickets()
  const [currentTab, setCurrentTab] = useState<TicketTabType>({
    name: intl({
      id: 'ticket.upcomingVisits',
    }),
    current: true,
  })

  useEffect(() => {
    if (authUser?.uid) {
      subscribe(authUser.uid)
      return unsubscribe
    }
  }, [authUser?.uid])

  return (
    <div className='bg-custom-bg md:pb-[20px] pb-[60px]'>
      <Header />
      <Tabs currentTab={currentTab} onChange={tab => setCurrentTab(tab)} />
      {currentTab?.name ===
        intl({
          id: 'ticket.upcomingVisits',
        }) && <TabUpcoming tickets={tickets} />}
      {currentTab?.name ===
        intl({
          id: 'ticket.visitsHistory',
        }) && <TabHistory tickets={tickets} />}
    </div>
  )
}
