import React from 'react'

interface TicketNotFoundProps {
  ticketId?: string
  placeId?: string
}

const TicketNotFound: React.FC<TicketNotFoundProps> = ({ ticketId, placeId }) => {
  return (
    <div className='flex flex-col items-center justify-center mb-[70px] border-gray-200 '>
      <div className='max-w-md w-full h-full mx-auto z-10'>
        <div
          className='mt-10 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative'
          role='alert'
        >
          <strong className='font-bold'>Ticket not found</strong>
          <br />
          <span className='block sm:inline'>Ticket id: {ticketId}</span>
          <br />
          <span className='block sm:inline'>Place id: {placeId}</span>
        </div>
      </div>
    </div>
  )
}

export default TicketNotFound
