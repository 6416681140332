import { Form, FormStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot } from 'firebase/firestore'
import { create } from 'zustand'

export interface FormsStore {
  placeId: string | undefined
  forms: Form[]
  isLoadingForms: boolean
  subscribeToForms: (placeId: string) => void
  unsubscribeFromForms: () => void
}

export const useForms = create<FormsStore>(set => ({
  forms: [],
  placeId: undefined,
  isLoadingForms: false,

  subscribeToForms: placeId => {
    set({ isLoadingForms: true, placeId })
    if (!placeId) {
      set({ forms: [], isLoadingForms: false })
      return
    }
    const ref = collection(db, `places/${placeId}/forms/`)
    console.log('Subscribing to forms', placeId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async querySnapshot => {
        const forms = querySnapshot.docs.map(doc => FormStruct.create(doc.data()))
        console.log('Forms updated', forms)
        set({ forms, isLoadingForms: false })
      },
      (error: any) => {
        console.error('Subscribing to forms failed', error)
        set({ forms: [], isLoadingForms: false })
      }
    )

    const unsubscribe = () => {
      console.log('Unsubscribing from forms')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribeFromForms: unsubscribe,
    })

    return unsubscribe
  },

  unsubscribeFromForms: () => {
    console.log('Unsubscribing from schedules')
  },
}))

export default useForms
