import { useAlert } from 'store/useAlert'

type AlertType = 'success' | 'info' | 'warning' | 'error'

interface Alert {
  id?: string
  message: string
  type: AlertType
}

export const Alert = () => {
  const alerts = useAlert(state => state.alerts)

  const AlertComponent = ({ message, type, onDismiss }: Alert & { onDismiss?: () => void }) => {
    const backgroundColor = {
      success: 'bg-green-100',
      info: 'bg-blue-100',
      warning: 'bg-yellow-100',
      error: 'bg-red-100',
    }[type]

    const borderColor = {
      success: 'border-green-400',
      info: 'border-blue-400',
      warning: 'border-yellow-400',
      error: 'border-red-400',
    }[type]

    const textColor = {
      success: 'text-green-700',
      info: 'text-blue-700',
      warning: 'text-yellow-700',
      error: 'text-red-700',
    }[type]

    return (
      <div
        className={`flex px-4 py-3 relative rounded-md border ${borderColor} ${backgroundColor} ${textColor}`}
        role='alert'
      >
        <span className='block sm:inline text-sm'>{message}</span>
        {onDismiss && (
          <button onClick={onDismiss} className='ml-5 float-right focus:outline-none'>
            <svg
              className={`fill-current h-5 w-5 ${textColor}`}
              role='button'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 20 20'
            >
              <path d='M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z' />
            </svg>
          </button>
        )}
      </div>
    )
  }

  return (
    <>
      {alerts.length > 0 && (
        <div className='flex justify-center px-2 mt-3 fixed top-0 left-0 right-0 z-50'>
          {alerts.map(alert => (
            <AlertComponent
              key={alert.id}
              message={alert.message}
              type={alert.type}
              onDismiss={() => {
                useAlert.setState(state => ({
                  alerts: state.alerts.filter(a => a.id !== alert.id),
                }))
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
