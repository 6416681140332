import { Place } from '@2meters/types'
import { ClockIconWithBg } from 'assets/icons'
import FormattedMessage from 'utils/FormattedMessage'
import { formatTimeToLocale } from 'utils/formatTimeToLocale'

type Props = {
  place?: Place
}

export const OpeningHours = ({ place }: Props) => {
  if (!place) return null
  const week = [
    { weekday: place.openingHours.MO, name: 'monday' },
    { weekday: place.openingHours.TU, name: 'tuesday' },
    { weekday: place.openingHours.WE, name: 'wednesday' },
    { weekday: place.openingHours.TH, name: 'thursday' },
    { weekday: place.openingHours.FR, name: 'friday' },
    { weekday: place.openingHours.SA, name: 'saturday' },
    { weekday: place.openingHours.SU, name: 'sunday' },
  ]
  return (
    <div id='right' className='mb-6'>
      <div style={{}} className='mb-2'>
        <ClockIconWithBg />
      </div>
      {place.timezone && (
        <h3 className='text-gray-900 dark:text-gray-100 mb-2 font-bold'>
          <FormattedMessage id='timezone' />: {place.timezone}
        </h3>
      )}
      <h3 className='text-gray-900 dark:text-gray-100 mb-2 font-bold'>
        <FormattedMessage id='openningHours' />
      </h3>
      {week.map(({ weekday, name }) => (
        <div key={name} className='flex mb-2 justify-between '>
          <span className='w-28 text-gray-700 dark:text-gray-100 text-base'>
            <FormattedMessage id={name} />
          </span>
          {weekday.open ? (
            <div className='flex flex-col items-end'>
              {weekday.workingTime?.map(time => (
                <span key={time.id} className='text-gray-700 dark:text-gray-100 text-base'>
                  {formatTimeToLocale(time.openingTime)} {` - `}{' '}
                  {formatTimeToLocale(time.closingTime)}
                </span>
              ))}
            </div>
          ) : (
            <span className='text-gray-700 dark:text-gray-100'>
              <FormattedMessage id='closed' />
            </span>
          )}
        </div>
      ))}
    </div>
  )
}
