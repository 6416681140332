import { ButtonLoading } from 'components/Buttons'
import { Container } from 'components/Container'
import { PacmanSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useForms } from 'store/useForms'
import { useInventories } from 'store/useInventories'
import { usePlace } from 'store/usePlace'
import { useQueues } from 'store/useQueues'
import { useServices } from 'store/useServices'
import { useWizardForm } from 'store/useWizardForm'
import FormattedMessage from 'utils/FormattedMessage'
import { isAppleDevice } from 'utils/isMobile'
import { useTranslation } from 'utils/useTranslation'
import {
  CategoryStep,
  ChoiceStep,
  ConfirmAppointment,
  ContactsStep,
  FormHeader,
  FormsStep,
  PausedQueueWarning,
  Products,
  ServiceStep,
  TicketSettingModal,
  TimeSlotStep,
  TimeZonePopup,
  TooManyPeopleWarning,
} from './components'
import { PushNotification } from './components/PushNotification'
import {
  CATEGORY_STEP,
  CHOICE_STEP,
  CONTACTS_STEP,
  FORMS_STEPS,
  FORMS_STEP_1,
  FORMS_STEP_2,
  FORMS_STEP_3,
  FORMS_STEP_4,
  FORMS_STEP_5,
  PRODUCTS_STEP,
  SERVICE_STEP,
  TIMESLOT_STEP,
  useWizardSteps,
} from './useWizardSteps'
import useQueueStats from 'store/useQueueStats'
import { useQueueTickets } from 'store/useQueueTickets'

export const WizardForm = () => {
  const intl = useTranslation()
  const { placeId: urlPlaceId } = useParams()
  const { services, subscribeToServices, unsubscribeFromServices, isLoadingServices } =
    useServices()
  const { place, subscribeToPlace, unsubscribeFromPlace, loaded: placeLoaded } = usePlace()
  const { subscribeToInventory, unsubscribeFromInventory } = useInventories()
  const { subscribeToQueues, unsubscribeFromQueues } = useQueues()
  const { subscribeToForms, unsubscribeFromForms } = useForms()
  const { wizardForm, updateWizardForm, resetWizardForm } = useWizardForm()
  const [isNextStepEnabled, setIsNextStepEnabled] = useState(false)
  const [isContactDataValid, setIsContactDataValid] = useState(true)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const { subscribeToQueueStats, unsubscribeFromQueueStats } = useQueueStats()
  const { subscribeToQueueTickets, unsubscribeFromQueueTickets } = useQueueTickets()
  const { isQueueDisabled } = useWizardSteps()

  const { state } = useLocation()
  const isItKiosk = state && state.kioskMode

  const {
    availableSteps,
    form,
    queue,
    activeStep,
    activeStepIndex,
    goToNextStep,
    goToPrevStep,
    isFirstStep,
    isLastStep,
  } = useWizardSteps()

  useEffect(() => {
    const unsubscribe = () => {
      unsubscribeFromPlace()
      unsubscribeFromServices()
      unsubscribeFromForms()
      unsubscribeFromInventory()
      unsubscribeFromQueues()
    }
    if (urlPlaceId) {
      subscribeToPlace(urlPlaceId)
      subscribeToServices(urlPlaceId)
      subscribeToForms(urlPlaceId)
      subscribeToInventory(urlPlaceId)
      subscribeToQueues(urlPlaceId)

      updateWizardForm({ placeId: urlPlaceId })
    }
    return unsubscribe
  }, [urlPlaceId])

  useEffect(() => {
    if (urlPlaceId && queue?.id) {
      subscribeToQueueTickets(urlPlaceId, queue?.id)
      subscribeToQueueStats(urlPlaceId, queue?.id)
    }

    return () => {
      unsubscribeFromQueueStats()
      unsubscribeFromQueueTickets()
    }
  }, [urlPlaceId, queue?.id])

  useEffect(() => {
    if (place?.id && wizardForm?.placeId && place.id !== wizardForm.placeId) {
      resetWizardForm(place.id)
    }
  }, [place?.id, resetWizardForm, wizardForm.placeId])

  useEffect(() => {
    function isPageValid(pageId?: string) {
      if (!pageId) return false
      return Boolean(wizardForm.form?.pages[pageId]?.valid)
    }

    const stepChecks = {
      [CATEGORY_STEP]: () => !!wizardForm.serviceCategoryId || !!wizardForm.serviceId,
      [SERVICE_STEP]: () => !!wizardForm.serviceId,
      [CHOICE_STEP]: () => (!!wizardForm.queueId && !isQueueDisabled) || !!wizardForm.scheduleId,
      [TIMESLOT_STEP]: () => !!wizardForm.selectedTime?.label,
      [CONTACTS_STEP]: () => isContactDataValid,
      [FORMS_STEP_1]: () => isPageValid(form?.pages[0].id),
      [FORMS_STEP_2]: () => isPageValid(form?.pages[1].id),
      [FORMS_STEP_3]: () => isPageValid(form?.pages[2].id),
      [FORMS_STEP_4]: () => isPageValid(form?.pages[3].id),
      [FORMS_STEP_5]: () => isPageValid(form?.pages[4].id),
      [PRODUCTS_STEP]: () => true,
    }

    const isStepValid = stepChecks[activeStep] ? stepChecks[activeStep]() : false
    setIsNextStepEnabled(isStepValid)
  }, [isContactDataValid, wizardForm, activeStep, isQueueDisabled])

  const onNextStepClicked = () => {
    if (isLastStep) {
      if (
        !isAppleDevice() &&
        !isItKiosk &&
        wizardForm.notificationStatus !== 'denied' &&
        wizardForm.contactData.pushToken?.length === 0 &&
        place?.messagingConfig.useWebPushMessenger
      ) {
        setShowNotificationModal(true)
      } else {
        setShowConfirmModal(true)
      }
    }
    goToNextStep()
  }

  const onPrevStepClicked = () => {
    goToPrevStep()
  }

  return (
    <>
      {!placeLoaded || isLoadingServices ? (
        <div className='flex justify-center items-center h-screen'>
          <PacmanSpinner />
        </div>
      ) : (
        <>
          <FormHeader goToPreviousStep={onPrevStepClicked} />
          <Container>
            <div className='flex justify-between h-full flex-col mt-5'>
              {(services.length > 0 && (
                <>
                  <div className='mb-[160px] md:mb-1'>
                    {activeStep === CATEGORY_STEP && <CategoryStep />}
                    {activeStep === SERVICE_STEP && <ServiceStep />}
                    {activeStep === CHOICE_STEP && <ChoiceStep skipStep={onNextStepClicked} />}
                    {activeStep === TIMESLOT_STEP && <TimeSlotStep />}
                    {activeStep === CONTACTS_STEP && (
                      <ContactsStep onValid={setIsContactDataValid} />
                    )}
                    {FORMS_STEPS.includes(activeStep) && form && (
                      <FormsStep
                        formPage={
                          form?.pages[activeStepIndex - availableSteps.indexOf(FORMS_STEP_1)]
                        }
                      />
                    )}
                    {activeStep === PRODUCTS_STEP && <Products />}
                  </div>
                  <div
                    className={`fixed bottom-[56px] w-[calc(100%-32px)] md:static md:w-full md:flex md:h-auto md:pb-6 pt-2 md:pt-4 ${
                      !isFirstStep ? 'md:justify-between' : 'md:justify-end'
                    } border-none h-[80px]  `}
                  >
                    {!isFirstStep && (
                      <ButtonLoading
                        onClick={onPrevStepClicked}
                        style='w-full hidden !mb-0 md:block md:w-auto border-solid border-1 border-gray-300 bg-gray-100 text-gray-900 dark:text-gray-900'
                      >
                        {intl({
                          id: 'back',
                        })}
                      </ButtonLoading>
                    )}
                    <ButtonLoading
                      disabled={!isNextStepEnabled}
                      onClick={onNextStepClicked}
                      style='w-full !mb-0 md:w-auto border-none'
                    >
                      {isLastStep
                        ? intl({
                            id: 'confirm',
                          })
                        : intl({
                            id: 'continue',
                          })}
                    </ButtonLoading>
                  </div>
                </>
              )) || (
                <div>
                  <FormattedMessage id='wizardForm.noService' />
                </div>
              )}
            </div>
          </Container>
          {activeStep === TIMESLOT_STEP && <TimeZonePopup />}
          <TicketSettingModal />
          <PausedQueueWarning />
          <TooManyPeopleWarning />

          <ConfirmAppointment
            show={showConfirmModal}
            onClose={() => {
              setShowConfirmModal(false)
            }}
          />

          <PushNotification
            show={showNotificationModal}
            onClose={() => {
              setShowNotificationModal(false)
              setShowConfirmModal(true)
            }}
          />
        </>
      )}
    </>
  )
}
