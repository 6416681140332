import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
  stroke?: string
}

const QrIcon = ({
  className = 'w-7 h-7 stroke-gray-800',
  fill = 'none',
  viewBox = '0 0 29 28',
  stroke = '#475467',
}: IconProps) => (
  <svg className={className} viewBox={viewBox} fill={fill}>
    <path
      d='M25.0829 9.88058V8.04054C25.0829 5.53302 23.05 3.5 20.5413 3.5H19.0747'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.0835 9.88058V8.04054C4.0835 5.53302 6.11652 3.5 8.62517 3.5H10.1292'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M4.0835 18.1197V19.9598C4.0835 22.4673 6.11652 24.5004 8.62517 24.5004H10.0918'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.0838 18.1197V19.9598C25.0838 22.4673 23.0507 24.5004 20.542 24.5004H19.0381'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.4958 11.9897H9.49798C8.90317 11.9897 8.42188 11.5084 8.42188 10.9136V8.91579C8.42188 8.32097 8.90317 7.83855 9.49798 7.83855H11.4958C12.0906 7.83855 12.5719 8.32097 12.5719 8.91579V10.9136C12.5719 11.5084 12.0906 11.9897 11.4958 11.9897Z'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5787 20.1618H9.49798C8.90317 20.1618 8.42188 19.6793 8.42188 19.0857V17.005C8.42188 16.4102 8.90317 15.9277 9.49798 15.9277H11.5787C12.1735 15.9277 12.6548 16.4102 12.6548 17.005V19.0857C12.6548 19.6793 12.1735 20.1618 11.5787 20.1618Z'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M17.5874 7.83855H19.6681C20.2629 7.83855 20.7442 8.31984 20.7442 8.91466V10.9953C20.7442 11.5902 20.2629 12.0715 19.6681 12.0715H17.5874C16.9926 12.0715 16.5112 11.5902 16.5112 10.9953V8.91466C16.5112 8.31984 16.9926 7.83855 17.5874 7.83855Z'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1958 20.1594H17.1255'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.8146 20.1594H20.7441'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.8146 16.5454H20.7441'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M19.0054 18.3501H18.9351'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M17.1958 16.5454H17.1255'
      stroke={stroke}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(QrIcon)
