import React, { useEffect, useRef } from 'react'

export type ModalProps = {
  open: boolean
  closeIcon?: boolean
  children?: React.ReactNode
  icon?: React.ReactNode
  cancelButton?: React.ReactNode
  submitButton?: React.ReactNode
  onClose?: () => void
}

export const Modal = ({
  open,
  closeIcon = true,
  icon,
  children,
  cancelButton,
  submitButton,
  onClose,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (onClose && modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClose])

  if (!open) {
    return null
  }

  return (
    <div className='relative z-10' aria-labelledby='modal-title' role='dialog' aria-modal='true'>
      <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'></div>

      <div className='fixed inset-0 z-10 overflow-y-auto'>
        <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
          <div
            ref={modalRef}
            className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6'
          >
            {closeIcon && (
              <div className='absolute right-0 top-0 hidden pr-4 pt-4 sm:block'>
                <button
                  type='button'
                  onClick={onClose}
                  className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                >
                  <span className='sr-only'>Close</span>
                  <svg
                    className='h-6 w-6'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    aria-hidden='true'
                  >
                    <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
                  </svg>
                </button>
              </div>
            )}
            <>
              {icon && (
                <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                  {icon}
                </div>
              )}

              <div className='flex flex-col items-center'>{children}</div>
            </>
            {(cancelButton || submitButton) && (
              <div className={`mt-5 sm:mt-6 grid grid-flow-col justify-stretch space-x-6`}>
                {cancelButton}
                {submitButton}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
