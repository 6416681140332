type Props = {
  checked: boolean
  className?: string
}

export const RadioCheck = ({ checked, className }: Props) => {
  return (
    <div
      className={`flex justify-center items-center w-[16px] h-[16px] bg-custom-bg border border-solid rounded-3xl ${
        checked ? 'border-gray-400 dark:border-gray-300' : 'border-gray-300 dark:border-gray-400'
      } ${className}`}
    >
      {checked && (
        <div
          className={`flex w-[6px] h-[6px] rounded-3xl bg-gray-400 dark:border-gray-300 ${className}`}
        />
      )}
    </div>
  )
}
