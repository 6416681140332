import { memo } from 'react'
import IconWithBg from './IconWithBg'

const MapIconWithBg = () => (
  <IconWithBg>
    <path
      className={`stroke-custom-main`}
      d='M30.5 21.3333C30.5 27.1666 23 32.1666 23 32.1666C23 32.1666 15.5 27.1666 15.5 21.3333C15.5 19.3442 16.2902 17.4365 17.6967 16.03C19.1032 14.6235 21.0109 13.8333 23 13.8333C24.9891 13.8333 26.8968 14.6235 28.3033 16.03C29.7098 17.4365 30.5 19.3442 30.5 21.3333Z'
      stroke='#7669FF'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      className={`stroke-custom-main`}
      d='M23 23.8333C24.3807 23.8333 25.5 22.714 25.5 21.3333C25.5 19.9526 24.3807 18.8333 23 18.8333C21.6193 18.8333 20.5 19.9526 20.5 21.3333C20.5 22.714 21.6193 23.8333 23 23.8333Z'
      stroke='#7669FF'
      strokeWidth='1.66667'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </IconWithBg>
)
export default memo(MapIconWithBg)
