import objectHash from 'object-hash'

interface RichTextParseProps {
  str: string | undefined
  className?: string
}

export const RichTextParse = ({ str, className }: RichTextParseProps) => {
  const isJsonString = str => {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const applyStyles = item => {
    let text = item.text

    if (item.bold) {
      text = <strong key={objectHash(item)}>{text}</strong>
    }
    if (item.italic) {
      text = <em key={objectHash(item)}>{text}</em>
    }
    if (item.underline) {
      text = <u key={objectHash(item)}>{text}</u>
    }
    if (item.type === 'link') {
      const linkChildren = item.children.map(applyStyles)
      text = (
        <a key={objectHash(item)} className='underline text-custom-main' href={item.url}>
          {linkChildren}
        </a>
      )
    }

    return text
  }

  const renderListItems = items => {
    return items.map(item => {
      return <li key={objectHash(item)}>{item.children.map(applyStyles)}</li>
    })
  }

  const parseElements = elements => {
    return elements
      .map((element, index) => {
        if (element.type === 'paragraph') {
          const children = element.children.map(applyStyles)
          return (
            <p className='mb-2' key={objectHash(element)}>
              {children}
            </p>
          )
        }

        if (element.type === 'numbered-list') {
          return (
            <ol className='list-decimal list-inside mb-2' key={objectHash(element)}>
              {renderListItems(element.children)}
            </ol>
          )
        }

        if (element.type === 'bulleted-list') {
          return (
            <ul className='list-disc list-inside mb-2' key={objectHash(element)}>
              {renderListItems(element.children)}
            </ul>
          )
        }

        return null
      })
      .filter(Boolean)
  }

  let htmlElements

  if (isJsonString(str)) {
    const parsedJson = JSON.parse(str ?? '')
    htmlElements = parseElements(parsedJson)
  } else {
    htmlElements = <span>{str}</span>
  }

  return (
    <span className={`mt-1 leading-6 text-gray-700 dark:text-gray-400 ${className}`}>
      {htmlElements}
    </span>
  )
}
