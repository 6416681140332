import { UIMessagePayload, UIMessagePayloadStruct } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { create } from 'zustand'

export interface UINotificationsStore {
  userId?: string
  notifications?: UIMessagePayload[]
  subscribe: (userId: string, callback?: (notifications: UIMessagePayload[]) => void) => void
  unsubscribe: () => void
  unsubscribeFromFirestore?: () => void
}

export const useUINotifications = create<UINotificationsStore>((set, get) => ({
  subscribe: (userId, callback?: (notifications: UIMessagePayload[]) => void) => {
    const q = query(
      collection(db, `uiNotifications`),
      where('userId', '==', userId),
      where('status', '==', 'new')
    )
    set({ userId })
    console.log('Subscribing to UI notifications')
    const unsubscribeFromFirestore = onSnapshot(
      q,
      snapshot => {
        const notifications: UIMessagePayload[] = snapshot
          .docChanges()
          .filter(change => change.type === 'added')
          .map(change => UIMessagePayloadStruct.create({ id: change.doc.id, ...change.doc.data() }))
        console.log('UI notifications updated', notifications)
        set({ notifications })
        callback?.(notifications)
      },
      (error: any) => {
        console.error('Subscribing to UI notifications failed', error)
        set({
          notifications: undefined,
          userId: undefined,
        })
      }
    )

    set({
      unsubscribeFromFirestore,
    })

    return get().unsubscribe
  },
  unsubscribe: () => {
    console.log('Unsubscribing from UI notifications')
    set({
      notifications: undefined,
      userId: undefined,
    })
    get().unsubscribeFromFirestore?.()
  },
}))

export default useUINotifications
