import { getAssetLinkFromStorage } from 'api/Firebase/storage'
import { LOGO, VISITOR } from 'app/routes'
import { ChevronLeftIcon } from 'assets/icons'
import { Container } from 'components/Container'
import { RoundSpinner } from 'components/Spinner'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePlace } from 'store/usePlace'

export const TicketPageHeader = () => {
  const navigate = useNavigate()
  const [logoUrl, setLogoUrl] = useState('')
  const { place } = usePlace()
  const [loadingLogo, setLoadingLogo] = useState(false)

  useEffect(() => {
    setLoadingLogo(true)
    getAssetLinkFromStorage(place?.logo)
      .then(link => {
        setLogoUrl(link)
        setLoadingLogo(false)
      })
      .catch(() => {
        setLogoUrl(LOGO)
        setLoadingLogo(false)
      })
  }, [place?.logo])

  return (
    <div className='bg-custom-header h-[68px]'>
      <Container className='flex justify-center items-center h-full relative'>
        <div className='flex absolute left-[16px]'>
          <button onClick={() => navigate(VISITOR.TICKETS)}>
            <ChevronLeftIcon />
          </button>
        </div>
        <div className='flex items-center absolute bottom-[-10px]'>
          {(loadingLogo && (
            <div className='flex items-center justify-center min-w-[40px] h-10'>
              <RoundSpinner width='w-7' height='h-7' />
            </div>
          )) || (
            <div className='flex-shrink-0 min-w-10 rounded-full'>
              <img className='inline-block h-12 w-12 rounded-full' src={logoUrl} alt='' />
            </div>
          )}
        </div>
        {/* </div> */}
      </Container>
    </div>
  )
}
