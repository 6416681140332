import { memo } from 'react'

type IconProps = {
  viewBox?: string
  className?: string
  fill?: string
}

const ChevronLeftIcon = ({ viewBox = '0 0 24 24' }: IconProps) => (
  <svg
    viewBox={viewBox}
    fill='none'
    strokeWidth='1.5'
    className='w-6 h-6 stroke-gray-900 dark:stroke-gray-100'
  >
    <path strokeLinecap='round' strokeLinejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' />
  </svg>
)
export default memo(ChevronLeftIcon)
