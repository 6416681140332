import { ReactElement } from 'react'

interface TitleStepProps {
  title: string | ReactElement
  description?: string | ReactElement
}

export const TitleStep = ({ title, description }: TitleStepProps) => {
  return (
    <div className='mb-7'>
      <div className='flex justify-between'>
        <h2 className='text-2xl font-bold mb-1 text-gray-700 dark:text-gray-100'>{title}</h2>
      </div>

      {description && <p className='text-sm text-gray-600 dark:text-gray-200'>{description}</p>}
    </div>
  )
}
