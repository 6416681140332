import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  color?: string
  viewBox?: string
}

const ChevronUpDownIcon = ({ className = 'w-6 h-6', viewBox = '0 0 24 24' }: IconProps) => (
  <svg fill='none' viewBox={viewBox} strokeWidth={1.5} className={className}>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
    />
  </svg>
)
export default memo(ChevronUpDownIcon)
