interface ContainerProps {
  className?: string
  children: React.ReactNode
}

export const Container = ({ className, children }: ContainerProps) => {
  return (
    <div className={`mx-auto w-full max-w-screen-lg px-4 sm:px-6 lg:px-8 h-full ${className}`}>
      {children}
    </div>
  )
}
