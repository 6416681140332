import { memo } from 'react'

type IconProps = {
  className?: string
  fill?: string
  viewBox?: string
}

const CompressedTicketIcon = ({
  className = 'w-6 h-6 stroke-gray-800',
  fill = 'none',
  viewBox = '0 0 24 24',
}: IconProps) => (
  <svg viewBox={viewBox} fill={fill} className={className}>
    <path
      d='M12.4435 6.72956C11.8077 6.72956 11.2579 6.43592 10.9028 5.9768C10.3577 5.27203 10.0851 4.91964 9.97837 4.84766C9.87353 4.77696 9.90215 4.79101 9.78209 4.75133C9.65984 4.71094 9.47932 4.71094 9.11829 4.71094C6.76304 4.71094 5.58542 4.71094 4.72015 5.24073C4.23486 5.53787 3.82693 5.9458 3.52979 6.43109C3 7.29636 3 8.47398 3 10.8292V13.1712C3 15.5265 3 16.7042 3.52982 17.5695C3.82694 18.0547 4.23486 18.4626 4.72011 18.7598C5.58539 19.2896 6.76305 19.2896 9.11836 19.2896C9.4798 19.2896 9.66052 19.2896 9.78268 19.2493C9.90256 19.2097 9.87412 19.2236 9.97882 19.1531C10.0855 19.0812 10.3576 18.73 10.9017 18.0277C11.2584 17.5673 11.8099 17.2723 12.4453 17.2728H12.5048C13.1487 17.2954 13.7091 17.6274 14.0474 18.121C14.4586 18.7211 14.6642 19.0212 14.7359 19.0854C14.8997 19.2322 14.8664 19.2146 15.0802 19.2668C15.1736 19.2896 15.3237 19.2896 15.6238 19.2896C17.2789 19.2896 18.1064 19.2896 18.7603 19.0227C19.6564 18.657 20.3674 17.9459 20.7331 17.0499C21 16.3959 21 15.5684 21 13.9134V10.0982C21 8.43277 21 7.60006 20.7298 6.94253C20.3642 6.053 19.6579 5.34671 18.7684 4.98115C18.1109 4.71094 17.2782 4.71094 15.6127 4.71094C15.3089 4.71094 15.157 4.71094 15.061 4.73507C14.8537 4.78717 14.8702 4.77828 14.7132 4.9233C14.6405 4.99048 14.4546 5.2749 14.083 5.84371C13.7334 6.37874 13.1383 6.73012 12.4435 6.72956Z'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.666 15.2031V15.2131'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.666 11.9961V12.0061'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M12.666 8.78516V8.79516'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
export default memo(CompressedTicketIcon)
