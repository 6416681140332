import { ContactDataConfig, ContactDataConfigStruct } from '@2meters/types'
import { useMemo } from 'react'
import useForms from 'store/useForms'
import { useInventories } from 'store/useInventories'
import { usePlace } from 'store/usePlace'
import { useQueueTickets } from 'store/useQueueTickets'
import { useQueues } from 'store/useQueues'
import { useSchedules } from 'store/useSchedules'
import { useServices } from 'store/useServices'
import { useWizardForm } from 'store/useWizardForm'
import { isWorkingTime } from 'utils/isWorkingTime'
import { createStore, useStore } from 'zustand'

export const UNDEFINED_STEP = 0 //TODO remove
export const CATEGORY_STEP = 'category' as const
export const SERVICE_STEP = 'service' as const
export const CHOICE_STEP = 'choice' as const
export const TIMESLOT_STEP = 'timeslot' as const
export const CONTACTS_STEP = 'contacts' as const
export const FORMS_STEP_1 = 'forms_1' as const
export const FORMS_STEP_2 = 'forms_2' as const
export const FORMS_STEP_3 = 'forms_3' as const
export const FORMS_STEP_4 = 'forms_4' as const
export const FORMS_STEP_5 = 'forms_5' as const
export const PRODUCTS_STEP = 'products' as const

export type STEP =
  | typeof CATEGORY_STEP
  | typeof SERVICE_STEP
  | typeof CHOICE_STEP
  | typeof TIMESLOT_STEP
  | typeof CONTACTS_STEP
  | typeof FORMS_STEP_1
  | typeof FORMS_STEP_2
  | typeof FORMS_STEP_3
  | typeof FORMS_STEP_4
  | typeof FORMS_STEP_5
  | typeof PRODUCTS_STEP

export const FORMS_STEPS: STEP[] = [
  FORMS_STEP_1,
  FORMS_STEP_2,
  FORMS_STEP_3,
  FORMS_STEP_4,
  FORMS_STEP_5,
]

type ActiveStepStore = {
  activeStep: STEP
  setActiveStep: (step: STEP) => void
}
const stepStore = createStore<ActiveStepStore>((set, get) => ({
  activeStep: CATEGORY_STEP,
  setActiveStep: (step: STEP) => set({ activeStep: step }),
}))

export const useWizardSteps = () => {
  const wizardForm = useWizardForm(state => state.wizardForm)
  const place = usePlace(state => state.place)
  const { services, isLoadingServices } = useServices()
  const { forms, isLoadingForms } = useForms()
  const { queues, isLoadingQueues } = useQueues()
  const { schedules, isLoadingSchedules } = useSchedules()
  const { inventories, isLoadingInventories } = useInventories()
  const { queueTickets } = useQueueTickets()

  const isPersonalInfoStep = (constactDataConfig?: ContactDataConfig) => {
    if (!constactDataConfig) return false

    const fields = Object.keys(ContactDataConfigStruct.schema)
    for (const field of fields) {
      if (constactDataConfig[field] === true) {
        return true
      }
    }
    return false
  }

  const calculateAvailableSteps = () => {
    const wizardForm = useWizardForm.getState().wizardForm
    const service = services.find(s => s.id == wizardForm.serviceId)
    const form = forms.find(f => f.id === service?.formId)
    const inventory = inventories.find(f => f.id === service?.inventoryId)

    const hasCategoriesStep = true // TODO services has more that one category
    const pagesCount = form?.pages.length || 0
    const productsCount = inventory?.products?.length || 0
    const hasServiceStep = Boolean(wizardForm.serviceCategoryId)
    const hasChoiceStep = service?.scheduleId || service?.queueId
    const hasTimeslotStep = wizardForm?.queueType === 'schedule'
    const hasContactsStep = isPersonalInfoStep(place?.contactDataConfig)
    const hasForms1 = pagesCount > 0
    const hasForms2 = pagesCount > 1
    const hasForms3 = pagesCount > 2
    const hasForms4 = pagesCount > 3
    const hasForms5 = pagesCount > 4
    const hasProducts = productsCount > 0

    const steps = [
      hasCategoriesStep && CATEGORY_STEP,
      hasServiceStep && SERVICE_STEP,
      hasChoiceStep && CHOICE_STEP,
      hasTimeslotStep && TIMESLOT_STEP,
      hasContactsStep && CONTACTS_STEP,
      hasForms1 && FORMS_STEP_1,
      hasForms2 && FORMS_STEP_2,
      hasForms3 && FORMS_STEP_3,
      hasForms4 && FORMS_STEP_4,
      hasForms5 && FORMS_STEP_5,
      hasProducts && PRODUCTS_STEP,
    ].filter(Boolean) as STEP[]
    return steps
  }

  const availableSteps: STEP[] = useMemo(
    () => calculateAvailableSteps(),
    [
      wizardForm.serviceCategoryId,
      place?.contactDataConfig,
      wizardForm?.queueType,
      wizardForm.serviceId,
      queues,
      schedules,
      inventories,
      services,
      forms,
    ]
  )

  const { service, form, queue, schedule, inventory } = useMemo(() => {
    const service = services.find(service => service.id === wizardForm.serviceId)

    const form = forms.find(f => f.id === service?.formId)
    const queue = queues.find(f => f.id === service?.queueId)
    const schedule = schedules.find(f => f.id === service?.scheduleId)
    const inventory = inventories.find(f => f.id === service?.inventoryId)

    return { service, form, queue, schedule, inventory }
  }, [
    wizardForm.serviceCategoryId,
    wizardForm.serviceId,
    services,
    forms,
    queues,
    schedules,
    inventories,
  ])

  const { activeStep, setActiveStep } = useStore(stepStore)

  const goToNextStep = () => {
    const availableSteps = calculateAvailableSteps()
    const currentIndex = availableSteps.indexOf(activeStep)
    const nextIndex = currentIndex + 1
    if (nextIndex < availableSteps.length) {
      setActiveStep(availableSteps[nextIndex])
    }
  }

  const goToPrevStep = () => {
    const currentIndex = availableSteps.indexOf(activeStep)
    const prevIndex = currentIndex - 1

    if (prevIndex >= 0) {
      setActiveStep(availableSteps[prevIndex])
    }
  }

  const resetSteps = () => setActiveStep(CATEGORY_STEP)

  const isQueueWorkingTime =
    !queue?.isOnlyInOpeningHours ||
    (queue?.isOnlyInOpeningHours && isWorkingTime(place?.openingHours))

  const isQueueAtMaxLength =
    queue?.isMaxLengthEnabled &&
    queue?.maxLength !== undefined &&
    queueTickets &&
    queueTickets.length >= queue.maxLength

  const isQueuePaused = queue?.paused

  const isQueueDisabled =
    (queue?.isOnlyInOpeningHours && !isQueueWorkingTime) || isQueueAtMaxLength || isQueuePaused

  return {
    availableSteps,
    service,
    form,
    queue,
    inventory,
    schedule,
    activeStep,
    activeStepIndex: availableSteps.indexOf(activeStep),
    totalSteps: availableSteps.length,
    isLastStep: availableSteps.indexOf(activeStep) === availableSteps.length - 1,
    isFirstStep: availableSteps.indexOf(activeStep) === 0,
    isLoading:
      isLoadingServices ||
      isLoadingForms ||
      isLoadingQueues ||
      isLoadingSchedules ||
      isLoadingInventories,
    isQueueWorkingTime,
    isQueueAtMaxLength,
    isQueuePaused,
    isQueueDisabled,
    goToNextStep,
    goToPrevStep,
    resetSteps,
  }
}
