import { Appointment, TOGGLE_CHECKED } from '@2meters/types'
import { RichTextParse } from 'components/RichTextParse'
import FormattedMessage from 'utils/FormattedMessage'

interface TabPersonalProps {
  app: Appointment | undefined
}

export const TabPersonal = ({ app }: TabPersonalProps) => {
  return (
    <>
      <div className='p-4'>
        <div className='grid grid-cols-1 gap-2 mt-4'>
          {app?.contactData?.firstName && (
            <div>
              <span className='text-xs text-gray-700'>
                <FormattedMessage id='firstName' />
              </span>
              <div className='flex items-center'>
                <p className='text-sm font-semibold text-gray-700 mt-1'>
                  {app?.contactData.firstName}
                </p>
              </div>
            </div>
          )}

          {app?.contactData?.lastName && (
            <div>
              <span className='text-xs text-gray-700'>
                <FormattedMessage id='lastName' />
              </span>
              <div className='flex items-center'>
                <p className='text-sm font-semibold text-gray-700 mt-1'>
                  {app?.contactData.lastName}
                </p>
              </div>
            </div>
          )}

          {app?.contactData?.email && (
            <div>
              <span className='text-xs text-gray-700'>
                <FormattedMessage id='email' />
              </span>
              <div className='flex items-center'>
                <p className='text-sm font-semibold text-gray-700 mt-1'>{app.contactData.email}</p>
              </div>
            </div>
          )}

          {app?.contactData?.phone && (
            <div className='mb-3'>
              <span className='text-xs text-gray-700'>
                <FormattedMessage id='phone' />
              </span>
              <div className='flex items-center'>
                <p className='text-sm font-semibold text-gray-700 mt-1'>{app?.contactData.phone}</p>
              </div>
            </div>
          )}
          <div className='mb-3'></div>
        </div>
      </div>
    </>
  )
}
