import { Appointment } from '@2meters/types'
import { db } from 'api/Firebase/firebase-init'
import { collectionGroup, onSnapshot, query, where } from 'firebase/firestore'
import { create } from 'zustand'

interface TicketsStore {
  userId: string | undefined
  isLoadingTickets: boolean
  tickets: Appointment[]
  subscribe: (userId: string) => void
  unsubscribe: () => void
}

export const useTickets = create<TicketsStore>(set => ({
  userId: undefined,
  isLoadingTickets: true,
  tickets: [],

  subscribe: async userId => {
    set({ isLoadingTickets: true, userId })
    if (!userId) {
      set({ tickets: [], isLoadingTickets: false })
      return
    }

    const ref = query(collectionGroup(db, `tickets`), where('userId', '==', userId))

    console.log('Subscribing to all tickets for', userId)

    const unsubscribeFromFirestore = onSnapshot(
      ref,
      async snap => {
        const tickets = snap.docs.map(doc => doc.data() as Appointment)

        console.log('All tickets updated for', tickets)
        set({ tickets, isLoadingTickets: false })
      },
      (error: any) => {
        console.error('All subscribing to tickets failed for', error)
        set({ tickets: [], isLoadingTickets: false })
      }
    )

    const unsubscribe = () => {
      console.log('All unsubscribing from tickets')
      unsubscribeFromFirestore()
    }

    set({
      unsubscribe,
    })

    return unsubscribe
  },

  unsubscribe: () => {
    console.log('All unsubscribing from tickets')
  },
}))
