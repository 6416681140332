import { DateTime, Interval } from 'luxon'

export const isWorkingTime = (week): boolean => {
  const now = DateTime.local()
  const dayOfWeek = now.toFormat('ccc').toUpperCase().slice(0, 2)

  const dayInfo = week[dayOfWeek]

  if (!dayInfo || !dayInfo.open || !dayInfo.workingTime) {
    return false
  }

  return dayInfo.workingTime.some(({ openingTime, closingTime }) => {
    const opening = DateTime.fromISO(openingTime)
    const closing = DateTime.fromISO(closingTime)
    const interval = Interval.fromDateTimes(opening, closing)

    return interval.contains(now)
  })
}
