import { UIMessagePayload } from '@2meters/types'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationTriangleIcon } from 'assets/icons'
import { markNotificationAsReceived } from 'backend/markNotificationAsReceived'
import { DateTime } from 'luxon'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import FormattedMessage from 'utils/FormattedMessage'
import { useLocation } from 'react-router-dom'
import { useCurrentUser } from 'store/useCurrentUser'
import useDingDong from 'store/useDingDong'
import { useUINotifications } from 'store/useUINotifications'

export const UINotifications: React.FC = () => {
  const uid = useCurrentUser(s => s.authUser?.uid)
  const location = useLocation()
  const [open, setOpen] = useState(false)
  const {
    play: playDingDong,
    subscribe: unlockDingDong,
    unsubscribe: unsubscribeFromDingDong,
  } = useDingDong()
  const [notification, setNotification] = useState<UIMessagePayload>()
  const { subscribe: subscribeToNotifications, unsubscribe: unsubscribeFromNotifications } =
    useUINotifications()
  const parts = location.pathname.split('/')
  const pageName = parts[parts.length - 1]

  const callback = useCallback(
    (n: UIMessagePayload[]) => {
      if (n && n.length) {
        const notifi = n[n.length - 1]
        if (notifi) {
          setNotification(notifi)
          playDingDong()
          setOpen(true)
          markNotificationAsReceived(notifi.id).catch(e =>
            console.error('UI Messaging: status change error.', e)
          )
        }
      }
    },
    [notification]
  )

  useEffect(() => {
    if (pageName === 'kiosk') return
    if (location.state?.kioskMode === 'on') return
    if (uid) {
      unlockDingDong()
      subscribeToNotifications(uid, callback)
      return () => {
        unsubscribeFromNotifications()
        unsubscribeFromDingDong()
      }
    }
  }, [uid])

  return (
    <>
      {location.state?.kioskMode === undefined && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as='div' className='relative z-10' onClose={setOpen}>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
            </Transition.Child>

            <div className='fixed inset-0 z-10 overflow-y-auto'>
              <div className='flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-out duration-300'
                  enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                  enterTo='opacity-100 translate-y-0 sm:scale-100'
                  leave='ease-in duration-200'
                  leaveFrom='opacity-100 translate-y-0 sm:scale-100'
                  leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
                >
                  <Dialog.Panel className='relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6'>
                    <div>
                      <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100'>
                        {(notification?.message.templateName === 'ticketInvitedByCounter' && (
                          <CheckIcon className='h-6 w-6 text-green-600' aria-hidden='true' />
                        )) || (
                          <ExclamationTriangleIcon
                            className='h-6 w-6 stroke-orange-600'
                            aria-hidden='true'
                          />
                        )}
                      </div>
                      <div className='mt-3 text-center sm:mt-5'>
                        <Dialog.Title
                          as='h3'
                          className='text-base font-semibold leading-6 text-gray-900'
                        >
                          {notification?.message.subject}
                        </Dialog.Title>
                        <div className='mt-2'>
                          <p className='text-xl mb-2 text-gray-700'>{notification?.appData?.tag}</p>
                          <p className='text-xl mb-2 text-gray-700'>
                            {notification?.appData?.timestamp ? (
                              DateTime.fromSeconds(Number(notification?.appData?.timestamp.seconds))
                                .setZone(notification?.appData?.timezone)
                                .toFormat('DD')
                            ) : (
                              <p className='text-md mb-2 text-gray-700'>
                                <FormattedMessage id='liveQueue' />
                              </p>
                            )}
                          </p>
                          <p className='text-xl mb-2 text-gray-700'>
                            {notification?.appData?.timestamp &&
                              DateTime.fromSeconds(Number(notification?.appData?.timestamp.seconds))
                                .setZone(notification?.appData?.timezone)
                                .toFormat('HH:mm')}
                          </p>
                          <p className='text-sm text-gray-500'>{notification?.message.body}</p>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  )
}

export default UINotifications
